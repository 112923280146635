import React from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ImageViewer from "pages/Common/utils/ImageViewer";

const PartsSearchList = ({ searchPartList = [], selectedPart, handleViewPartDetails }) => {
  return (
    <>
      <div className="bg-grey border-radius-10 p-3">
        <div className="equipment-master-ul">
          <ul>
            {searchPartList.map((parts, i) => (
              <li
                key={`parts-master-${i}`}
                className={`cursor ${parts.id === selectedPart ? "active" : ""}`}
                onClick={() => handleViewPartDetails(parts.id, parts)}
                // onClick={() => handleViewPartDetails(parts.id)}
              >
                <div className="row position-relative align-items-center">
                  <div className="global-serach-arrow">
                    <ArrowForwardIosIcon className="text-primary font-size-20 mb-0 pb-0" />
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                    <ImageViewer
                      src={parts?.sparepartImages && parts?.sparepartImages.length > 0 ? parts?.sparepartImages[0] : ""}
                      style={{ objectFit: "fill", width: "65px", height: "65px" }}
                    />
                  </div>
                  <div className="col-lg-5 col-md-5 col-5">
                    <h6 className="font-size-12 font-weight-500 text-primary m-0 text-truncate">{parts.partNumber}</h6>
                    <p className="font-size-12 text-light-60 font-weight-500 m-0">{parts.partDescription}</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <div className="d-block pr-3">
                      <h6 className="font-size-12 font-weight-500 text-primary m-0 text-truncate">{parts.model}</h6>
                      <p className="font-size-12 text-light-60 font-weight-500 m-0">{parts.manufacturer}</p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PartsSearchList;
