import React, { useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Modal } from "react-bootstrap";

const ReplacementQuestion = ({ show, hideModal, handleSnack, handleShowReplacementModal }) => {
    const [question1, setQuestion1] = useState("");

    // select questions answer
    const handleSelectQusAns = (e) => {
        const { name, value } = e.target;
        setQuestion1(value);
    };

    const handlePressNextBtn = () => {
        if (!question1) {
            handleSnack("error", "Please select answer first.");
            return;
        } else {
            if (question1 === "no") {
                hideModal();
            } else {
                handleShowReplacementModal();
            }
        }
    };

    return (
        <Modal show={show} onHide={hideModal} size="lg" centered>
            <Modal.Body>
                <div className=" mt-3 p-3">
                    <div className="d-flex">
                        <div className="col-12">
                            <p>QUESTION 01/10</p>
                            <h4>Do you want to Create Replacement ?</h4>
                        </div>
                    </div>
                    <RadioGroup
                        className="my-3"
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="question1"
                        defaultValue="top"
                        value={question1}
                        onChange={handleSelectQusAns}
                    >
                        <div className="col-md-6 customFormControlLabel">
                            <FormControlLabel
                                className="w-100 m-0 mb-3  p-2 card py-4 align-itemsstart "
                                value={"no"}
                                id={"no"}
                                control={<Radio />}
                                label={"No"}
                                labelPlacement="bottom"
                            />
                        </div>
                        <div className="col-md-6 customFormControlLabel">
                            <FormControlLabel
                                className="w-100 m-0 mb-3  p-2 card py-4 align-items-start "
                                value={"yes"}
                                id={"yes"}
                                control={<Radio />}
                                label={"Yes"}
                                labelPlacement="bottom"
                            />
                        </div>
                    </RadioGroup>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                    <div>
                        <button className="btn border-primary cursor" onClick={hideModal}>
                            Cancel
                        </button>
                    </div>
                    <div>
                        <button className="btn bg-primary text-white cursor" onClick={handlePressNextBtn}>
                            Next <ArrowForwardIcon className=" font-size-16" />
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReplacementQuestion;
