import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    { name: 'Jan', "Water Heater": 12, "Solar Panel": 8, "Inverter": 18, "Gas Heater": 22 },
    { name: 'Feb', "Water Heater": 14, "Solar Panel": 17, "Inverter": 12, "Gas Heater": 9 },
    { name: 'Mar', "Water Heater": 21, "Solar Panel": 19, "Inverter": 13, "Gas Heater": 14 },
    { name: 'Apr', "Water Heater": 7, "Solar Panel": 6, "Inverter": 23, "Gas Heater": 26 },
    { name: 'May', "Water Heater": 9, "Solar Panel": 12, "Inverter": 18, "Gas Heater": 19 },
    { name: 'Jun', "Water Heater": 16, "Solar Panel": 14, "Inverter": 11, "Gas Heater": 8 },
    { name: 'Jul', "Water Heater": 19, "Solar Panel": 21, "Inverter": 16, "Gas Heater": 13 },
    { name: 'Aug', "Water Heater": 6, "Solar Panel": 7, "Inverter": 24, "Gas Heater": 23 },
    { name: 'Sep', "Water Heater": 11, "Solar Panel": 9, "Inverter": 21, "Gas Heater": 18 },
    { name: 'Oct', "Water Heater": 13, "Solar Panel": 16, "Inverter": 9, "Gas Heater": 11 },
    { name: 'Nov', "Water Heater": 22, "Solar Panel": 18, "Inverter": 14, "Gas Heater": 12 },
    { name: 'Dec', "Water Heater": 5, "Solar Panel": 6, "Inverter": 25, "Gas Heater": 24 },
    { name: 'Jan', "Water Heater": 8, "Solar Panel": 11, "Inverter": 19, "Gas Heater": 20 },
    { name: 'Feb', "Water Heater": 17, "Solar Panel": 13, "Inverter": 8, "Gas Heater": 10 },
    { name: 'Mar', "Water Heater": 20, "Solar Panel": 22, "Inverter": 12, "Gas Heater": 15 },
];


const ClaimsTrend = () => (
    <>
        <div className='pl-3 text-muted text-uppercase font-size-14 font-weight-600'>Total Claims Over the Year</div>
        <div className='pl-3 py-2 font-size-14 font-weight-700'>No of Warranty Claims Trend</div>
        <ResponsiveContainer width="100%" height={318}>
            <BarChart data={data} margin={{ top: 20, right: 30, left: -10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Water Heater" stackId="a" fill="#896dfe" radius={[5, 5, 0, 0]} barSize={8} />
                <Bar dataKey="Solar Panel" stackId="a" fill="#6fa7ff" radius={[5, 5, 0, 0]} barSize={8} />
                <Bar dataKey="Inverter" stackId="a" fill="#dd96ff" radius={[5, 5, 0, 0]} barSize={8} />
                <Bar dataKey="Gas Heater" stackId="a" fill="#6fd4ff" radius={[5, 5, 0, 0]} barSize={8} />
            </BarChart>
        </ResponsiveContainer>
    </>
);

export default ClaimsTrend;
