import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function SelectBox(props) {
  const { options, label, value, handleChange, handleUnselect, showClearIcon, size } = props;

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: size || 120,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: '#8898aa', 
            borderWidth:'1px',
          },
          "&:hover fieldset": {
            borderColor: '#8898aa',
            borderWidth:'1px',
          },
          "&.Mui-focused fieldset": {
            borderColor: '#8898aa',
            borderWidth:'1px',
          },
        }
      }}
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        size="small"
        endAdornment={
          showClearIcon && value && (
            <InputAdornment position="end" sx={{ marginRight: 1.5, marginLeft: 0, fontSize: 16 }}>
              <IconButton onClick={handleUnselect} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        MenuProps={{
          PaperProps: {
            style: {
              transformOrigin: 'top left',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>{option.replace(/_/g, ' ')}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
