export const sparePartRequestObj = {
  id: 0,
  partNumber: "",
  replacedByPartNumber: "",
  replacesPartNumber: "",
  manufacturer: "",
  model: "",
  groupNumber: "",
  partDescription: "",
  partsGroup: "",
  modelGroupDescription: "",
  partGroupDescription: "",
  yearOfManufacture: "",
  salesUnit: "",
  listPrice: 0,
  costPrice: 0,
  currency: "",
  partType: "",
  usageArea: "",
  availability: "",
  status: "",
  partComplexity: "",
  partUsage: "",
  demand: "",
  application: "",
  erpMaterialNumber: "",
  legacyMaterial: "",
  alternativePart: "",
  materialGroup: "",
  materialGroupDescription: "",
  becCode: "",
  becCodeDescription: "",
  createdBy: "",
  updatedBy: "",
};
