export const STRATEGY_TASK = "STRATEGY_TASK"
export const CATEGORY_USAGE = "CATEGORY_USAGE"
export const TASK_TYPE = "TASK_TYPE"
export const RESPONSE_TIME = "RESPONSE_TIME"
export const PRODUCT_HIERARCHY = "PRODUCT_HIERARCHY"
export const GEOGRAPHIC = "GEOGRAPHIC"
export const MACHINE_COMPONENT = "MACHINE_COMPONENT"
export const MACHINE_TYPE = "MACHINE_TYPE"
export const LIFE_STAGE_OF_MACHINE = "LIFE_STAGE_OF_MACHINE"
export const MAKE = "MAKE"
export const MODEL = "MODEL"
export const PREFIX = "PREFIX"






