import { Fragment, useState } from "react";
import Propensity from "./Propensity";
import { Card, Grid, Typography, Box } from "@mui/material";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import GapToEntitlement from "./GapToEntitlement";
import SparepartSegment from "./SparepartSegment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import {
  faChartBar,
  faChartLine,
  faChartPie,
  faClock,
  faDollarSign,
  faPercent,
  faWrench,
  faFont,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import ServiceRecommend from "./ServiceRecommend";
import JobhourRecommend from "./JobhourRecommend";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import DiscountGuidance from "./DiscountGuidance";
import MarginRecommendation from "./MarginRecommendation";
import Select from "react-select";

// import AiCustomization from "./AiCustomization";

export default function Insights(props) {
  const [insightType, setInsightType] = useState("");
  const insightTypes = [
    {
      type: "propensity",
      label: "Find The Propensity To Buy",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faChartLine} />,
    },
    {
      type: "entitlement",
      label: "Find Gap To Entitlement",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faChartBar} />,
    },
    {
      type: "product-segment",
      label: "Recommend Spare Parts",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faChartPie} />,
    },
    {
      type: "job-hr-recommend",
      label: "Recommend Service Hours",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faClock} />,
    },
    {
      type: "service-recommend",
      label: "Recommend Services",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faWrench} />,
    },
    {
      type: "margin",
      label: "Margin Recommendation",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faDollarSign} />,
    },
    {
      type: "discount",
      label: "Discount Guidance",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faPercent} />,
    },
  ];
  const handleChange = (value) => {
    setInsightType(value);
  };
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showOptions, setShowOptions] = useState(true);
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [text, setText] = useState("");
  const maxCharacters = 2000;

  const handleTextChange = (e) => {
    setText(e.target.textContent);
  };
  return (
    <Fragment>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid ">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: 4 }}>
              Talk Data To Me
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              Choose a prompt below or write your own to
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              start chatting
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "semibold", marginBlock: 4, fontSize: "1.2rem" }}>
              Ask about:
            </Typography>
          </Grid>
          {/* <Box 
  display="flex" 
  flexDirection="column" 
  // justifyContent="center" 
  alignItems="center" 
  style={{  gap: '16px' }} // Adjust gap as needed
>
  {insightTypes.reduce((rows, insight, index) => {
    if (index % 3 === 0) rows.push([]); // Start a new row every 3 items
    rows[rows.length - 1].push(insight);
    return rows;
  }, []).map((row, rowIndex) => (
    <Box 
      key={rowIndex} 
      display="flex" 
      justifyContent="center" 
      gap="16px" // Adjust gap between cards as needed
    >
      {row.map((insight, cardIndex) => (
        <Card
          key={cardIndex}
          sx={{
            borderRadius: 5,
            padding: 0.5,
            margin: 0,
            width: "33.33%", // Ensures 3 cards per row
            cursor: 'pointer',
            backgroundColor: insight.type === insightType ? "#872ff715" : "",
            border: insight.type === insightType ? "1px solid #872ff785" : "",
            boxSizing: 'border-box'
          }}
          variant="outlined"
          onClick={() => handleChange(insight.type)}
        >
          <Box display="flex" alignItems="center">
            <Box sx={{ borderRadius: 4, padding: 0.5, display: 'flex', justifyContent: 'center', backgroundColor: '#872ff7' }} variant="outlined">
              {insight.icon}
            </Box>
            <Typography fontSize={'0.9rem'} textAlign={'center'} style={{ marginLeft: '8px' }}>{insight.label}</Typography>
          </Box>
        </Card>
      ))}
    </Box>
  ))}
</Box> */}

          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="center" width="60%" gap="16px" marginBottom={2}>
              {insightTypes.slice(0, 3).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 1,
                    margin: 0,
                    width: "40%", // Ensures 3 cards per row
                    cursor: "pointer",
                    backgroundColor: insight.type === insightType ? "#872ff715" : "",
                    border: insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box", // Ensures no space around the cards
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.8,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography fontSize={"1rem"} textAlign={"center"} style={{ marginLeft: "8px" }}>
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box>

            <Box display="flex" justifyContent="center" width="70%" gap="16px" marginBottom={2}>
              {insightTypes.slice(3, 7).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 1,
                    // padding: 0.5,
                    margin: 0,
                    width: "40%",
                    // width: "33.33%",
                    cursor: "pointer",
                    backgroundColor: insight.type === insightType ? "#872ff715" : "",
                    border: insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box",
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.8,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography fontSize={"1rem"} textAlign={"center"} style={{ marginLeft: "8px" }}>
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box>

            {/* <Box display="flex" justifyContent="center" width="60%" gap="16px">
              {insightTypes.slice(6).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 0.5,
                    margin: 0,
                    width: "33.33%",
                    cursor: "pointer",
                    backgroundColor:
                      insight.type === insightType ? "#872ff715" : "",
                    border:
                      insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box",
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.5,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography
                      fontSize={"0.9rem"}
                      textAlign={"center"}
                      style={{ marginLeft: "8px" }}
                    >
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box> */}
          </Box>

          {/* <Grid container columnSpacing={3}> */}
          {/* <Grid container justifyContent="center" alignItems="center" spacing={0} >
            {insightTypes.map(insight =>
              <Grid item  lg={4} md={4} xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                  sx={{ borderRadius: 5, padding: 0.5, marginBlock: 1, display: "flex",width:"60%" , cursor: 'pointer', backgroundColor: insight.type === insightType ? "#872ff715" : "", border: insight.type === insightType ? "1px solid #872ff785" : "" }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}

                >
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Card sx={{ borderRadius: 4, padding: 0.5, display: 'flex', justifyContent: 'center', backgroundColor: '#872ff7' }} variant="outlined">
                        {insight.icon}
                      </Card>
                    </Grid>
                    <Grid item container xs={10}  sx={{ paddingLeft: 1 }}>
                      {/* <Box> */}
          {/* <Typography fontSize={'0.9rem'} textAlign={'center'}>{insight.label}</Typography>
                      {/* </Box> */}
          {/* </Grid> */}
          {/* </Grid> */}
          {/* </Card> */}
          {/* </Grid> */}
          {/* )} */}
          {/* </Grid>  */}
          {insightType === "propensity" && <Propensity handleSnack={handleSnack} />}
          {insightType === "entitlement" && <GapToEntitlement handleSnack={handleSnack} />}
          {insightType === "product-segment" && <SparepartSegment handleSnack={handleSnack} />}
          {insightType === "service-recommend" && <ServiceRecommend handleSnack={handleSnack} />}
          {insightType === "job-hr-recommend" && <JobhourRecommend handleSnack={handleSnack} />}
          {insightType === "discount" && <DiscountGuidance handleSnack={handleSnack} />}
          {insightType === "margin" && <MarginRecommendation handleSnack={handleSnack} />}
          {/* {insightType === 'aicustomisation' && <AiCustomization handleSnack={handleSnack} />} */}
          <div className="m-5">
            <Box style={{ backgroundColor: "#E5E5E5", borderRadius: 5, padding: 2, marginTop: "10%" }}>
              <div className="d-flex position-relative">
                <span className="" style={{ position: "absolute", left: 0, transform: "translate(1px, 8px)" }}>
                  <AutoFixHighSharpIcon style={{ fontSize: "40px" }} />
                </span>
                <div
                  contentEditable={true}
                  onInput={handleTextChange}
                  style={{
                    width: "100%",
                    minHeight: "200px",
                    marginBottom: "20px",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#E5E5E5",
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    // color: text === "" || text.length === 0 ? "gray" : "black", //
                  }}
                  className="ml-4"
                >
                  {text === "" ? "Type your request here..." : null}
                </div>
              </div>

              <div className="d-flex justify-content-between ">
                <div className="ml-2 mb-4">
                  <Select
                    className="border-primary text-primary"
                    placeholder="Select Source"
                    menuPlacement="top"
                    options={[
                      { label: "Parts", value: "PARTS" },
                      { label: "Services", value: "SERVICES" },
                      { label: "Services", value: "SERVICES" },
                      { label: "Services", value: "SERVICES" },
                      { label: "Services", value: "SERVICES" },
                    ]}
                  />
                </div>
                <div className="d-flex align-items-center" style={{ fontSize: "14px", color: "#555", marginTop: 5 }}>
                  {`${text.length}/${maxCharacters}`}
                  <span className="mx-2 d-flex justify-content-center align-items-center cursor">
                    <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: "18px" }} />
                  </span>
                </div>
              </div>
            </Box>
            {/* </Grid> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
