import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";

import { Modal } from "react-bootstrap";
import Select from "react-select";
import Moment from "react-moment";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import {
    equipmentRequestObj,
    familyOptions,
    installerCreateObj,
    makeOptions,
    warrantyCreateObj,
    warrantyTypeOptions,
    yearlyWarrantyObj,
} from "../warrantyManagementConstants";
import { customerSearch, equipmentSearch, machineSearch } from "services/searchServices";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import SearchBox from "pages/Common/SearchBox";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import {
    CLAIM_MASTER_URL,
    CLAIM_ORDER_MASTER_URL,
    DATA_SVC_EQUIPMENT,
    WARRANTY_INSTALLER_MASTER_URL,
    WARRANTY_MASTER_URL,
    Warranty_Yearly_GetById_GET,
} from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";
import { isEmpty } from "pages/Common/textUtilities";
import UploadFilesModal from "../UploadFilesModal";
import ClaimOrderRequestModal from "../claimOrderManagement/ClaimOrderRequestModal";

// import ClaimRequestModal from "../claimMaster/ClaimRequestModal";
// import { isEmpty } from "../../common/textUtilities";
// import UploadFilesModal from "../UploadFilesModal";
// import ClaimOrderRequestModal from "../claimOrderManagement/ClaimOrderRequestModal";

const equipmentObj = {
    equipmentId: "",
    model: "",
    makerSerialNumber: "",
};

const customerRequestObj = {
    customerId: 0,
    customerName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    phoneNumber: "",
};

const purchaseObj = {
    purchaseDate: new Date(),
    placeOfPurchase: "",
    attachProof: "",
    usedFor: "",
    usageCondition: "",
};

const insatllerObj = {
    insatallerName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    contactEmail: "",
    phoneNumber: "",
};

const warrantyObj = {
    warrantyType: "",
    warrantyValidFrom: new Date(),
    warrantyValidTo: new Date(),
    dateOfInstall: new Date(),
    dateOfSale: new Date(),
};

const EquipmentEnrollModal = ({
    show,
    hideModal,
    handleSnack,
    equRecordId = null,
    setEquRecordId,
    openClaimRequestModal,
    handleCloseRequestModal = null,
    handleShowFileUploadModal,
    openFileUploadModal,
    handleViewGenerateWarrantyOverview,
}) => {
    const [tabValue, setTabValue] = useState("productData");
    const [productTabValue, setProductTabValue] = useState("machine");
    const [warrantyTabValue, setWarrantyTabValue] = useState("machine");

    const [equipmentRecordData, setEquipmentRecordData] = useState({
        ...equipmentRequestObj,
    });

    const [isExistsSerilNo, setIsExistsSerilNo] = useState(false);

    const [serialNoChecked, setSerialNoChecked] = useState(true);

    const [equipmentData, setEquipmentData] = useState({
        ...equipmentObj,
        make: makeOptions[0],
        family: familyOptions[0],
    });
    const [replaceEquipmentData, setReplaceEquipmentData] = useState({
        ...equipmentRequestObj,
    });
    // const [equipmentId, setEquipmentId] = useState(null);
    const [replacedEquipmentId, setReplacedEquipmentId] = useState(null);

    const [customerRecord, setCustomerRecord] = useState({
        id: "",
        customerID: "",
        customerName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        contactEmail: "",
        contactPhone: "",
        country: "",
        addressId: "",
    });

    const [purchaseRecord, setPurchaseRecord] = useState({ ...purchaseObj });
    const [insatallerRecord, setInsatallerRecord] = useState({
        ...installerCreateObj,
    });
    const [yearlyWarrantyRecord, setYearlyWarrantyRecord] = useState({
        ...yearlyWarrantyObj,
    });
    const [warrantyRecord, setWarrantyRecord] = useState({
        ...warrantyCreateObj,
    });

    const [yearlyWarrantyId, setYearlyWarrantyId] = useState(null);
    const [warrantyId, setWarrantyId] = useState(null);
    const [installerId, setInstallerId] = useState(null);
    const [claimRecordId, setClaimRecordId] = useState(null);
    const [claimOrderId, setClaimOrderId] = useState(null);
    const [evaluationId, setEvaluationId] = useState(null);
    const [assesstmentId, setAssesstmentId] = useState(null);

    const [claimRecordDetail, setClaimRecordDetail] = useState(null);

    const [productReplaced, setProductReplaced] = useState(false);

    const [machineData, setMachineData] = useState({
        make: "",
        family: "",
        model: "",
        serialNo: "",
        smu: "",
        fleetNo: "",
        registrationNo: "",
        chasisNo: "",
        productSegment: "",
        productGroup: "",
    });

    const [viewOnlyTab, setViewOnlyTab] = useState({
        productMachineViewOnly: false,
        productCustomerViewOnly: false,
        productReplacedViewOnly: false,
        warrantyMachineViewOnly: false,
        warrantyInstallerViewOnly: false,
        productViewOnly: false,
        warrantyViewOnly: false,
    });
    const [replacedEquViewOnly, setReplacedEquViewOnly] = useState(false);

    const [noOptionsModel, setNoOptionsModel] = useState(false);
    const [noOptionsSerial, setNoOptionsSerial] = useState(false);
    const [searchModelResults, setSearchModelResults] = useState([]);
    const [searchSerialResults, setSearchSerialResults] = useState([]);

    const [searchCustResults, setSearchCustResults] = useState([]);
    const [noOptionsCust, setNoOptionsCust] = useState(false);
    const [isNewCreated, setIsNewCreated] = useState(false);

    useEffect(() => {
        if (equRecordId && !isNewCreated) {
            const rUrl = DATA_SVC_EQUIPMENT() + "/" + equRecordId;
            callGetApi(rUrl, (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;

                    setViewOnlyTab({
                        productMachineViewOnly: true,
                        productCustomerViewOnly: true,
                        productReplacedViewOnly: responseData.replacedEquipmentNumber ? true : false,
                        warrantyMachineViewOnly: true,
                        warrantyInstallerViewOnly: true,
                        productViewOnly: true,
                        warrantyViewOnly: responseData.warrantyId ? true : false,
                    });

                    setEquipmentRecordData({ ...responseData });

                    // make
                    const _make = makeOptions.find((obj) => obj.value === responseData?.maker);

                    // family
                    const _family = familyOptions.find((obj) => obj.value === responseData?.market);

                    //
                    setEquipmentData({
                        ...equipmentData,
                        equipmentId: responseData?.id,
                        model: responseData?.model,
                        makerSerialNumber: responseData?.makerSerialNumber,
                        description: responseData?.description,
                        make: _make || makeOptions[0],
                        family: _family || familyOptions[0],
                        // serialNo: responseData?.equipmentNumber,
                    });

                    setCustomerRecord({
                        id: responseData.customerId,
                        customerID: responseData.currentClient,
                        customerName: responseData.customer,
                        address: responseData.addressDTO?.fullAddress,
                        city: responseData.addressDTO?.district,
                        state: responseData.addressDTO?.regionOrState,
                        zipCode: responseData.addressDTO?.zipCode,
                        contactEmail: responseData.addressDTO?.email,
                        contactPhone: responseData.addressDTO?.phoneNumber,
                        country: responseData.addressDTO?.country,
                        addressId: responseData.addressDTO?.id,
                    });

                    setPurchaseRecord({
                        ...purchaseRecord,
                        purchaseDate: responseData?.purchaseDate,
                        placeOfPurchase: responseData?.placeOfPurchase,
                        attachProof: "",
                        usedFor: responseData?.usedFor,
                        usageCondition: responseData?.usageCondition,
                    });
                    // setEquRecordId(responseData.id);
                    if (responseData.replacedEquipmentNumber) {
                        getReplaceEquDetails(responseData.replacedEquipmentNumber);
                    }
                    if (responseData.warrantyId) {
                        setWarrantyId(responseData.warrantyId);
                        getWarrantyDetails(responseData.warrantyId);
                    }
                }
            });
        }
    }, [equRecordId, isNewCreated]);

    // get replaced equipment details
    const getReplaceEquDetails = async (replacedEquipmentNumber) => {
        // const res = await equipmentSearch(`replacedEquipmentNumber:${replacedEquipmentNumber}&pageNumber=0&pageSize=10`);
        const res = await equipmentSearch(`equipmentNumber:${replacedEquipmentNumber}`);
        if (res.length > 0) {
            setReplacedEquViewOnly(true);
            setReplaceEquipmentData({ ...res[0] });
            setReplacedEquipmentId(res[0].id);
            setProductReplaced(true);
        }
    };

    // get warranty details
    const getWarrantyDetails = (warrantyId) => {
        const rUrl = `${WARRANTY_MASTER_URL}/${warrantyId}`;
        callGetApi(rUrl, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
                setWarrantyRecord({ ...responseData });
                setInstallerId(responseData.installerId);
                setClaimRecordId(responseData.claimId);
                if (responseData.claimId) {
                    getClaimDetails(responseData.claimId);
                }
                getInstallerDetails(responseData.installerId);
                if (responseData["yearlyWarrantyIds"].length !== 0) {
                    getYearlyWarrantyDetails(responseData["yearlyWarrantyIds"][0]);
                }
            }
        });
    };

    // get claim detail for fetch claim Order Id
    const getClaimDetails = (claimOrderId) => {
        const rUrl = `${CLAIM_ORDER_MASTER_URL}/${claimOrderId}`;
        callGetApi(rUrl, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
                setClaimRecordDetail(responseData);
                setClaimOrderId(responseData.claimOrderId);
            }
        });
    };

    // get warranty installer details
    const getInstallerDetails = (installerId) => {
        const rUrl = `${WARRANTY_INSTALLER_MASTER_URL}/${installerId}`;
        callGetApi(rUrl, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
                setInsatallerRecord({ ...responseData });
            }
        });
    };

    // get yearly warranty details
    const getYearlyWarrantyDetails = (yearId) => {
        const rUrl = `${Warranty_Yearly_GetById_GET}/${yearId}`;
        callGetApi(rUrl, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;

                const _warrantyType = warrantyTypeOptions.find((obj) => obj.value === responseData.warrantyType);
                setYearlyWarrantyRecord({
                    ...responseData,
                    warrantyType: _warrantyType,
                });
            }
        });
    };

    // Machine search based on model and serial number
    const handleMachineSearch = async (searchMachinefieldName, searchText) => {
        let searchQueryMachine = "";
        setSearchModelResults([]);
        setSearchSerialResults([]);

        if (searchMachinefieldName === "model") {
            equipmentData.model = searchText;
            searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
        } else if (searchMachinefieldName === "serialNo") {
            equipmentData.makerSerialNumber = searchText;
            searchQueryMachine = searchText
                ? equipmentData.model
                    ? `model:${equipmentData.model} AND makerSerialNumber~` + searchText
                    : "makerSerialNumber~" + searchText
                : "";
        }
        if (searchQueryMachine) {
            await machineSearch(searchQueryMachine)
                .then((result) => {
                    if (result) {
                        if (searchMachinefieldName === "model") {
                            if (result && result.length > 0) {
                                setSearchModelResults(result);
                                setNoOptionsModel(false);
                            } else {
                                setNoOptionsModel(true);
                            }
                        } else if (searchMachinefieldName === "serialNo") {
                            if (result && result.length > 0) {
                                setSearchSerialResults(result);
                                setNoOptionsSerial(false);
                            } else {
                                setNoOptionsSerial(true);
                            }
                        }
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the machine!");
                });
        } else {
            searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
        }
    };

    // Select machine from the search result
    const handleModelSelect = (type, currentItem) => {
        if (type === "model") {
            setEquipmentData({
                ...equipmentData,
                model: currentItem.model,
                // equipmentId: currentItem.id,
            });
            setSearchModelResults([]);
        } else if (type === "equipmentNumber") {
            setEquipmentData({
                ...equipmentData,
                model: currentItem.model,
                // equipmentId: currentItem.id,
            });
            setSearchSerialResults([]);
        }
    };

    // Search Customer with customer ID
    const handleCustSearch = async (searchCustfieldName, searchText) => {
        setSearchCustResults([]);
        customerRecord.customerID = searchText;
        customerRecord.id = "";
        customerRecord.customerName = "";
        customerRecord.address = "";
        customerRecord.city = "";
        customerRecord.state = "";
        customerRecord.zipCode = "";
        customerRecord.email = "";
        customerRecord.addressId = "";
        if (searchText) {
            await customerSearch(searchCustfieldName + "~" + searchText)
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchCustResults(result);
                        setNoOptionsCust(false);
                    } else {
                        setNoOptionsCust(true);
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the customer!");
                });
        }
    };

    // Select the customer from search result
    const handleCustSelect = (type, currentItem) => {
        setCustomerRecord({
            ...customerRecord,
            id: currentItem.id,
            customerID: currentItem.customerId,
            customerName: currentItem.fullName,
            address: currentItem.addressDTO?.fullAddress,
            city: currentItem.addressDTO?.district,
            state: currentItem.addressDTO?.regionOrState,
            zipCode: currentItem.addressDTO?.zipCode,
            email: currentItem.email,
            addressId: currentItem.addressDTO?.id,
        });
        setSearchCustResults([]);
    };

    // product fields value change
    const handleEquipmentFieldsChange = (e) => {
        const { name, value } = e.target;
        if (name === "makerSerialNumber") {
            setSerialNoChecked(false);
            setIsExistsSerilNo(true);
        }
        setEquipmentData({ ...equipmentData, [name]: value });
    };

    // customer fields value change
    const handleCustomerFieldsChange = (e) => {
        const { name, value } = e.target;
        setCustomerRecord({ ...customerRecord, [name]: value });
    };

    // purchase fields value change
    const handlePurchaseFieldsChange = (e) => {
        const { name, value } = e.target;
        setPurchaseRecord({ ...purchaseRecord, [name]: value });
    };

    // purchase fields value change
    const handleInsatllerFieldsChange = (e) => {
        const { name, value } = e.target;
        setInsatallerRecord({ ...insatallerRecord, [name]: value });
    };

    // chnage replaced equipment tab text change
    const handleReplaceEquTextChange = (e) => {
        const { name, value } = e.target;
        setReplaceEquipmentData({ ...replaceEquipmentData, [name]: value });
    };

    //
    const handleCheckValidateSerialNumber = () => {
        if (!equipmentData.makerSerialNumber) {
            handleSnack("error", "Serial Number must not e empty.");
            return;
        }
        const rUrl = `${DATA_SVC_EQUIPMENT()}/search?search=makerSerialNumber:"${equipmentData.makerSerialNumber}"`;
        setSerialNoChecked(true);
        callGetApi(
            rUrl,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    if (responseData.length > 0) {
                        setIsExistsSerilNo(true);
                        handleSnack("error", "This Serial Number already exits, please use another.");
                    } else {
                        handleSnack("info", "This is a new serial number");
                        setIsExistsSerilNo(false);
                    }
                } else {
                    setIsExistsSerilNo(false);
                }
            },
            (error) => {
                setIsExistsSerilNo(false);
            }
        );
    };

    // check product tab >> Machine Tab input validation
    const checkProductMachineValidation = () => {
        if (!equipmentData.model) {
            handleSnack("error", "Model must not be empty. Please search and select a Model.");
            return false;
        } else if (equipmentData.model && searchModelResults.length !== 0) {
            handleSnack("info", "Please select a Model from search results.");
            return false;
        } else if (equipmentData.model && noOptionsModel) {
            handleSnack("info", "Search another model and select model.");
            return false;
        } else if (!equipmentData.makerSerialNumber) {
            handleSnack("error", "Serial Number must not be empty.");
            return false;
        } else if (equipmentData.makerSerialNumber && !serialNoChecked) {
            handleSnack("error", "Please click on check and verify it is exists or not.");
            return false;
        } else if (equipmentData.makerSerialNumber && serialNoChecked && isExistsSerilNo) {
            handleSnack("error", `Serial Number ${equipmentData.makerSerialNumber} already exits, please use another.`);
            return false;
        }
        return true;
    };

    // check product tab >> Customer Tab input validation
    const checkProductCustomerValidation = () => {
        // if (!customerRecord.customerID) {
        //     handleSnack(
        //         "error",
        //         "Customer ID must not be empty. Please search and select a Customer."
        //     );
        //     return false;
        // } else
        if (customerRecord.customerID && searchCustResults.length !== 0) {
            handleSnack("info", "Please select a Customer from search results.");
            return false;
        } else if (customerRecord.customerID && noOptionsCust) {
            handleSnack("info", "Search another customer and select a customer.");
            return false;
        }
        return true;
    };

    // check product tab input validation
    const checkProductInputValidation = () => {
        if (productTabValue === "machine") {
            if (!checkProductMachineValidation()) {
                return false;
            }
            return true;
        } else if (productTabValue === "customer") {
            if (!checkProductCustomerValidation()) {
                return false;
            }
            return true;
        }
        return true;
    };

    // click on save and Next button >> Proudct Tab
    const handleProductNextClick = (e) => {
        const { id } = e.target;
        if (!viewOnlyTab.productViewOnly && productTabValue !== "replacedProduct" && !checkProductInputValidation()) {
            return;
        }
        if (id === "productMachine") {
            setProductTabValue("customer");
        } else if (id === "productCustomer") {
            setProductTabValue("replacedProduct");
        } else if (id === "replacedProduct") {
            setTabValue("warrantyData");
        }
    };

    // create equipment data
    const handleAddUpdateEquipment = (e) => {
        const { id } = e.target;
        if (id === "productMachine" && viewOnlyTab?.productMachineViewOnly) {
            setProductTabValue("replacedProduct");
            return;
        } else if (id === "productCustomer" && viewOnlyTab?.productCustomerViewOnly) {
            setTabValue("warrantyData");
            return;
        }
        if (id === "productMachine" && !checkProductMachineValidation()) {
            return;
        } else if (id === "productCustomer" && !checkProductCustomerValidation()) {
            return;
        }
        const rUrl = DATA_SVC_EQUIPMENT();
        const rObj = {
            ...equipmentRecordData,
            id: equRecordId || 0,
            model: equipmentData.model,
            // equipmentNumber: equipmentData.makerSerialNumber,
            makerSerialNumber: equipmentData.makerSerialNumber,
            currentClient: customerRecord.customerID,
            customer: customerRecord.customerName,
            customerId: customerRecord.id,
            addressDTO: customerRecord.addressId
                ? {
                      id: customerRecord.addressId,
                  }
                : null,
            purchaseDate: purchaseRecord.purchaseDate,
            placeOfPurchase: purchaseRecord.placeOfPurchase,
            usedFor: purchaseRecord.usedFor,
            usageCondition: purchaseRecord.usageCondition,
            maker: equipmentData?.make?.value || equipmentData.make || "",
            market: equipmentData?.family?.value || equipmentData.family || "",
            description: equipmentData.description,
        };

        if (equRecordId) {
            callPutApi(
                null,
                `${rUrl}/${equRecordId}`,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        if (replacedEquipmentId) {
                            handleUpdateReplecedEquipment({
                                currentClient: customerRecord.customerID,
                                customer: customerRecord.customerName,
                                customerId: customerRecord.id,
                                addressDTO: customerRecord.addressId
                                    ? {
                                          id: customerRecord.addressId,
                                      }
                                    : null,
                            });
                        }
                        if (id === "productMachine") {
                            setViewOnlyTab({ ...viewOnlyTab, productMachineViewOnly: true });
                            setProductTabValue("replacedProduct");
                        } else if (id === "productCustomer") {
                            setViewOnlyTab({ ...viewOnlyTab, productCustomerViewOnly: true });
                            setTabValue("warrantyData");
                        }
                        handleSnack("success", "Equipment updated successfully.");
                    } else {
                        handleSnack("info", "Equipment update is unsuccessful.");
                    }
                },
                (error) => {
                    handleSnack("error", "Equipment update is unsuccessful.");
                }
            );
        } else {
            callPostApi(
                null,
                rUrl,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        setIsNewCreated(true);
                        if (id === "productMachine") {
                            setViewOnlyTab({ ...viewOnlyTab, productMachineViewOnly: true });
                            setProductTabValue("replacedProduct");
                        } else if (id === "productCustomer") {
                            setViewOnlyTab({ ...viewOnlyTab, productCustomerViewOnly: true });
                            setTabValue("warrantyData");
                        }
                        setEquRecordId(responseData.id);
                        setEquipmentData({
                            ...responseData,
                            equipmentId: responseData.id,
                            make: equipmentData?.make || makeOptions[0],
                            family: equipmentData?.family || familyOptions[0],
                        });
                        // setViewOnlyTab({ ...viewOnlyTab, productViewOnly: true });
                        handleSnack("success", "New Equipment Enrolled successfully.");
                    } else {
                        handleSnack("info", "Enrollment is unsuccessful.");
                    }
                },
                (error) => {
                    handleSnack("error", "Enrollment is unsuccessful.");
                }
            );
        }
    };

    // create and update Replaced equipment data
    const handleAddUpdateReplacedEquipment = () => {
        if (!productReplaced) {
            setProductTabValue("customer");
            return;
        } else if (productReplaced && replacedEquViewOnly) {
            setProductTabValue("customer");
            return;
        }
        const rUrl = DATA_SVC_EQUIPMENT();
        const rObj = {
            ...replaceEquipmentData,
            model: equipmentData.model,
            // equipmentNumber: equipmentData.makerSerialNumber,
            makerSerialNumber: replaceEquipmentData.makerSerialNumber,
            currentClient: customerRecord.customerID,
            customer: customerRecord.customerName,
            customerId: customerRecord.id,
            addressDTO: customerRecord.addressId
                ? {
                      id: customerRecord.addressId,
                  }
                : null,
            purchaseDate: purchaseRecord.purchaseDate,
            placeOfPurchase: purchaseRecord.placeOfPurchase,
            usedFor: purchaseRecord.usedFor,
            usageCondition: purchaseRecord.usageCondition,
            maker: equipmentData?.make?.value || equipmentData.make || "",
            market: equipmentData?.family?.value || equipmentData.family || "",
            description: equipmentData.description,
        };

        if (replacedEquipmentId) {
            callPutApi(
                null,
                `${rUrl}/${replacedEquipmentId}`,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        setViewOnlyTab({ ...viewOnlyTab, productReplacedViewOnly: true });
                        setProductTabValue("customer");
                        setReplacedEquViewOnly(true);
                        handleSnack("success", "Equipment updated successfully.");
                    } else {
                        handleSnack("info", "Equipment update is unsuccessful.");
                    }
                },
                (error) => {
                    handleSnack("error", "Equipment update is unsuccessful.");
                }
            );
        } else {
            callPostApi(
                null,
                rUrl,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        setReplacedEquipmentId(responseData.id);
                        setReplaceEquipmentData({ ...responseData, equipmentId: responseData.id });
                        handleUpdateEquipmet({ replacedEquipmentNumber: responseData.equipmentNumber }, true);
                        setViewOnlyTab({ ...viewOnlyTab, productReplacedViewOnly: true });
                        setReplacedEquViewOnly(true);
                        setProductTabValue("customer");
                        handleSnack("success", "New Equipment Enrolled successfully.");
                    } else {
                        handleSnack("info", "Enrollment is unsuccessful.");
                    }
                },
                (error) => {
                    handleSnack("error", "Enrollment is unsuccessful.");
                }
            );
        }
    };

    // check warranty tab >> Machine Tab input validation
    const checkWarrantyMachineValidation = () => {
        if (!yearlyWarrantyRecord.warrantyType) {
            handleSnack("error", "Warranty Type must not be empty. Please select a Warranty Type.");
            return false;
        } else if (!yearlyWarrantyRecord.warrantyStartDate) {
            handleSnack("error", "Warranty Start Date must not be empty. Please select Warranty Start Date.");
            return false;
        } else if (!yearlyWarrantyRecord.warrantyEndDate) {
            handleSnack("error", "Warranty End Date must not be empty. Please select Warranty End Date.");
            return false;
        } else if (!warrantyRecord.dateOfInstall) {
            handleSnack("error", "Date of install must not be empty. Please select Date of install.");
            return false;
        }
        return true;
    };

    // check warranty tab >> Installer Tab input validation
    const checkWarrantyInstallerValidation = () => {
        // if (!insatallerRecord.companyName) {
        //     handleSnack("error", "Installer Name must not be empty");
        //     return false;
        // }
        return true;
    };

    // check warranty tab input validation
    const checkWarrantyInputValidation = () => {
        if (warrantyTabValue === "machine") {
            if (!checkWarrantyMachineValidation()) {
                return false;
            }
            return true;
        } else if (warrantyTabValue === "installer") {
            if (!checkWarrantyInstallerValidation()) {
                return false;
            }
            return true;
        }
        return true;
    };

    // click on save and Next button >> Proudct Tab
    const handleWarrantySaveClick = (e) => {
        const { id } = e.target;
        if (!equRecordId) {
            handleSnack("error", "Please create an Equipment first.");
            return;
        }
        if (!viewOnlyTab.warrantyViewOnly && !checkWarrantyInputValidation()) {
            return;
        }

        if (id === "warrantyMachine") {
            setWarrantyTabValue("installer");
        } else if (id === "") {
        }
    };

    // add update warranty
    const handleAddUpdateWarranty = () => {
        if (!checkWarrantyMachineValidation()) {
            return;
        } else if (!checkWarrantyInstallerValidation()) {
            return;
        }
        const rUrl = WARRANTY_MASTER_URL;
        const rObj = {
            ...warrantyRecord,
            dateOfInstall: warrantyRecord.dateOfInstall,
            dateOfSale: warrantyRecord.dateOfSale,
            equipmentId: equRecordId,
            warrantyTitle: yearlyWarrantyRecord.warrantyType?.value || "STANDARD",
            title: yearlyWarrantyRecord.warrantyType?.value || "STANDARD",
            //   yearlyWarrantyIds: [yearlyWarrrantyId],
            unit: "MONTHS",
        };
        if (warrantyId) {
            callPutApi(null, `${rUrl}/${warrantyId}`, rObj, (response) => {
                if (response.status === API_SUCCESS) {
                    //   handleAddUpdateInstaller(warrantyId);
                    handleAddUpdateYearlyWarranty(warrantyId);
                }
            });
        } else {
            callPostApi(null, rUrl, rObj, (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setWarrantyId(responseData.warrantyId);
                    handleAddUpdateYearlyWarranty(responseData.warrantyId);
                    //   handleAddUpdateInstaller(responseData.warrantyId);
                }
            });
        }
    };

    // add update yearly-warranty
    const handleAddUpdateYearlyWarranty = (warrantyId) => {
        const rUrl = Warranty_Yearly_GetById_GET;
        const rObj = {
            ...yearlyWarrantyRecord,
            title: yearlyWarrantyRecord?.title || yearlyWarrantyRecord.warrantyType?.value || "STANDARD",
            warrantyType: yearlyWarrantyRecord.warrantyType?.value || yearlyWarrantyRecord?.warrantyType || "STANDARD",
            basis: "TIME",
            warrantyStartDate: yearlyWarrantyRecord.warrantyStartDate,
            warrantyEndDate: yearlyWarrantyRecord.warrantyEndDate,
            warrantyIds: [warrantyId],
        };
        if (yearlyWarrantyId) {
            callPutApi(null, `${rUrl}/${yearlyWarrantyId}`, rObj, (response) => {
                if (response.status === API_SUCCESS) {
                    handleAddUpdateInstaller(warrantyId);
                }
            });
        } else {
            callPostApi(null, rUrl, rObj, (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setYearlyWarrantyId(responseData.yearlyWarrantyId);
                    handleAddUpdateInstaller(warrantyId);
                }
            });
        }
    };

    // add update installer
    const handleAddUpdateInstaller = (warrantyId) => {
        const rUrl = WARRANTY_INSTALLER_MASTER_URL;
        const rObj = {
            ...insatallerRecord,
            companyName: insatallerRecord.companyName,
            email: insatallerRecord.email,
            address: insatallerRecord.address,
            city: insatallerRecord.city,
            state: insatallerRecord.state,
            country: insatallerRecord.country,
            zipCode: insatallerRecord.zipCode,
            phoneNumber: insatallerRecord.phoneNumber,
            warrantyId: warrantyId,
        };
        if (installerId) {
            callPutApi(null, `${rUrl}/${installerId}`, rObj, (response) => {
                if (response.status === API_SUCCESS) {
                    handleUpdateEquipmet({ warrantyId: warrantyId });
                    handleUpdateReplecedEquipment({ warrantyId: warrantyId });
                }
            });
        } else {
            callPostApi(null, rUrl, rObj, (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setInstallerId(responseData.installerId);
                    handleUpdateEquipmet({ warrantyId: warrantyId });
                    handleUpdateReplecedEquipment({ warrantyId: warrantyId });
                } else {
                    handleSnack("error", "Warranty Regestration Failed");
                }
            });
        }
    };

    // generate warranty
    const handleGenerateWarranty = () => {
        handleAddUpdateWarranty();
    };

    // update equipment
    const handleUpdateEquipmet = async (objRes, isReplacment = false) => {
        const rUrl = DATA_SVC_EQUIPMENT();
        const rObj = {
            ...equipmentRecordData,
            id: equRecordId,
            model: equipmentData.model,
            // equipmentNumber: equipmentData.makerSerialNumber,
            makerSerialNumber: equipmentData.makerSerialNumber,
            currentClient: customerRecord.customerID,
            customer: customerRecord.customerName,
            customerId: customerRecord.id,
            addressDTO: customerRecord.addressId
                ? {
                      id: customerRecord.addressId,
                  }
                : null,
            purchaseDate: purchaseRecord.purchaseDate,
            placeOfPurchase: purchaseRecord.placeOfPurchase,
            usedFor: purchaseRecord.usedFor,
            usageCondition: purchaseRecord.usageCondition,
            // warrantyId: `${warrantyId}`,
            maker: equipmentData?.make?.value || equipmentData.make || "",
            market: equipmentData?.family?.value || equipmentData.family || "",
            description: equipmentData.description,
            ...objRes,
            // warrantyId: warrantyId,
        };
        await callPutApi(
            null,
            `${rUrl}/${equRecordId}`,
            rObj,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;

                    if (!isReplacment) {
                        setViewOnlyTab({
                            ...viewOnlyTab,
                            warrantyViewOnly: true,
                        });
                    }
                    setEquipmentRecordData({
                        ...responseData,
                        replacedEquipmentNumber: responseData.replacedEquipmentNumber,
                        warrantyId: responseData.warrantyId,
                    });
                    setEquipmentData({
                        ...responseData,
                        make: equipmentData?.make || makeOptions[0],
                        family: equipmentData?.family || familyOptions[0],
                    });
                    handleSnack("success", "Equipment updated successfully.");
                } else {
                    handleSnack("info", "Equipment update is unsuccessful.");
                }
            },
            (error) => {
                handleSnack("error", "Equipment update is unsuccessful.");
            }
        );
    };

    // update the replaced equipment details
    const handleUpdateReplecedEquipment = async (objRes) => {
        if (replacedEquipmentId) {
            const rUrl = DATA_SVC_EQUIPMENT();
            const rObj = {
                ...replaceEquipmentData,
                id: replacedEquipmentId,
                model: replacedEquipmentId.model,
                makerSerialNumber: replaceEquipmentData.makerSerialNumber,
                currentClient: customerRecord.customerID,
                customer: customerRecord.customerName,
                customerId: customerRecord.id,
                addressDTO: customerRecord.addressId
                    ? {
                          id: customerRecord.addressId,
                      }
                    : null,
                purchaseDate: purchaseRecord.purchaseDate,
                placeOfPurchase: purchaseRecord.placeOfPurchase,
                usedFor: purchaseRecord.usedFor,
                usageCondition: purchaseRecord.usageCondition,
                maker: equipmentData?.make?.value || equipmentData.make || "",
                market: equipmentData?.family?.value || equipmentData.family || "",
                description: equipmentData.description,
                ...objRes,
            };
            await callPutApi(
                null,
                `${rUrl}/${replacedEquipmentId}`,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        setReplaceEquipmentData(responseData);
                        // handleSnack("success", "Equipment updated successfully.");
                    } else {
                        // handleSnack("info", "Equipment update is unsuccessful.");
                    }
                },
                (error) => {
                    // handleSnack("error", "Equipment update is unsuccessful.");
                }
            );
        }
    };

    // create claim
    const handleCreateClaim = () => {
        const rObj = {
            claimNumber: Math.floor(Math.random() * 9000) + 1000,
            modelNumber: equipmentData.model,
            equipmentNumber: equipmentData.equipmentNumber,
            serialNumber: equipmentData.makerSerialNumber,
            componentCode: "",
            claimStatus: "REGISTERED",
            claimType: "STANDARD",
            failDate: new Date(),
            failurePartNumber: "",
            smu: "",
            fleetNo: "",
            hourOnMachine: 0,
            hoursOnFailedPart: 0,
            uploadPhoto: "",
            partList: "",
            repairTime: 0,
            claimStory: "",
            claimNotes: "",
            claimQuestionnaire: "",
            payer: "CUSTOMER",
            claimApprover: "",
            claimReceiptDate: new Date(),
            createdOn: new Date(),
            updatedOn: new Date(),
            createdDate: new Date(),
            closedDate: "",
            appoverRejectedOn: "",
            warrantyId: warrantyId,
            assessmentId: 0,
            evaluationId: 0,
            claimOrderId: 0,
            replacement: true,
        };
        callPostApi(
            null,
            CLAIM_MASTER_URL,
            rObj,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setClaimRecordId(responseData.claimId);
                    setClaimRecordDetail(responseData);
                    handleSnack("success", `Claim Number ${responseData.claimNumber} Created successfully.`);
                    handleCloseRequestModal();
                } else {
                    handleSnack("info", response.data?.message);
                }
            },
            (error) => {
                handleSnack("error", "Something went wrong");
            }
        );
    };

    const handleViewWarrantyOverview = () => {
        handleViewGenerateWarrantyOverview(warrantyId);
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Body>
                    <Modal.Title className="mb-2">{equRecordId ? "Update " : "Register New "}Equipment & Warranty</Modal.Title>
                    <div className="card border px-2 py-1 mb-0">
                        <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={tabValue}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList className="custom-tabs-div" onChange={(e, value) => setTabValue(value)}>
                                        <Tab label="PRODUCT DATA" value="productData" />
                                        <Tab label="WARRANTY DATA" value="warrantyData" disabled={!equRecordId} />
                                    </TabList>
                                </Box>
                                <TabPanel
                                    value="productData"
                                    sx={{
                                        paddingTop: equRecordId ? 0.3 : 1.5,
                                        paddingBottom: 1,
                                    }}
                                >
                                    <div className="card border">
                                        <Box
                                            className="mt-0"
                                            sx={{
                                                width: "100%",
                                                typography: "body1",
                                            }}
                                        >
                                            <TabContext value={productTabValue}>
                                                <Box
                                                    sx={{
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        backgroundColor: "#f8f8f8",
                                                    }}
                                                >
                                                    <TabList
                                                        className="custom-tabs-div"
                                                        sx={{ paddingX: 2 }}
                                                        onChange={(e, value) => setProductTabValue(value)}
                                                    >
                                                        <Tab label="PRODUCT" value="machine" />
                                                        <Tab label="REPLACED PRODUCT" value="replacedProduct" disabled={!equRecordId} />
                                                        <Tab label="CUSTOMER" value="customer" />
                                                    </TabList>
                                                </Box>
                                                {equRecordId && productTabValue === "machine" && (
                                                    <div className="row mt-3 mb-0 mx-3" style={{ justifyContent: "right" }}>
                                                        <button
                                                            className="btn btn-primary text-white"
                                                            onClick={() => setViewOnlyTab({ ...viewOnlyTab, productMachineViewOnly: false })}
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                                {equRecordId && productTabValue === "customer" && (
                                                    <div className="row mt-3 mb-0 mx-3" style={{ justifyContent: "right" }}>
                                                        <button
                                                            className="btn btn-primary text-white"
                                                            onClick={() => setViewOnlyTab({ ...viewOnlyTab, productCustomerViewOnly: false })}
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                                {replacedEquipmentId && productTabValue === "replacedProduct" && (
                                                    <div className="row mt-3 mb-0 mx-3" style={{ justifyContent: "right" }}>
                                                        <button className="btn btn-primary text-white" onClick={() => setReplacedEquViewOnly(false)}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                                <TabPanel value="machine">
                                                    <div className="card border px-3 py-2 mb-3 mt-0">
                                                        {viewOnlyTab.productMachineViewOnly ? (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="EQUIPMENT ID"
                                                                        value={equRecordId}
                                                                        // value={equipmentData.equipmentId}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="MAKE"
                                                                        value={equipmentData.make?.label}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="MODEL"
                                                                        value={equipmentData.model}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="FAMILY"
                                                                        value={equipmentData.family?.label}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="SERIAL NUMBER"
                                                                        // value={equipmentData.serialNo}
                                                                        value={equipmentData.makerSerialNumber}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mt-2 input-fields">
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                EQUIPMENT ID
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                id="equRecordId"
                                                                                placeholder="Equipment Id"
                                                                                value={equRecordId}
                                                                                // value={equipmentData.equipmentId}
                                                                                disabled
                                                                                onChange={handleEquipmentFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MAKE
                                                                            </label>
                                                                            <Select
                                                                                onChange={(e) => setEquipmentData({ ...equipmentData, make: e })}
                                                                                options={makeOptions}
                                                                                value={equipmentData.make}
                                                                                styles={FONT_STYLE_SELECT}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MODEL
                                                                            </label>
                                                                            <SearchBox
                                                                                value={equipmentData.model}
                                                                                onChange={(e) => handleMachineSearch("model", e.target.value)}
                                                                                type="model"
                                                                                result={searchModelResults}
                                                                                onSelect={handleModelSelect}
                                                                                noOptions={noOptionsModel}
                                                                                placeholder="Model Number"
                                                                            />
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                FAMILY
                                                                            </label>
                                                                            <Select
                                                                                onChange={(e) => setEquipmentData({ ...equipmentData, family: e })}
                                                                                options={familyOptions}
                                                                                value={equipmentData.family}
                                                                                styles={FONT_STYLE_SELECT}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                SERIAL NUMBER
                                                                            </label>
                                                                            <div className="input-group ">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control text-primary"
                                                                                    // name="serialNo"
                                                                                    name="makerSerialNumber"
                                                                                    placeholder="Serial Number"
                                                                                    // value={equipmentData.serialNo}
                                                                                    value={equipmentData.makerSerialNumber}
                                                                                    onChange={handleEquipmentFieldsChange}
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <button
                                                                                        className="btn bg-primary text-white"
                                                                                        type="button"
                                                                                        onClick={handleCheckValidateSerialNumber}
                                                                                    >
                                                                                        Check
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <h5>Purchase Data</h5>
                                                    <div className="card border px-3 py-2 mb-0">
                                                        {viewOnlyTab.productMachineViewOnly ? (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="PURCHASE DATE"
                                                                        value={<Moment format="DD/MM/YYYY">{purchaseRecord.purchaseDate}</Moment>}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="PLACE OF PURCHASE"
                                                                        value={purchaseRecord.placeOfPurchase}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="ATTACH PROOF"
                                                                        value={purchaseRecord.attachProof}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="USED FOR"
                                                                        value={purchaseRecord.usedFor}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="USAGE CONDITIONS"
                                                                        value={purchaseRecord.usageCondition}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mt-2 input-fields">
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                PURCHASE DATE
                                                                            </label>
                                                                            <div className="align-items-center date-box">
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <MobileDatePicker
                                                                                        inputFormat="dd/MM/yyyy"
                                                                                        className="form-controldate border-radius-10"
                                                                                        // minDate={generalData.estimationDate}
                                                                                        // maxDate={new Date()}
                                                                                        closeOnSelect
                                                                                        value={purchaseRecord.purchaseDate}
                                                                                        onChange={(e) =>
                                                                                            setPurchaseRecord({
                                                                                                ...purchaseRecord,
                                                                                                purchaseDate: e,
                                                                                            })
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="standard"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: FONT_STYLE,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                PLACE OF PURCHASE
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={purchaseRecord.placeOfPurchase}
                                                                                name="placeOfPurchase"
                                                                                placeholder="Place of purchase"
                                                                                onChange={handlePurchaseFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                ATTACH PROOF
                                                                            </label>
                                                                            <div
                                                                                className="file-upload-input bg-white border-radius-10 d-flex align-items-center justify-content-between cursor"
                                                                                onClick={handleShowFileUploadModal}
                                                                            >
                                                                                <h6 className="text-primary m-0 font-size-16 font-weight-500">
                                                                                    {isEmpty(purchaseRecord.attachProof)
                                                                                        ? "Upload File"
                                                                                        : purchaseRecord.attachProof}
                                                                                </h6>
                                                                                <img
                                                                                    className="mx-1 cursor"
                                                                                    src="../../assets/images/fileUploadIcon.png"
                                                                                    alt="File Upload icon"
                                                                                    // onClick={handleShowFileUploadModal}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                USED FOR
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={purchaseRecord.usedFor}
                                                                                name="usedFor"
                                                                                placeholder="Used For"
                                                                                onChange={handlePurchaseFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                USAGE CONDITIONS
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={purchaseRecord.usageCondition}
                                                                                name="usageCondition"
                                                                                placeholder="Usage Conditions"
                                                                                onChange={handlePurchaseFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div
                                                        className="row mt-3 mb-0"
                                                        style={{
                                                            justifyContent: "right",
                                                        }}
                                                    >
                                                        <button
                                                            className="btn btn-primary text-white mx-2"
                                                            onClick={handleAddUpdateEquipment}
                                                            // onClick={handleProductNextClick}
                                                            id="productMachine"
                                                        >
                                                            {!viewOnlyTab.productMachineViewOnly ? "Save & Next" : "Next"}
                                                        </button>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value="replacedProduct">
                                                    <div className="row input-fields px-2 mb-0">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="form-group">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        style={{
                                                                            alignItems: "start",
                                                                            marginLeft: 0,
                                                                        }}
                                                                        control={
                                                                            <Switch
                                                                                checked={productReplaced}
                                                                                onChange={(e) => setProductReplaced(e.target.checked)}
                                                                            />
                                                                        }
                                                                        labelPlacement="top"
                                                                        label={
                                                                            <span className="text-light-dark font-size-12 font-weight-500">
                                                                                REPLACEMENT
                                                                            </span>
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {productReplaced && (
                                                        <div className="card border px-3 py-2 mb-3 mt-0">
                                                            {!replacedEquViewOnly ? (
                                                                <div className="row input-fields">
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MAKE
                                                                            </label>
                                                                            <Select
                                                                                onChange={(e) => setEquipmentData({ ...equipmentData, make: e })}
                                                                                options={familyOptions}
                                                                                value={equipmentData.make}
                                                                                styles={FONT_STYLE_SELECT}
                                                                                isDisabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MODEL
                                                                            </label>
                                                                            <SearchBox
                                                                                value={equipmentData.model}
                                                                                onChange={(e) => handleMachineSearch("model", e.target.value)}
                                                                                type="model"
                                                                                result={searchModelResults}
                                                                                onSelect={handleModelSelect}
                                                                                noOptions={noOptionsModel}
                                                                                placeholder="Model Number"
                                                                                disabled={true}
                                                                            />
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                FAMILY
                                                                            </label>
                                                                            <Select
                                                                                onChange={(e) => setEquipmentData({ ...equipmentData, family: e })}
                                                                                options={familyOptions}
                                                                                value={equipmentData.family}
                                                                                styles={FONT_STYLE_SELECT}
                                                                                isDisabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="MAKE"
                                                                        value={equipmentData.make?.label}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="MODEL"
                                                                        value={equipmentData.model}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="FAMILY"
                                                                        value={equipmentData.family?.label}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                </div>
                                                            )}
                                                            <p className="mt-4">ITEM DETAILS</p>
                                                            {!replacedEquViewOnly ? (
                                                                <div className="row input-fields">
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="text-light-dark font-size-14 font-weight-500"
                                                                                for="exampleInputEmail1"
                                                                            >
                                                                                SERIAL NUMBER
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                name="makerSerialNumber"
                                                                                placeholder="Serial Number"
                                                                                value={replaceEquipmentData.makerSerialNumber}
                                                                                onChange={handleReplaceEquTextChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-md-4 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label
                                                                            className="text-light-dark font-size-14 font-weight-500"
                                                                            for="exampleInputEmail1"
                                                                        >
                                                                            FAMILY
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control border-radius-10 text-primary"
                                                                            name="market"
                                                                            placeholder="Family"
                                                                            value={replaceEquipmentData?.market}
                                                                            onChange={handleReplaceEquTextChange}
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MERCHANT
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                name="owner"
                                                                                placeholder="Merchant details"
                                                                                value={replaceEquipmentData?.owner}
                                                                                onChange={handleReplaceEquTextChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="SERIAL NUMBER"
                                                                        value={replaceEquipmentData.makerSerialNumber}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    {/* <ReadOnlyField
                                                                        label="FAMILY"
                                                                        value={replaceEquipmentData?.market}
                                                                        className="col-md-3 col-sm-3"
                                                                    /> */}
                                                                    <ReadOnlyField
                                                                        label="MERCHANT"
                                                                        value={replaceEquipmentData?.owner}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                </div>
                                                            )}
                                                            <p className="mt-4">INSTALLATION DETAILS</p>
                                                            {!replacedEquViewOnly ? (
                                                                <div className="row input-fields">
                                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                INSTALLED ON
                                                                            </label>
                                                                            <div className="align-items-center date-box">
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <MobileDatePicker
                                                                                        inputFormat="dd/MM/yyyy"
                                                                                        className="form-controldate border-radius-10"
                                                                                        // maxDate={new Date()}
                                                                                        closeOnSelect
                                                                                        value={replaceEquipmentData.installationDate}
                                                                                        onChange={(e) =>
                                                                                            setReplaceEquipmentData({
                                                                                                ...replaceEquipmentData,
                                                                                                installationDate: e,
                                                                                            })
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="standard"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: FONT_STYLE,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-3">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                PROOF OF INSTALL
                                                                            </label>
                                                                            <div
                                                                                className="file-upload-input bg-white border-radius-10 d-flex align-items-center justify-content-between cursor"
                                                                                onClick={handleShowFileUploadModal}
                                                                            >
                                                                                <h6 className="text-primary m-0 font-size-16 font-weight-500">
                                                                                    {isEmpty(replaceEquipmentData?.proofOfInstall)
                                                                                        ? "Upload File"
                                                                                        : replaceEquipmentData?.proofOfInstall}
                                                                                </h6>
                                                                                <img
                                                                                    className="mx-1 cursor"
                                                                                    src="../../assets/images/fileUploadIcon.png"
                                                                                    alt="File Upload icon"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-3">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                PROOF OF PURCHASE
                                                                            </label>
                                                                            <div
                                                                                className="file-upload-input bg-white border-radius-10 d-flex align-items-center justify-content-between cursor"
                                                                                onClick={handleShowFileUploadModal}
                                                                            >
                                                                                <h6 className="text-primary m-0 font-size-16 font-weight-500">
                                                                                    {isEmpty(replaceEquipmentData?.proofOfPurchase)
                                                                                        ? "Upload File"
                                                                                        : replaceEquipmentData?.proofOfPurchase}
                                                                                </h6>
                                                                                <img
                                                                                    className="mx-1 cursor"
                                                                                    src="../../assets/images/fileUploadIcon.png"
                                                                                    alt="File Upload icon"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-12">
                                                                        <div className="form-group">
                                                                            <FormGroup>
                                                                                <FormControlLabel
                                                                                    style={{
                                                                                        alignItems: "start",
                                                                                        marginLeft: 0,
                                                                                    }}
                                                                                    control={
                                                                                        <Switch
                                                                                            checked={replaceEquipmentData?.replaced}
                                                                                            onChange={(e) =>
                                                                                                setReplaceEquipmentData({
                                                                                                    ...replaceEquipmentData,
                                                                                                    replaced: e.target.checked,
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    labelPlacement="top"
                                                                                    label={
                                                                                        <span className="text-light-dark font-size-12 font-weight-500">
                                                                                            REPLACED
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="INSTALLED ON"
                                                                        value={
                                                                            <>
                                                                                {replaceEquipmentData.installationDate ? (
                                                                                    <Moment format="DD/MM/YYYY">
                                                                                        {replaceEquipmentData.installationDate}
                                                                                    </Moment>
                                                                                ) : (
                                                                                    "NA"
                                                                                )}
                                                                            </>
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="PROOF OF INSTALL"
                                                                        value={
                                                                            <>
                                                                                <img
                                                                                    className="mx-1 cursor"
                                                                                    src="../../assets/images/fileUploadIcon.png"
                                                                                    alt="File Upload icon"
                                                                                />
                                                                                {isEmpty(replaceEquipmentData?.proofOfInstall) ? "No" : "Yes"}
                                                                            </>
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="PROOF OF PURCHASE"
                                                                        value={
                                                                            <>
                                                                                <img
                                                                                    className="mx-1 cursor"
                                                                                    src="../../assets/images/fileUploadIcon.png"
                                                                                    alt="File Upload icon"
                                                                                />
                                                                                {isEmpty(replaceEquipmentData?.proofOfPurchase) ? "No" : "Yes"}
                                                                            </>
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="REPLACED"
                                                                        value={"YES"}
                                                                        // value={replaceEquipmentData.replaced ? "YES" : "NO"}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div
                                                        className="row mt-3 mb-0"
                                                        style={{
                                                            justifyContent: "right",
                                                        }}
                                                    >
                                                        <button className="btn btn-primary text-white" onClick={handleAddUpdateReplacedEquipment}>
                                                            {productReplaced ? (!replacedEquViewOnly ? "Save & Next" : "Next") : "Next"}
                                                        </button>
                                                        {/* {productReplaced && !replacedEquViewOnly && (
                                                            <button className="btn btn-primary text-white" onClick={handleAddUpdateReplacedEquipment}>
                                                                {replacedEquipmentId ? "Update Equipment" : "Register Equipment"}
                                                            </button>
                                                        )} */}
                                                        {/* {equRecordId && (!isEmpty(replacedEquipmentId) || replacedEquViewOnly) && (
                                                            <button
                                                                className="btn btn-primary text-white mx-2"
                                                                onClick={handleProductNextClick}
                                                                id="replacedProduct"
                                                            >
                                                                Next
                                                            </button>
                                                        )} */}
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value="customer">
                                                    <div className="card border px-3 py-2 mb-3 mt-0">
                                                        {viewOnlyTab.productCustomerViewOnly ? (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="CUSTOMER ID"
                                                                        value={customerRecord.customerID}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="CUSTOMER NAME"
                                                                        value={customerRecord.customerName}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="ADDRESS"
                                                                        value={customerRecord.address}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="CITY"
                                                                        value={customerRecord.city}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="STATE"
                                                                        value={customerRecord.state}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="ZIP CODE"
                                                                        value={customerRecord.zipCode}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="CONTACT EMAIL"
                                                                        value={customerRecord.contactEmail}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="PHONE NUMBER"
                                                                        value={customerRecord.contactPhone}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mt-2 input-fields">
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                CUSTOMER ID
                                                                            </label>
                                                                            <SearchBox
                                                                                value={customerRecord.customerID}
                                                                                onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                                                                type="customerId"
                                                                                result={searchCustResults}
                                                                                onSelect={handleCustSelect}
                                                                                noOptions={noOptionsCust}
                                                                                placeholder="Customer Id"
                                                                            />
                                                                            {/* <div className="css-w8dmq8">
                                                                                *Mandatory
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                CUSTOMER NAME
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.customerName}
                                                                                name="customerName"
                                                                                placeholder="Customer Name"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                ADDRESS
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.address}
                                                                                name="address"
                                                                                placeholder="Address"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                CITY
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.city}
                                                                                name="city"
                                                                                placeholder="City"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                STATE
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.state}
                                                                                name="state"
                                                                                placeholder="State"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                ZIP CODE
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.zipCode}
                                                                                name="zipCode"
                                                                                placeholder="Zip Code"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                CONTACT EMAIL
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.contactEmail}
                                                                                name="contactEmail"
                                                                                placeholder="Email"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                PHONE NUMBER
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={customerRecord.contactPhone}
                                                                                name="contactPhone"
                                                                                placeholder="Phone Number"
                                                                                onChange={handleCustomerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="row mt-3 mb-0"
                                                        style={{
                                                            justifyContent: "right",
                                                        }}
                                                    >
                                                        {/* {!viewOnlyTab.productViewOnly && (
                                                            <button className="btn btn-primary text-white" onClick={handleAddUpdateEquipment}>
                                                                {equRecordId ? "Update Equipment" : "Register Equipment"}
                                                            </button>
                                                        )} */}
                                                        {equRecordId && (
                                                            <button
                                                                className="btn btn-primary text-white mx-2"
                                                                // onClick={handleProductNextClick}
                                                                onClick={handleAddUpdateEquipment}
                                                                id="productCustomer"
                                                            >
                                                                {!viewOnlyTab.productCustomerViewOnly ? "Save & Next" : "Next"}
                                                            </button>
                                                        )}
                                                    </div>
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    </div>
                                </TabPanel>
                                <TabPanel
                                    value="warrantyData"
                                    sx={{
                                        paddingTop: equRecordId ? 0.3 : 1.5,
                                        paddingBottom: 1,
                                    }}
                                >
                                    <div className="card border">
                                        <Box
                                            className="mt-0"
                                            sx={{
                                                width: "100%",
                                                typography: "body1",
                                            }}
                                        >
                                            <TabContext value={warrantyTabValue}>
                                                <Box
                                                    sx={{
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        backgroundColor: "#f8f8f8",
                                                    }}
                                                >
                                                    <TabList
                                                        className="custom-tabs-div"
                                                        sx={{ paddingX: 2 }}
                                                        onChange={(e, value) => setWarrantyTabValue(value)}
                                                    >
                                                        <Tab label="MACHINE" value="machine" />
                                                        <Tab label="INSTALLER" value="installer" />
                                                    </TabList>
                                                </Box>
                                                {warrantyId && (
                                                    <div
                                                        className="row my-2 mx-1"
                                                        style={{
                                                            justifyContent: "right",
                                                        }}
                                                    >
                                                        <button
                                                            className="btn btn-primary text-white"
                                                            onClick={() =>
                                                                setViewOnlyTab({
                                                                    ...viewOnlyTab,
                                                                    warrantyViewOnly: false,
                                                                })
                                                            }
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                                <TabPanel value="machine">
                                                    <div className="card border px-3 py-2 mb-3">
                                                        {viewOnlyTab.warrantyViewOnly ? (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="EQUIPMENT ID"
                                                                        // value={equRecordId}
                                                                        value={replacedEquipmentId}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="MAKE"
                                                                        value={equipmentData.make?.label}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="MODEL"
                                                                        value={equipmentData.model}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="FAMILY"
                                                                        value={equipmentData.family.label}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="SERIAL NUMBER"
                                                                        // value={equipmentData.makerSerialNumber}
                                                                        value={replaceEquipmentData.makerSerialNumber}
                                                                        className="col-md-4 col-sm-4"
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mt-2 input-fields">
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                EQUIPMENT ID
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                id="equipmentId"
                                                                                placeholder="Equipment Id"
                                                                                // value={equRecordId}
                                                                                value={replacedEquipmentId}
                                                                                onChange={handleEquipmentFieldsChange}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MAKE
                                                                            </label>
                                                                            <Select
                                                                                onChange={(e) => setEquipmentData({ ...equipmentData, make: e })}
                                                                                options={familyOptions}
                                                                                value={equipmentData.make}
                                                                                styles={FONT_STYLE_SELECT}
                                                                                isDisabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                MODEL
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                id="model"
                                                                                placeholder="Model Number"
                                                                                value={equipmentData.model}
                                                                                onChange={handleEquipmentFieldsChange}
                                                                                disabled
                                                                            />
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                FAMILY
                                                                            </label>
                                                                            <Select
                                                                                onChange={(e) => setEquipmentData({ ...equipmentData, family: e })}
                                                                                options={familyOptions}
                                                                                value={equipmentData.family}
                                                                                styles={FONT_STYLE_SELECT}
                                                                                isDisabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-4">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                SERIAL NUMBER
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                id="makerSerialNumber"
                                                                                // id="serialNo"
                                                                                placeholder="Serial Number"
                                                                                // value={equipmentData.makerSerialNumber}
                                                                                value={replaceEquipmentData.makerSerialNumber}
                                                                                onChange={handleEquipmentFieldsChange}
                                                                                disabled
                                                                            />
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <h5>Warranty Data</h5>
                                                    <div className="card border px-3 py-2 mb-0">
                                                        {viewOnlyTab.warrantyViewOnly ? (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="WARRANTY TYPE"
                                                                        value={yearlyWarrantyRecord.warrantyType?.label}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="WARRANTY VALID FROM"
                                                                        value={
                                                                            <>
                                                                                {yearlyWarrantyRecord.warrantyStartDate ? (
                                                                                    <Moment format="DD/MM/YYYY">
                                                                                        {yearlyWarrantyRecord.warrantyStartDate}
                                                                                    </Moment>
                                                                                ) : (
                                                                                    "NA"
                                                                                )}
                                                                            </>
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="WARRANTY VALID TO"
                                                                        value={
                                                                            yearlyWarrantyRecord.warrantyEndDate ? (
                                                                                <Moment format="DD/MM/YYYY">
                                                                                    {yearlyWarrantyRecord.warrantyEndDate}
                                                                                </Moment>
                                                                            ) : (
                                                                                "NA"
                                                                            )
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="DATE OF INSTALL"
                                                                        value={
                                                                            warrantyRecord.dateOfInstall ? (
                                                                                <Moment format="DD/MM/YYYY">{warrantyRecord.dateOfInstall}</Moment>
                                                                            ) : (
                                                                                "NA"
                                                                            )
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="DATE OF SALE"
                                                                        value={
                                                                            warrantyRecord.dateOfSale ? (
                                                                                <Moment format="DD/MM/YYYY">{warrantyRecord.dateOfSale}</Moment>
                                                                            ) : (
                                                                                "NA"
                                                                            )
                                                                        }
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mt-2 input-fields">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                WARRANTY TYPE
                                                                            </label>
                                                                            <Select
                                                                                // defaultValue={selectedOption}
                                                                                onChange={(e) =>
                                                                                    setYearlyWarrantyRecord({
                                                                                        ...yearlyWarrantyRecord,
                                                                                        warrantyType: e,
                                                                                    })
                                                                                }
                                                                                options={warrantyTypeOptions}
                                                                                value={yearlyWarrantyRecord.warrantyType}
                                                                                styles={FONT_STYLE_SELECT}
                                                                            />
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                WARRANTY VALID FROM
                                                                            </label>
                                                                            <div className="align-items-center date-box">
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <MobileDatePicker
                                                                                        inputFormat="dd/MM/yyyy"
                                                                                        className="form-controldate border-radius-10"
                                                                                        // minDate={generalData.estimationDate}
                                                                                        // maxDate={new Date()}
                                                                                        closeOnSelect
                                                                                        value={yearlyWarrantyRecord.warrantyStartDate}
                                                                                        onChange={(e) =>
                                                                                            setYearlyWarrantyRecord({
                                                                                                ...yearlyWarrantyRecord,
                                                                                                warrantyStartDate: e,
                                                                                            })
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="standard"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: FONT_STYLE,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                WARRANTY VALID TO
                                                                            </label>
                                                                            <div className="align-items-center date-box">
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <MobileDatePicker
                                                                                        inputFormat="dd/MM/yyyy"
                                                                                        className="form-controldate border-radius-10"
                                                                                        // minDate={generalData.estimationDate}
                                                                                        // maxDate={new Date()}
                                                                                        closeOnSelect
                                                                                        value={yearlyWarrantyRecord.warrantyEndDate}
                                                                                        onChange={(e) =>
                                                                                            setYearlyWarrantyRecord({
                                                                                                ...yearlyWarrantyRecord,
                                                                                                warrantyEndDate: e,
                                                                                            })
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="standard"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: FONT_STYLE,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                DATE OF INSTALL
                                                                            </label>
                                                                            <div className="align-items-center date-box">
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <MobileDatePicker
                                                                                        inputFormat="dd/MM/yyyy"
                                                                                        className="form-controldate border-radius-10"
                                                                                        // minDate={generalData.estimationDate}
                                                                                        // maxDate={new Date()}
                                                                                        closeOnSelect
                                                                                        value={warrantyRecord.dateOfInstall}
                                                                                        onChange={(e) =>
                                                                                            setWarrantyRecord({
                                                                                                ...warrantyRecord,
                                                                                                dateOfInstall: e,
                                                                                            })
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="standard"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: FONT_STYLE,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                DATE OF SALE
                                                                            </label>
                                                                            <div className="align-items-center date-box">
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <MobileDatePicker
                                                                                        inputFormat="dd/MM/yyyy"
                                                                                        className="form-controldate border-radius-10"
                                                                                        // minDate={generalData.estimationDate}
                                                                                        // maxDate={new Date()}
                                                                                        closeOnSelect
                                                                                        value={warrantyRecord.dateOfSale}
                                                                                        onChange={(e) =>
                                                                                            setWarrantyRecord({
                                                                                                ...warrantyRecord,
                                                                                                dateOfSale: e,
                                                                                            })
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="standard"
                                                                                                inputProps={{
                                                                                                    ...params.inputProps,
                                                                                                    style: FONT_STYLE,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="row mt-3 mb-0"
                                                        style={{
                                                            justifyContent: "right",
                                                        }}
                                                    >
                                                        <button
                                                            className="btn btn-primary text-white mx-2"
                                                            onClick={handleWarrantySaveClick}
                                                            id="warrantyMachine"
                                                        >
                                                            {!viewOnlyTab.warrantyViewOnly ? "Save & Next" : "Next"}
                                                        </button>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value="installer">
                                                    <div className="card border px-3 py-2 mb-3">
                                                        {viewOnlyTab.warrantyViewOnly ? (
                                                            <>
                                                                <div className="row mt-2">
                                                                    <ReadOnlyField
                                                                        label="INSTALLER NAME"
                                                                        value={insatallerRecord.companyName}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="ADDRESS"
                                                                        value={insatallerRecord.address}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="CITY"
                                                                        value={insatallerRecord.city}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="STATE"
                                                                        value={insatallerRecord.state}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="ZIP CODE"
                                                                        value={insatallerRecord.zipCode}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="COUNTRY"
                                                                        value={insatallerRecord.country}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="CONTACT EMAIL"
                                                                        value={insatallerRecord.email}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                    <ReadOnlyField
                                                                        label="PHONE NUMBER"
                                                                        value={insatallerRecord.phoneNumber}
                                                                        className="col-md-3 col-sm-3"
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mt-2 input-fields">
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                INSTALLER NAME
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.companyName}
                                                                                name="companyName"
                                                                                placeholder="Insataller Name"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                ADDRESS
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.address}
                                                                                name="address"
                                                                                placeholder="Address"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                CITY
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.city}
                                                                                name="city"
                                                                                placeholder="City"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                STATE
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.state}
                                                                                name="state"
                                                                                placeholder="State"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                ZIP CODE
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.zipCode}
                                                                                name="zipCode"
                                                                                placeholder="Zip Code"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                COUNTRY
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.country}
                                                                                name="country"
                                                                                placeholder="Country"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                CONTACT EMAIL
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.email}
                                                                                name="email"
                                                                                placeholder="Email Address"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                                                        <div className="form-group">
                                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                                PHONE NUMBER
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control border-radius-10 text-primary"
                                                                                value={insatallerRecord.phoneNumber}
                                                                                name="phoneNumber"
                                                                                placeholder="Phone Number"
                                                                                onChange={handleInsatllerFieldsChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="row mt-2 mb-0"
                                                        style={{
                                                            justifyContent: "right",
                                                        }}
                                                    >
                                                        {warrantyId && (
                                                            <button className="btn btn-primary text-white mx-2" onClick={handleViewWarrantyOverview}>
                                                                View Warranty Overiew
                                                            </button>
                                                        )}
                                                        {!viewOnlyTab.warrantyViewOnly && (
                                                            <button className="btn btn-primary text-white" onClick={handleGenerateWarranty}>
                                                                {warrantyId ? "Update Warranty" : "Register Warranty"}
                                                            </button>
                                                        )}
                                                        <button className="btn btn-primary text-white mx-2" onClick={hideModal}>
                                                            Close
                                                        </button>
                                                    </div>
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </Modal.Body>
            </Modal>
            {/* {openClaimRequestModal && (
                <ClaimOrderRequestModal
                    show={openClaimRequestModal}
                    hideModal={handleCloseRequestModal}
                    handleSnack={handleSnack}
                    claimRecordDetail={claimRecordDetail}
                    claimOrderId={claimOrderId}
                    setClaimOrderId={setClaimOrderId}
                    claimRecordId={claimRecordId}
                    assesstmentId={assesstmentId}
                    setAssesstmentId={setAssesstmentId}
                    evaluationId={evaluationId}
                    setEvaluationId={setEvaluationId}
                    byAuthCode={true}
                    isEquipment={true}
                />
            )} */}

            {openFileUploadModal && <UploadFilesModal show={openFileUploadModal} hideModal={handleShowFileUploadModal} />}
        </>
    );
};

export default EquipmentEnrollModal;
