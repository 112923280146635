import React, { useCallback, useEffect, useState } from "react";
import "./AIAssistence.css";

import AddIcon from "@mui/icons-material/Add";

import Loader from "react-js-loader";
import { FileUploader } from "react-drag-drop-files";
import { customerSearch, machineSearch } from "services/searchServices";
import { Box } from "@mui/material";

const defaultMessage = {
  sender: "bot",
  text: `Hello there!,<br />How can I help you today?`,
  prompt: ["Warranty", "Quote", "Inventory", "Kit", "Collection"],
  //   promopat: ["Warranty", "Quote", "Inventory", "Kit"],
  //   prompt: `Warranty\nQuote\nInventory\nKit`,
  productList: [],
  showList: false,
  activeStep: 1,
  timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  contentUI: "",
};

const defaultQuestions = [
  { question: "Please begin by entering the customer's number / name for this claim", answer: "", index: 0 },
  { question: "Please select your product's serial number or enter it manually for which you want to create a claim", answer: "", index: 1 },
  { question: "Enter the Model Number", answer: "", index: 2 },
  { question: "Could you please provide the failure code, if applicable?", answer: "", index: 3 },
  { question: "Please specify the defective component, if there is one", answer: "", index: 4 },
  { question: "What are the causal components, if there are any?", answer: "", index: 5 },
  { question: "Please provide the new parts that are required", answer: "", index: 6 },
  { question: "Please specify the quantity needed for the selected part", answer: "", index: 7 },
  { question: "Are you looking to add another part?", answer: "", index: 8 },
  { question: "Please input the number of hours needed to allocate", answer: "", index: 9 },
  { question: "Enter the stipulated hourly rates for the specified hours", answer: "", index: 10 },
  { question: "Please enter any additional hours, if applicable", answer: "", index: 11 },
  { question: "Kindly share the travel distance in kilometers", answer: "", index: 12 },
  { question: "Please input the fixed travel cost per kilometer", answer: "", index: 13 },
  { question: "Please input the vehicle cost, if applicable", answer: "", index: 14 },
];

const quoteDefaultQuestions = [
  { question: "Please begin by entering a short description for the quote", answer: "", index: 0 },
  { question: "Please provide the customer's number / name for the quote.", answer: "", index: 1 },
  { question: "Please select your product's serial number or enter it manually for which you want to create a quote.", answer: "", index: 2 },
  { question: "Please provide the sales office / branch", answer: "", index: 3 },
];

const questionsTypesObj = {
  warranty: false,
  quote: false,
  inventory: false,
  kit: false,
  collection: false,
};

const AIAssistence = ({
  closeModal,
  handleShowGenerativeAIModal,
  handleShowWarrantySummaryModal,
  setAiRecordObj,
  aiPartsRecord = [],
  setAiPartsRecord,
  setWarrantySummaryTitle,
}) => {
  const [warrantyQuestions, setWarrantyQuestions] = useState(defaultQuestions);

  const [questionType, setQuestionType] = useState({ ...questionsTypesObj });

  const [customerData, setCustomerData] = useState({
    customerNumber: "",
    customerName: "",
  });
  const [machineData, setMachineData] = useState({
    make: "",
    family: "",
  });

  const [questionIndex, setQuestionIndex] = useState(0);

  const [messages, setMessages] = useState([defaultMessage]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [startQuestions, setStartQuestions] = useState(false);

  const [activeStep, setActiveStep] = useState(1);

  // update the chat bot messages
  const updateChatbotMessage = useCallback(
    (updateMessage) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          setMessages([...updateMessage]);
          setIsLoading(false);

          // Scroll to the bottom of the page smoothly

          // Get the first element with the class name 'my-class'
          const targetElement = document.getElementsByClassName("chat-window")[0];

          // Scroll to the element smoothly
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });

            const inputElement = document.getElementsByClassName(".userInput")[0];
            if (inputElement) {
              inputElement.focus();
            }
          }
        }, 1500);
      });
    },
    [activeStep]
  );

  // type message
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // send message
  const handleSendMessage = async () => {
    if (isLoading) return;
    if (userInput.trim() === "") return;
    if (startQuestions) {
      if (questionType?.warranty) {
        if (questionIndex === 1) {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          let obj = {};
          setMessages(newMessages);
          await customerSearch("customerId:" + userInput).then(async (response) => {
            if (response && response.length > 0) {
              setCustomerData({
                customerNumber: response[0]?.customerId,
                customerName: response[0]?.fullName,
              });
              setMachineData({
                make: "",
                family: "",
              });
              await machineSearch(`currentClient:${userInput}&pageNumber=0&pageSize=20&sortColumn=id&orderBY=ASC`).then(async (equipmentResponse) => {
                setUserInput("");
                if (equipmentResponse && equipmentResponse.length > 0) {
                  obj = {
                    sender: "bot",
                    text: "Please select your product's serial number or enter it manually for which you want to create a claim.",
                    prompt: [],
                    productList: [...equipmentResponse],
                    showList: questionIndex === 1 ? true : false,
                    activeStep: 4,
                    timestamp: timestamp,
                    contentUI: "",
                  };
                  setTimeout(() => {
                    setIsLoading(true);
                    newMessages.push(obj);
                    updateChatbotMessage(newMessages);
                  }, 500);
                } else {
                  obj = {
                    sender: "bot",
                    text: "Enter a Serial Number manually",
                    prompt: [],
                    productList: [],
                    showList: questionIndex === 1 ? true : false,
                    activeStep: 4,
                    timestamp: timestamp,
                    contentUI: "",
                  };
                  setTimeout(() => {
                    setIsLoading(true);
                    newMessages.push(obj);
                    updateChatbotMessage(newMessages);
                  }, 500);
                }
              });
            } else {
              let obj = {
                sender: "bot",
                text: "Sorry, This Customer number is not exits! <br />Please enter another",
                prompt: [],
                productList: [],
                showList: questionIndex === 1 ? true : false,
                activeStep: 4,
                timestamp: timestamp,
                contentUI: "",
              };
              setUserInput("");
              setTimeout(() => {
                setIsLoading(true);
                newMessages.push(obj);
                updateChatbotMessage(newMessages);
              }, 500);
            }
          });
        } else if (questionIndex === 6 || questionIndex === 7 || questionIndex === 8) {
          let obj = {};
          let _aiPartsRecord = [...aiPartsRecord];
          if (questionIndex === 7) {
            _aiPartsRecord.map((item) =>
              item?.id === _aiPartsRecord.length
                ? { ...item, quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer) }
                : item
            );
          } else {
            if (userInput.toLowerCase() === "st60dcb") {
              _aiPartsRecord.push({
                id: aiPartsRecord.length + 1,
                partNumber: questionIndex === 6 ? userInput : warrantyQuestions[6]?.answer,
                description: "Star 60 DC - DC Board",
                quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer),
                price: 7500,
                relatedPart: true,
                isLabour: false,
                isMachine: false,
              });
            } else if (userInput.toLowerCase() === "st60mb") {
              _aiPartsRecord.push({
                id: aiPartsRecord.length + 1,
                partNumber: questionIndex === 6 ? userInput : warrantyQuestions[6]?.answer,
                description: "Star 60 Main Board",
                quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer),
                price: 26360,
                relatedPart: true,
                isLabour: false,
                isMachine: false,
              });
            } else {
              _aiPartsRecord.push({
                id: aiPartsRecord.length + 1,
                partNumber: questionIndex === 6 ? userInput : warrantyQuestions[6]?.answer,
                description: "test description",
                quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer),
                price: 980,
                relatedPart: true,
                isLabour: false,
                isMachine: false,
              });
            }
          }
          setQuestionIndex(questionIndex + 1);
          const filteredArray = _aiPartsRecord.filter((obj) => Object.keys(obj).length > 0 && obj.partNumber);
          setAiPartsRecord(filteredArray);
          // setAiPartsRecord((prev) => [...prev, obj]);

          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

          // ! user message Start ! //
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          setMessages(newMessages);

          // ! user message End ! //

          // ! bot message start ! //
          const _questionsRecord = [...warrantyQuestions];
          _questionsRecord[questionIndex].answer = userInput;
          setWarrantyQuestions(_questionsRecord);

          let _question = warrantyQuestions[questionIndex + 1];

          let botMessage = {
            sender: "bot",
            text: _question?.question,
            prompt: questionIndex === 7 ? ["Yes", "No"] : [],
            productList: [],
            showList: false,
            activeStep: 4,
            timestamp: timestamp,
            contentUI: "",
          };
          setUserInput("");
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(botMessage);
            updateChatbotMessage(newMessages);
          }, 500);

          // ! bot message End ! //

          // setQuestionIndex(questionIndex + 1);
        } else {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          setMessages(newMessages);

          if (questionIndex === warrantyQuestions.length - 1) {
            let obj = {
              sender: "bot",
              text: `Please wait a sec, generating your claim`,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);

            obj = {
              sender: "bot",
              text: `Kindly hold on for a moment as we redirect you to the claim summary. Please check the information and submit the details.`,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };

            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 400);

            const _failureCode = warrantyQuestions.find((item) => item?.index === 3);
            const _partCausingFailure = warrantyQuestions.find((item) => item?.index === 4);

            setAiRecordObj({
              customerNumber: customerData?.customerNumber || "",
              customerName: customerData?.customerName || "",
              serialNumber: warrantyQuestions[1]?.answer,
              model: warrantyQuestions[2]?.answer,
              make: machineData?.make,
              family: machineData?.family,
              failureCode: _failureCode?.answer,
              partCausingFailure: _partCausingFailure?.answer,
            });

            const _aiPartsRecord = [...aiPartsRecord];
            _aiPartsRecord.push(
              {
                id: aiPartsRecord.length + 1,
                partNumber: "Labour (In hours)",
                description: "",
                quantity: Number(warrantyQuestions[9]?.answer) || 5,
                price: 70,
                relatedPart: false,
                isLabour: true,
                isMachine: false,
              },
              {
                id: aiPartsRecord.length + 2,
                partNumber: "Travel (In km)",
                description: "",
                quantity: Number(warrantyQuestions[12]?.answer) || 20,
                price: 50,
                relatedPart: false,
                isLabour: false,
                isMachine: true,
              }
            );
            setAiPartsRecord(_aiPartsRecord);

            setUserInput("");
            setTimeout(() => {
              closeModal();
              handleShowWarrantySummaryModal();
            }, 2500);
          } else {
            const _questionsRecord = [...warrantyQuestions];
            _questionsRecord[questionIndex].answer = userInput;
            setWarrantyQuestions(_questionsRecord);

            let _question = warrantyQuestions[questionIndex + 1];

            let obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          }

          if (questionIndex < warrantyQuestions.length) {
            setQuestionIndex(questionIndex + 1);
          }
        }
      } else if (questionType?.warranty) {
      }
    } else {
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      const lastActiveStep = messages[messages.length - 1];
      const newMessages = [
        ...messages,
        {
          sender: "user",
          text: userInput,
          prompt: "",
          productList: [],
          showList: false,
          activeStep: lastActiveStep?.activeStep + 1,
          timestamp: timestamp,
          contentUI: "",
        },
      ];
      setMessages(newMessages);
      setUserInput("");
    }
  };

  // Image|File upload Modal box show|hide
  const handleImageFileUpload = (e, value) => {
    const newMessages = [
      ...messages,
      {
        sender: "user",
        text: e.name,
        timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        prompt: [],
        productList: [],
        showList: false,
        activeStep: 1,
        contentUI: "",
      },
    ];
    setMessages(newMessages);

    setTimeout(() => {
      //   setIsLoading(true);
      closeModal();
      handleShowWarrantySummaryModal();
    }, 4000);
  };

  // select file
  const handleFileSelect = (file) => {
    // You can handle the selected file here
  };

  // warranty questions-answer flow
  const handleWarrantyProcess = () => {};

  // select question prompat
  const handleSelectPrompt = async (e, activeSteper) => {
    const { innerText } = e.target;
    let obj = {};
    const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    setStartQuestions(true);

    if (activeSteper === 1) {
      let _textMessage = "";
      let _prompt = [];

      let newMessages = [...messages];
      let questionsObj = { ...questionsTypesObj };
      if (innerText.toLowerCase() === "warranty") {
        questionsObj = { ...questionsObj, warranty: true };

        _textMessage = `For the warranty, please select one of the processes listed below.`;
        _prompt = ["Upload Service Report", "Input Claim Manually", "Guided Claim Creation"];
      } else if (innerText.toLowerCase() === "quote") {
        questionsObj = { ...questionsObj, quote: true };

        _textMessage = `For the quote, please select one of the processes listed below.`;
        _prompt = ["Upload Quote", "Input Quote Manually", "Guided Quote Creation"];
      } else if (innerText.toLowerCase() === "inventory") {
        questionsObj = { ...questionsObj, inventory: true };

        _textMessage = `For the quote, please select one of the processes listed below.`;
        _prompt = ["Upload Invoice", "Input Quote Manually", "Guided Quote Creation"];
      } else if (innerText.toLowerCase() === "kit") {
        questionsObj = { ...questionsObj, kit: true };

        _textMessage = `For the kit, please select one of the processes listed below.`;
        _prompt = ["Upload Kit Items", "Input Kit Manually", "Guided Kit Creation"];
      } else if (innerText.toLowerCase() === "collection") {
        questionsObj = { ...questionsObj, collection: true };

        _textMessage = `For the Collection, please select one of the processes listed below.`;
        _prompt = ["Upload Collection Items", "Input Collection Manually", "Guided Collection Creation"];
      }

      newMessages = [
        ...messages,
        {
          sender: "user",
          text: innerText,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
          contentUI: "",
        },
      ];

      setWarrantySummaryTitle(`${innerText} Summary`);
      setMessages(newMessages);

      obj = {
        sender: "bot",
        text: `${_textMessage}`,
        prompt: _prompt,
        productList: [],
        showList: false,
        activeStep: 2,
        timestamp: timestamp,
      };
      setTimeout(() => {
        setIsLoading(true);
        newMessages.push(obj);
        updateChatbotMessage(newMessages);
      }, 500);
      setQuestionType(questionsObj);
    } else if (activeSteper === 2) {
      let textmessage = innerText;
      const newMessages = [
        ...messages,
        { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
      ];
      setMessages(newMessages);

      let _textMessage = "";
      let _prompt = [];
      let _contentUI = "";

      if (questionType?.warranty) {
        if (textmessage === "Upload Service Report") {
          _textMessage = `Do you have a service report you’d like to upload to create the claim? Click here to upload`;
          _prompt = [];

          _contentUI = (
            <div className="card py-3 px-1" style={{ width: "100%" }}>
              <div className="justify-content-center">
                <h6 className="text-center font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <Box sx={{ minWidth: "100px", width: "100%" }}>
                  <FileUploader
                    name="file"
                    types={["JPG", "PNG"]}
                    sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                    style={{ minWidth: "200px" }}
                    handleChange={handleImageFileUpload}
                    onSelect={handleFileSelect}
                  />
                </Box>
              </div>
            </div>
          );
        } else if (textmessage === "Input Claim Manually") {
          _textMessage = `Kindly hold on for a moment to enter details manually`;
          _prompt = [];

          _contentUI = "";
        } else if (textmessage === "Guided Claim Creation") {
          _textMessage = `Prefer a step-by-step guide to creating your claim? Let me ask you a few questions to get started.`;
          _prompt = [];
          _contentUI = "";
        }
      } else if (questionType?.quote) {
        if (textmessage === "Upload Invoice") {
          _textMessage = "Do you have an invoice you’d like to upload to create the quote? Click here to upload";
          _prompt = [];
          _contentUI = (
            <div className="card py-3 px-1" style={{ width: "100%" }}>
              <div className="justify-content-center">
                <h6 className="text-center font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <Box sx={{ minWidth: "100px", width: "100%" }}>
                  <FileUploader
                    name="file"
                    types={["JPG", "PNG"]}
                    sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                    style={{ minWidth: "200px" }}
                    handleChange={handleImageFileUpload}
                    onSelect={handleFileSelect}
                  />
                </Box>
              </div>
            </div>
          );
        } else if (textmessage === "Input Quote Manually") {
          _textMessage = "Want to enter details manually? Let’s start with the serial number or model.";
          _prompt = [];
          _contentUI = "";
        } else if (textmessage === "Guided Quote Creation") {
          _textMessage = "Prefer a step-by-step guide to creating your quote? Let me ask you a few questions to get started.";
          _prompt = [];
          _contentUI = "";
        }
      } else if (questionType?.collection) {
        if (textmessage === "Upload Collection Items") {
          _textMessage = "Do you have an invoice you’d like to upload to create the quote? Click here to upload";
          _prompt = [];
          _contentUI = (
            <div className="card py-3 px-1" style={{ width: "100%" }}>
              <div className="justify-content-center">
                <h6 className="text-center font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <Box sx={{ minWidth: "100px", width: "100%" }}>
                  <FileUploader
                    name="file"
                    types={["JPG", "PNG"]}
                    sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                    style={{ minWidth: "200px" }}
                    handleChange={handleImageFileUpload}
                    onSelect={handleFileSelect}
                  />
                </Box>
              </div>
            </div>
          );
        } else if (textmessage === "Input Collection Manually") {
          _textMessage = "Want to enter details manually? Let’s start with the serial number or model.";
          _prompt = [];
          _contentUI = "";
        } else if (textmessage === "Guided Collection Creation") {
          _textMessage = "Prefer a step-by-step guide to creating your quote? Let me ask you a few questions to get started.";
          _prompt = [];
          _contentUI = "";
        }
      }

      obj = {
        sender: "bot",
        text: `${_textMessage}`,
        prompt: _prompt,
        productList: [],
        showList: false,
        activeStep: 3,
        timestamp: timestamp,
        contentUI: _contentUI,
      };
      setTimeout(() => {
        setIsLoading(true);
        newMessages.push(obj);
        updateChatbotMessage(newMessages);
        if (questionType?.warranty && textmessage === "Guided Claim Creation") {
          setQuestionIndex(1);
          let _question = warrantyQuestions[0];
          obj = {
            sender: "bot",
            text: _question?.question,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 4,
            timestamp: timestamp,
            contentUI: "",
          };
          setStartQuestions(true);
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(obj);
            updateChatbotMessage(newMessages);
          }, 500);
        }
      }, 500);
      if (textmessage === "Input Claim Manually" || textmessage === "Input Quote Manually" || textmessage === "Input Collection Manually") {
        setTimeout(() => {
          closeModal();
          handleShowGenerativeAIModal();
        }, 4000);
      }
    } else if (activeSteper === 4) {
      const newMessages = [
        ...messages,
        { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
      ];

      setMessages(newMessages);
      if (innerText.toLowerCase() === "yes") {
        let _question = warrantyQuestions[6];

        let obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
        setUserInput("");
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
        setQuestionIndex(6);
      } else if (innerText.toLowerCase() === "no") {
        let _question = warrantyQuestions[9];
        const _questionsRecord = [...warrantyQuestions];
        _questionsRecord[8].answer = innerText;
        setWarrantyQuestions(_questionsRecord);

        let obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
        setUserInput("");
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
        setQuestionIndex(9);
      }
    }

    // setActiveStep(activeStep + 1);
  };

  // handle the chatbot summary
  const handleChatbotSummary = async (conversationHistory) => {
    setIsLoading(true);
    const userResponses = conversationHistory
      .filter((msg) => msg.sender === "user")
      .map((msg) => msg.text)
      .join("\n");
    const prompt = `Here are the user's responses:\n${userResponses}\nPlease summarize the warranty claim in a clear and concise format.`;
    try {
      const botMessage = "Hello";
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      setMessages([...conversationHistory, { sender: "bot", text: botMessage, timestamp: timestamp }]);
    } catch (error) {
      console.error("Error fetching summary from OpenAI:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  // select the Producct|Serial Number
  const handleProductSelect = async (product) => {
    try {
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      const newMessages = [
        ...messages,
        {
          sender: "user",
          text: `${product?.makerSerialNumber}`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
          contentUI: "",
        },
      ];

      setMachineData({
        make: product?.market,
        family: product?.maker,
      });
      setMessages(newMessages);

      let _question = warrantyQuestions[3];
      const _questionsRecord = [...warrantyQuestions];
      _questionsRecord[1].answer = product?.makerSerialNumber;
      _questionsRecord[2].answer = product?.model;
      setWarrantyQuestions(_questionsRecord);
      setQuestionIndex(3);

      let obj = {
        sender: "bot",
        text: `Model Number is ${product?.model ? product?.model : "null"} for serial number ${product?.makerSerialNumber}`,
        prompt: [],
        productList: [],
        showList: false,
        activeStep: 4,
        timestamp: timestamp,
        contentUI: "",
      };
      setIsLoading(true);
      setTimeout(async () => {
        newMessages.push(obj);
        await updateChatbotMessage(newMessages);

        obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };

        setIsLoading(true);
        setTimeout(() => {
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
      }, 500);
    } catch (error) {}
  };

  return (
    <div className="app-container">
      <div className="chat-header">
        <svg id="bot-avatar" data-name="Layer 1" className="bot-avatar" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 125.88 135.35">
          <path d="M57.49,29.2V23.53a14.41,14.41,0,0,1-2-.93A12.18,12.18,0,0,1,50.44,7.5a12.39,12.39,0,0,1,2.64-3.95A12.21,12.21,0,0,1,57,.92,12,12,0,0,1,61.66,0,12.14,12.14,0,0,1,72.88,7.5a12.14,12.14,0,0,1,0,9.27,12.08,12.08,0,0,1-2.64,3.94l-.06.06a12.74,12.74,0,0,1-2.36,1.83,11.26,11.26,0,0,1-2,.93V29.2H94.3a15.47,15.47,0,0,1,15.42,15.43v2.29H115a7.93,7.93,0,0,1,7.9,7.91V73.2A7.93,7.93,0,0,1,115,81.11h-5.25v2.07A15.48,15.48,0,0,1,94.3,98.61H55.23L31.81,118.72a2.58,2.58,0,0,1-3.65-.29,2.63,2.63,0,0,1-.63-1.85l1.25-18h-.21A15.45,15.45,0,0,1,13.16,83.18V81.11H7.91A7.93,7.93,0,0,1,0,73.2V54.83a7.93,7.93,0,0,1,7.9-7.91h5.26v-2.3A15.45,15.45,0,0,1,28.57,29.2H57.49ZM82.74,47.32a9.36,9.36,0,1,1-9.36,9.36,9.36,9.36,0,0,1,9.36-9.36Zm-42.58,0a9.36,9.36,0,1,1-9.36,9.36,9.36,9.36,0,0,1,9.36-9.36Zm6.38,31.36a2.28,2.28,0,0,1-.38-.38,2.18,2.18,0,0,1-.52-1.36,2.21,2.21,0,0,1,.46-1.39,2.4,2.4,0,0,1,.39-.39,3.22,3.22,0,0,1,3.88-.08A22.36,22.36,0,0,0,56,78.32a14.86,14.86,0,0,0,5.47,1A16.18,16.18,0,0,0,67,78.22,25.39,25.39,0,0,0,72.75,75a3.24,3.24,0,0,1,3.89.18,3,3,0,0,1,.37.41,2.22,2.22,0,0,1,.42,1.4,2.33,2.33,0,0,1-.58,1.35,2.29,2.29,0,0,1-.43.38,30.59,30.59,0,0,1-7.33,4,22.28,22.28,0,0,1-7.53,1.43A21.22,21.22,0,0,1,54,82.87a27.78,27.78,0,0,1-7.41-4.16l0,0ZM94.29,34.4H28.57A10.26,10.26,0,0,0,18.35,44.63V83.18A10.26,10.26,0,0,0,28.57,93.41h3.17a2.61,2.61,0,0,1,2.41,2.77l-1,14.58L52.45,94.15a2.56,2.56,0,0,1,1.83-.75h40a10.26,10.26,0,0,0,10.22-10.23V44.62A10.24,10.24,0,0,0,94.29,34.4Z" />
        </svg>
        <div className="chat-header-details">
          <h2 className="text-white">AI Assistant</h2>
          <span>24×7 Support Bot</span>
        </div>
      </div>

      <div className="chat-window">
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <>
              <div key={index} className={`message ${msg?.sender === "user" ? "user-message" : "bot-message"}`}>
                <div
                  className="message-text"
                  dangerouslySetInnerHTML={{
                    __html: msg?.text,
                  }}
                ></div>
                <div className="message-meta">
                  <span className="timestamp">{msg.timestamp}</span>
                </div>
              </div>

              <div className="bot-message-prompt">
                {msg?.prompt.length > 0 &&
                  msg?.prompt.map((prompt, j) => (
                    <p key={`prompt-${j}`} className="py-2 px-2" onClick={(e) => handleSelectPrompt(e, msg?.activeStep)}>
                      {prompt}
                    </p>
                  ))}
              </div>

              {msg?.contentUI && <div className="bot-message-card">{msg?.contentUI}</div>}
              {msg?.showList && msg?.productList.length > 0 && (
                <div className="product-list">
                  {msg?.productList.map(
                    (product) =>
                      product?.makerSerialNumber && (
                        <button key={product.id} className="product-option" onClick={() => handleProductSelect(product)}>
                          {product.makerSerialNumber}
                        </button>
                      )
                  )}
                </div>
              )}
            </>
          ))}

          {isLoading && (
            <div className="bot-message-typing-indicator typing-indicator">
              <div className="d-flex align-items-center justify-content-start">
                <Loader type="bubble-top" bgColor={"#808080"} title={""} color={"#FFFFFF"} size={25} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          id="userInput"
          className="userInput"
          onKeyPress={handleKeyPress}
          autoComplete="off"
          placeholder="Ask anything ..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default AIAssistence;
