import React, { useState } from "react";

import DataTable from "react-data-table-component";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import LoadingProgress from "pages/Repair/components/Loader";

const GIMaster = () => {
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  const columns = [
    {
      id: "partNumber",
      name: "Part Number",
      selector: (row) => row.partNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partNumber || "-",
    },
    {
      id: "partDescription",
      name: "Description",
      selector: (row) => row.partDescription,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partDescription || "-",
    },
    {
      id: "partType",
      name: "Type",
      selector: (row) => row.partType,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partType || "-",
    },
    {
      id: "reference",
      name: "Reference",
      selector: (row) => row.reference,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.reference || "-",
    },
    {
      id: "referenceId",
      name: "Reference ID",
      selector: (row) => row.referenceId,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.referenceId || "-",
    },
    {
      id: "sourcePlant",
      name: "Source Plant",
      selector: (row) => row.sourcePlant,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.sourcePlant || "-",
    },
    {
      id: "destPlant",
      name: "Dest Plant",
      selector: (row) => row.destPlant,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.destPlant || "-",
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.destPlant,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.destPlant || "-",
    },
    {
      id: "action",
      name: "Action",
      selector: (row) => row.destPlant,
      wrap: true,
      minWidth: 200,
      cell: (row) => row.destPlant || "-",
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid mt-3">
            <h4 className="font-weight-600 mb-0 mt-4">GI</h4>
            <div className="mb-4 mt-2">
              <DataTable
                data={[]}
                columns={columns}
                // customStyles={expendTableCustomStyle}
                paginationPerPage={perPage}
                onChangePage={(e) => setPageNo(e)}
                onChangeRowsPerPage={(e) => {
                  setPageNo(1);
                  setPerPage(e);
                }}
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions={[5, 10]}
                paginationDefaultPage={pageNo}
                progressPending={loading}
                progressComponent={<LoadingProgress />}
                persistTableHead
                pagination
                paginationServer
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GIMaster;
