import { Modal } from "react-bootstrap";
import { default as Select, default as SelectFilter } from "react-select";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useState } from "react";
import { callPostApi } from "services/ApiCaller";
import { AddLaborItemToLabor, AddLaborToService } from "services/repairBuilderServices";

const customStyle = {
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      background: isDisabled ? "#e9ecef" : "white",
      borderRadius: 10,
      fontSize: 12,
    };
  },
  singleValue: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: "#616161",
      borderRadius: 10,
      fontSize: 12,
      fontWeight: 500,
    };
  },
};

const recordObj = {
  id: 0,
  chargeCode: "",
  laborType: "",
  serviceType: "",
  unitOfMeasure: "",
  estimatedHours: 0,
  targetHours: 0,
  plannedHours: 0,
  unitPrice: 0,
  extendedPrice: 0,
  totalPrice: 0,
  currency: "USD",
  comment: "",
  travelIncluded: true,
  travelCharge: 0,
  inspectionIncluded: true,
  inspectionCharge: 0,
};

const LaborItemAddUpdate = ({
  show,
  hideModal,
  operationData,
  chargeCodeList,
  laborTypeList,
  serviceTypeList,
  unitOfMeasureOptions,
  handleSnack,
  operationIndex,
  segmentIndex,
  segmentItems,
  setSegmentItems,
  labourItems,
  setLabourItems,
}) => {
  const [recordData, setRecordData] = useState({ ...recordObj });

  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const handleSaveClick = () => {
    if (!operationData?.labourDetails) {
      let laborData = {
        ...(operationData?.labourDetails?.id && { id: operationData?.labourDetails?.id }),
        jobCode: operationData.jobCode,
        jobCodeDescription: operationData.jobCodeDescription,
        laborCode: "Offsite",
        pricingMethod: "LIST",
        // laborCode: labourData.laborCode?.value,
        // totalHours: labourData.totalHours,
        // // payer: labourData.payer,
      };
      AddLaborToService(operationData?.id, laborData)
        .then((result) => {
          let data = {
            // ...recordData,
            ...(recordData.id && { id: recordData.id }),
            chargeCode: recordData.chargeCode?.value,
            laborType: recordData.laborType?.value,
            serviceType: recordData.serviceType?.value,
            unitOfMeasure: recordData.unitOfMeasure?.value,
            estimatedHours: recordData.estimatedHours,
            comment: recordData.comment,
            travelIncluded: recordData.travelIncluded,
            travelCharge: recordData.travelCharge,
            inspectionIncluded: recordData.inspectionIncluded,
            inspectionCharge: recordData.inspectionCharge,
            currency: recordData.currency,
          };
          AddLaborItemToLabor(result.id, data).then(() => {
            const _segmentItems = { ...segmentItems };
            const _segments = _segmentItems["segments"];

            const _operations = _segments[segmentIndex]["operations"];
            const _labourDetails = _operations[operationIndex]["labourDetails"];
            _labourDetails["labourItems"].push(result?.id);
            setSegmentItems(_segmentItems);

            let _labourItems = { ...labourItems };
            let _labourItemsResult = _labourItems["result"];
            let _labourItemsTotalRows = _labourItems["totalRows"];
            _labourItemsResult.push(result);
            _labourItemsTotalRows = _labourItemsResult.length;
            setLabourItems({ ..._labourItems });

            hideModal();
            handleSnack("success", "Added labor item successfully");
          });
          //   setLabourData({
          //     ...result,
          //     id: result.id,
          //     pricingMethod: LABOR_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
          //     totalPrice: result.totalPrice ? result.totalPrice : 0,
          //     laborCode: laborCodeList.find((element) => element.value === result.laborCode),
          //   });
          //   handleSnack("success", "Labour details updated!");
          //   setLaborViewOnly(true);
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating labor details!");
        });
    } else {
      let data = {
        // ...recordData,
        ...(recordData.id && { id: recordData.id }),
        chargeCode: recordData.chargeCode?.value,
        laborType: recordData.laborType?.value,
        serviceType: recordData.serviceType?.value,
        unitOfMeasure: recordData.unitOfMeasure?.value,
        estimatedHours: recordData.estimatedHours,
        comment: recordData.comment,
        travelIncluded: recordData.travelIncluded,
        travelCharge: recordData.travelCharge,
        inspectionIncluded: recordData.inspectionIncluded,
        inspectionCharge: recordData.inspectionCharge,
        currency: recordData.currency,
      };
      AddLaborItemToLabor(operationData?.labourDetails?.id, data).then((result) => {
        const _segmentItems = { ...segmentItems };
        const _segments = _segmentItems["segments"];

        const _operations = _segments[segmentIndex]["operations"];
        const _labourDetails = _operations[operationIndex]["labourDetails"];
        _labourDetails["labourItems"].push(result?.id);
        setSegmentItems(_segmentItems);

        let _labourItems = { ...labourItems };
        let _labourItemsResult = _labourItems["result"];
        let _labourItemsTotalRows = _labourItems["totalRows"];
        _labourItemsResult.push(result);
        _labourItemsTotalRows = _labourItemsResult.length;
        setLabourItems({ ..._labourItems });

        hideModal();

        handleSnack("success", "Added labor item successfully");
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal-header-border">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 bg-white">
          <div>
            <div className="p-3">
              <div className="row input-fields mt-4">
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">CHARGE CODE</label>
                    <Select
                      //   isDisabled={recordData.isEditing}
                      onChange={(e) => setRecordData({ ...recordData, chargeCode: e })}
                      styles={customStyle}
                      options={chargeCodeList}
                      value={recordData.chargeCode}
                    />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">LABOR TYPE</label>
                    <Select
                      onChange={(e) => setRecordData({ ...recordData, laborType: e })}
                      //   isDisabled={recordData.isEditing}
                      styles={customStyle}
                      options={laborTypeList}
                      value={recordData.laborType}
                    />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">SERVICE TYPE</label>
                    <Select
                      onChange={(e) => setRecordData({ ...recordData, serviceType: e })}
                      //   isDisabled={recordData.isEditing}
                      styles={customStyle}
                      options={serviceTypeList}
                      value={recordData.serviceType}
                    />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">UNIT OF MEASURES</label>
                    <Select
                      onChange={(e) => setRecordData({ ...recordData, unitOfMeasure: e })}
                      //   isDisabled={recordData.isEditing}
                      styles={customStyle}
                      options={unitOfMeasureOptions}
                      value={recordData.unitOfMeasure}
                    />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group w-100 date-box">
                    <label className="text-light-dark font-size-12 font-weight-500">ESTIMATED HOURS</label>
                    <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                      <input
                        type="text"
                        className="form-control rounded-top-left-0 rounded-bottom-left-0"
                        // // style={{width: '64%'}}
                        onChange={(e) =>
                          setRecordData({
                            ...recordData,
                            estimatedHours: e.target.value,
                            extendedPrice: e.target.value > 0 ? parseFloat(recordData.unitPrice * e.target.value).toFixed(2) : 0,
                            totalPrice: e.target.value > 0 ? parseFloat(recordData.unitPrice * e.target.value).toFixed(2) : 0,
                          })
                        }
                        value={recordData.estimatedHours}
                      />
                      <span className="hours-div" style={{ float: "left", width: "60%" }}>
                        {recordData.unitOfMeasure?.label ? recordData.unitOfMeasure?.label : "Unit Of Measure"}
                      </span>
                    </div>
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">UNIT PRICE</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={recordData.unitPrice} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">EXTENDED PRICE</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={recordData.extendedPrice} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">TOTAL PRICE</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={recordData.totalPrice} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={operationData?.currency} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div class="form-group w-100">
                    <label className="text-light-dark font-size-12 font-weight-500">COMMENTS (More Action)</label>
                    <input
                      type="text"
                      class="form-control border-radius-10 text-primary"
                      value={recordData.comment}
                      onChange={(e) => setRecordData({ ...recordData, comment: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={recordData.travelIncluded}
                              onChange={(e) => setRecordData({ ...recordData, travelIncluded: e.target.checked })}
                            />
                          }
                          label="TRAVEL INCLUDED"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={recordData.inspectionIncluded}
                              onChange={(e) => setRecordData({ ...recordData, inspectionIncluded: e.target.checked })}
                            />
                          }
                          label="INSPECTION INCLUDED"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  {!recordData.travelIncluded && (
                    <div class="form-group w-100">
                      <label className="text-light-dark font-size-12 font-weight-500">TRAVEL CHARGES</label>
                      <input
                        type="text"
                        class="form-control border-radius-10 text-primary"
                        value={recordData.travelCharge}
                        onChange={(e) => setRecordData({ ...recordData, travelCharge: e.target.value })}
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-6 col-sm-6">
                  {!recordData.inspectionIncluded && (
                    <div class="form-group w-100">
                      <label className="text-light-dark font-size-12 font-weight-500">INSPECTION CHARGES</label>
                      <input
                        type="text"
                        class="form-control border-radius-10 text-primary"
                        value={recordData.inspectionCharge}
                        onChange={(e) => setRecordData({ ...recordData, inspectionCharge: e.target.value })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="m-3 text-right">
              <button
                type="button"
                className="btn btn-light bg-primary text-white"
                //    onClick={props.handleLaborItemClose}
              >
                {" "}
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-light bg-primary text-white"
                // onClick={props.addLaborItem}
                onClick={handleSaveClick}
                disabled={
                  !(
                    recordData.chargeCode &&
                    recordData.laborType &&
                    recordData.serviceType &&
                    recordData.unitOfMeasure &&
                    recordData.estimatedHours &&
                    (!recordData.travelIncluded ? recordData.travelCharge : true) &&
                    (!recordData.inspectionIncluded ? recordData.inspectionCharge : true)
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LaborItemAddUpdate;
