import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";

import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    LinearProgress,
    Tab,
    Typography,
    linearProgressClasses,
    styled,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";

import $ from "jquery";

import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList, BarChart, Legend, PieChart, Pie, Cell, Label } from "recharts";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import ShowInsights from "./ShowInsights";
import InsightLanding from "./InsightLanding";
import ProgressPie from "./ProgressPie";
import SelectBox from "./SelectBox";
import ClaimsTrend from "./ClaimsTrend";
import CostTrend from "./CostTrend";
import { getTop10FailedModes, getTop5RegionData, getTop5ClaimedParts, getTop5ClaimedModel, getCityDetails, getTop5PartsDetailsForCity } from "./WarrantyServices";
import LoadingProgress from "pages/Repair/components/Loader";
import InputBoxSearchLoader from "pages/SolutionModules/use-case-4/useCase4Common/InputBoxSearchLoader";




const colors = ["#896dfe", "#6fa7ff", "#dd96ff", "#6fd4ff"];

const dataFor1 = [
    { name: "Rheem", value: 600000 },
    { name: "Solahart", value: 500000 },
    { name: "Paloma", value: 400000 },
    { name: "Vulcan", value: 300000 },
];
const dataFor2 = [
    { name: "Rheem", value: 3582 },
    { name: "Solahart", value: 2686 },
    { name: "Paloma", value: 1791 },
    { name: "Vulcan", value: 895 },
];

const dataForParts = [
    { "part_name": "Thermal Expansion Valve", "part_no": "N98000008", "claim_count": 2676, "perc": 29.73 },
    { "part_name": "Power Control Board", "part_no": "0R6478", "claim_count": 1876, "perc": 20.84 },
    { "part_name": "Thermistor", "part_no": "1757896", "claim_count": 939, "perc": 10.43 },
    { "part_name": "Fan Motor", "part_no": "N98001005", "claim_count": 246, "perc": 2.73 },
    { "part_name": "T3a Sensor", "part_no": "6I6371", "claim_count": 237, "perc": 2.63 },
    { "part_name": "Regeneration Valve", "part_no": "N88409026", "claim_count": 236, "perc": 2.62 },
    { "part_name": "Clam Cylinder", "part_no": "001706", "claim_count": 226, "perc": 2.51 },
    { "part_name": "Cab Riser Cylinder", "part_no": "N88409029", "claim_count": 225, "perc": 2.5 },
    { "part_name": "Heating Element", "part_no": "0S1619", "claim_count": 225, "perc": 2.5 },
    { "part_name": "Drive Shaft Hub", "part_no": "003397", "claim_count": 224, "perc": 2.49 },
    { "part_name": "Platform Cylinder", "part_no": "0R5217", "claim_count": 224, "perc": 2.49 },
    { "part_name": "Lower Heating Element", "part_no": "001708", "claim_count": 221, "perc": 2.46 },
    { "part_name": "Hyd Oil Temp Sensor", "part_no": "N88452100", "claim_count": 220, "perc": 2.44 },
    { "part_name": "Travel Sheave", "part_no": "N90058041", "claim_count": 217, "perc": 2.41 },
    { "part_name": "T4 Sensor", "part_no": "3681P053", "claim_count": 214, "perc": 2.38 },
    { "part_name": "Swing Pinion", "part_no": "N90198054", "claim_count": 209, "perc": 2.32 },
    { "part_name": "Control Board", "part_no": "2453804", "claim_count": 200, "perc": 2.22 },
    { "part_name": "Gas Control Valve", "part_no": "0R6333", "claim_count": 196, "perc": 2.18 },
    { "part_name": "Pilot Hydrolic Control", "part_no": "001707", "claim_count": 189, "perc": 2.1 }
]

const dataForModels = [
    { "model": "CB10", "claim_count": 350, "perc": 3.89 },
    { "model": "TL642E", "claim_count": 340, "perc": 3.78 },
    { "model": "994K", "claim_count": 323, "perc": 3.59 },
    { "model": "966K", "claim_count": 259, "perc": 2.88 },
    { "model": "CS64B", "claim_count": 257, "perc": 2.86 },
    { "model": "C32", "claim_count": 204, "perc": 2.27 },
    { "model": "C27", "claim_count": 177, "perc": 1.97 },
    { "model": "140 GC", "claim_count": 174, "perc": 1.93 },
    { "model": "3516C", "claim_count": 166, "perc": 1.84 },
    { "model": "C15", "claim_count": 156, "perc": 1.73 },
    { "model": "C12", "claim_count": 111, "perc": 1.23 },
    { "model": "794 AC", "claim_count": 109, "perc": 1.21 },
    { "model": "262C", "claim_count": 94, "perc": 1.04 }
]


const EachModelTop5 = [
    {
        "model": "140 GC",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 46, "perc": 26.44 },
            { "part_name": "Power Control Board", "total_claims": 44, "perc": 25.29 },
            { "part_name": "Thermistor", "total_claims": 22, "perc": 12.64 },
            { "part_name": "Cab Riser Cylinder", "total_claims": 8, "perc": 4.60 },
            { "part_name": "Hyd Oil Temp Sensor", "total_claims": 6, "perc": 3.45 }
        ]
    },
    {
        "model": "262C",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 27, "perc": 28.72 },
            { "part_name": "Power Control Board", "total_claims": 13, "perc": 13.83 },
            { "part_name": "Thermistor", "total_claims": 9, "perc": 9.57 },
            { "part_name": "Cab Riser Cylinder", "total_claims": 5, "perc": 5.32 },
            { "part_name": "Regeneration Valve", "total_claims": 5, "perc": 5.32 }
        ]
    },
    {
        "model": "3516C",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 48, "perc": 28.92 },
            { "part_name": "Power Control Board", "total_claims": 32, "perc": 19.28 },
            { "part_name": "Thermistor", "total_claims": 20, "perc": 12.05 },
            { "part_name": "Drive Shaft Hub", "total_claims": 8, "perc": 4.82 },
            { "part_name": "T4 Sensor", "total_claims": 7, "perc": 4.22 }
        ]
    },
    {
        "model": "794 AC",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 30, "perc": 27.52 },
            { "part_name": "Power Control Board", "total_claims": 22, "perc": 20.18 },
            { "part_name": "Thermistor", "total_claims": 13, "perc": 11.93 },
            { "part_name": "Cab Riser Cylinder", "total_claims": 5, "perc": 4.59 },
            { "part_name": "Drive Shaft Hub", "total_claims": 5, "perc": 4.59 }
        ]
    },
    {
        "model": "C12",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 44, "perc": 39.64 },
            { "part_name": "Power Control Board", "total_claims": 18, "perc": 16.22 },
            { "part_name": "Thermistor", "total_claims": 11, "perc": 9.91 },
            { "part_name": "Fan Motor", "total_claims": 6, "perc": 5.41 },
            { "part_name": "Lower Heating Element", "total_claims": 5, "perc": 4.50 }
        ]
    },
    {
        "model": "C15",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 45, "perc": 28.85 },
            { "part_name": "Power Control Board", "total_claims": 30, "perc": 19.23 },
            { "part_name": "Thermistor", "total_claims": 17, "perc": 10.90 },
            { "part_name": "Platform Cylinder", "total_claims": 8, "perc": 5.13 },
            { "part_name": "Fan Motor", "total_claims": 5, "perc": 3.21 }
        ]
    },
    {
        "model": "C27",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 55, "perc": 31.07 },
            { "part_name": "Power Control Board", "total_claims": 37, "perc": 20.90 },
            { "part_name": "Thermistor", "total_claims": 25, "perc": 14.12 },
            { "part_name": "Fan Motor", "total_claims": 7, "perc": 3.95 },
            { "part_name": "Regeneration Valve", "total_claims": 7, "perc": 3.95 }
        ]
    },
    {
        "model": "C32",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 54, "perc": 26.47 },
            { "part_name": "Power Control Board", "total_claims": 49, "perc": 24.02 },
            { "part_name": "Thermistor", "total_claims": 24, "perc": 11.76 },
            { "part_name": "Clam Cylinder", "total_claims": 9, "perc": 4.41 },
            { "part_name": "Fan Motor", "total_claims": 8, "perc": 3.92 }
        ]
    },
    {
        "model": "CB10",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 107, "perc": 30.57 },
            { "part_name": "Power Control Board", "total_claims": 71, "perc": 20.29 },
            { "part_name": "Thermistor", "total_claims": 28, "perc": 8.00 },
            { "part_name": "Lower Heating Element", "total_claims": 18, "perc": 5.14 },
            { "part_name": "Heating Element", "total_claims": 14, "perc": 4.00 }
        ]
    },
    {
        "model": "CS64B",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 87, "perc": 33.85 },
            { "part_name": "Power Control Board", "total_claims": 53, "perc": 20.62 },
            { "part_name": "Thermistor", "total_claims": 19, "perc": 7.39 },
            { "part_name": "Fan Motor", "total_claims": 13, "perc": 5.06 },
            { "part_name": "Cab Riser Cylinder", "total_claims": 10, "perc": 3.89 }
        ]
    },
    {
        "model": "966K",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 73, "perc": 28.19 },
            { "part_name": "Power Control Board", "total_claims": 62, "perc": 23.94 },
            { "part_name": "Thermistor", "total_claims": 24, "perc": 9.27 },
            { "part_name": "Clam Cylinder", "total_claims": 10, "perc": 3.86 },
            { "part_name": "Control Board", "total_claims": 8, "perc": 3.09 }
        ]
    },
    {
        "model": "994K",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 94, "perc": 29.10 },
            { "part_name": "Power Control Board", "total_claims": 76, "perc": 23.53 },
            { "part_name": "Thermistor", "total_claims": 32, "perc": 9.91 },
            { "part_name": "Cab Riser Cylinder", "total_claims": 12, "perc": 3.72 },
            { "part_name": "Lower Heating Element", "total_claims": 11, "perc": 3.41 }
        ]
    },
    {
        "model": "TL642E",
        "data": [
            { "part_name": "Thermal Expansion Valve", "total_claims": 101, "perc": 29.71 },
            { "part_name": "Power Control Board", "total_claims": 75, "perc": 22.06 },
            { "part_name": "Thermistor", "total_claims": 37, "perc": 10.88 },
            { "part_name": "Drive Shaft Hub", "total_claims": 11, "perc": 3.24 },
            { "part_name": "Fan Motor", "total_claims": 10, "perc": 2.94 }
        ]
    }
]


const dataForInsights = [
    {
        "ques": "Hot water runs out quickly",
        "ans": "Failed heating element. High voltage could be damaging the heating elements. Make sure that the heating element is bad before you replace it.",
        "recommended_parts": "Heating element, Thermistor, Control board"
    },
    {
        "ques": "Replace a water heater thermostat",
        "ans": "Shut off the electricity. Remove the heating element’s access cover. Remove the plastic shield. Remove the thermostat. Install the new thermostat.",
        "recommended_parts": "Thermistor, Control board"
    },
    {
        "ques": "Water heater's pressure relief valve dripping water",
        "ans": "Overheating tripped the valve. Shut off the circuit breakers, check the thermostats and heating elements. A failed thermostat can cause the element to heat constantly, resulting in overheating. Replace failed thermostats and shorted heating elements.",
        "recommended_parts": "Temperature-pressure (T&P) relief valve, Heating element"
    },
    {
        "ques": "Heating late",
        "ans": "If your electric water heater runs out of hot water quickly, or if the water isn’t as hot as it should be, the heating element could be the problem.",
        "recommended_parts": "Heating element, Control board"
    },
    {
        "ques": "Hot water smells like rotten eggs",
        "ans": "Certain water conditions cause a reaction between a water heater's anode rod and supply water, causing hydrogen sulfide gas to dissolve in the tank water. Installing a less-reactive aluminum alloy anode rod and chlorinating the water supply system eliminates the rotten-egg smell in hot water.",
        "recommended_parts": "Less-reactive zinc-aluminum anode rod"
    },
    {
        "ques": "Making the water hot enough",
        "ans": "Carefully adjust the water heater's thermostat setting if it’s set below 120 degrees Fahrenheit. Adjust the temperature setting in small increments to avoid producing scalding hot water.",
        "recommended_parts": "Heating element, Thermostat"
    },
    {
        "ques": "Water in drain pan",
        "ans": "Check for leaks at the water pipe fittings. Tighten the water pipe connections if necessary. Check the drain valve and temperature-pressure relief valve for leaks. Replace the drain valve or relief valve if they leak.",
        "recommended_parts": "Drain valve, Temperature-pressure relief valve"
    },
    {
        "ques": "Water heater is not heating water",
        "ans": "Check both house circuit breakers. Reset the circuit breakers by shutting them off and then flipping them back on.",
        "recommended_parts": "Heating element, Thermostat"
    },
    {
        "ques": "Heating element fails often",
        "ans": "There may be too much sediment inside the tank. Disconnect power and shut off the water supply, and then drain the tank to flush out sediment. Refill the tank before restoring electrical power to avoid damaging the heating element.",
        "recommended_parts": "Heating element, Control board"
    },
    {
        "ques": "Temperature fluctuates",
        "ans": "The upper and lower thermostats must fit snug against the side of the tank to sense and regulate the temperature correctly. Turn off the power to the water heater and remove the access covers to check the thermostats’ positions. Adjust the thermostat brackets if necessary to keep the thermostats snug against the tank.",
        "recommended_parts": "Thermostat, Sensor"
    }
]

const dataTopCity = [
    {
        "city": "Melbourne",
        "claim_count": 2699,
        "perc": "29.99"
    },
    {
        "city": "Perth",
        "claim_count": 1759,
        "perc": "19.54"
    },
    {
        "city": "Brisbane",
        "claim_count": 1686,
        "perc": "18.73"
    },
    {
        "city": "Sydney",
        "claim_count": 914,
        "perc": "10.16"
    },
    {
        "city": "Darwin",
        "claim_count": 675,
        "perc": "7.50"
    }
]

const timePeriodOptions = [
    "Last 2 Years",
    "1 Year",
    "6 Months",
    "1 Month",
    "1 Week",
];

const RegionOptions = [
    "Melbourne",
    "Perth",
    "Brisbane",
    "Sydney",
    "Darwin",
    "Gold Coast",
    "Canberra",
    "Hobart"
]

const modelOptions = [
    "Rheem",
    "Solahart",
    "Paloma",
    "Vulcan"
]

const productFamilyOptions = [
    "Water Heater",
    "Gas Heater",
    "Solar Panel",
    "Inverter"
]

const partGroupsOptions = [
    "Thermostat",
    "Heating element",
    "Sensors",
    "PV Panels"
]

const failureOptions = [
    "Shut down",
    "Not operating correctly",
    "Flame out",
    "Ignition failure",
    "Compressor failure",
    "Lock-out condition",
    "Evaporator frosted over",
    "Air pressure switch closed",
    "Discharge temperature above the limit",
    "Air pressure switch open",
]

const PartsFailureLines = [
    { partName: "Valve", percentage: 0.97, },
    { partName: "Anode rod", percentage: 0.63, },
    { partName: "Inlet", percentage: 0.19, },
    { partName: "Outlet", percentage: 0.15, },
    { partName: "Expansion Tank", percentage: 0.05, },
];
const RegionLines = [
    { cityName: "Brisbane", percentage: 0.63, },
    { cityName: "Sydney", percentage: 0.42, },
    { cityName: "Perth", percentage: 0.31, },
    { cityName: "Melbourne", percentage: 0.19, },
    { cityName: "Canberra", percentage: 0.12, },
];

const FailureModeLines = [
    { modeName: "Shut down", percentage: 0.49, },
    { modeName: "Contamination", percentage: 0.47, },
    { modeName: "Water leak", percentage: 0.22, },
    { modeName: "Flame out", percentage: 0.11, },
    { modeName: "Ignition failure", percentage: 0.11, },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        // background: `#ff5a6f`,
        background: `linear-gradient(90deg, #ff5a6f 40%, #ff5a6f 100%)`,
        // background: `linear-gradient(90deg, #6fa7ff 40%, #d06fff 100%)`,
    },
}));

const BusinessLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        background: `#75ccdf`,
        // background: `linear-gradient(90deg, #6fa7ff 40%, #d06fff 100%)`,
    },
}));

const CarrierLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        background: `#2596be`,
        // background: `linear-gradient(90deg, #6fa7ff 40%, #d06fff 100%)`,
    },
}));

const WarrantyAnalytics = () => {
    const [timePeriod, setTimePeriod] = useState("");
    const [regions, setRegions] = useState("");
    const [models, setModels] = useState("");
    const [productFamily, setProductFamily] = useState("");
    const [partGroups, setPartGroups] = useState("");
    const [failureTypes, setFailureTypes] = useState("");
    // Snack Bar State
    const [severity, setSeverity] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const handleSnack = (snackSeverity, snackMessage) => {
        setSnackMessage(snackMessage);
        setSeverity(snackSeverity);
        setOpenSnack(true);
    };

    const [isLoading, setIsLoading] = useState(false);

    const [dataForTop10FailedModes, setDataForTop10FailedModes] = useState(null);
    const [dataForTop5Regions, setDataForTop5Regions] = useState(null);
    const [dataForTop5Parts, setDataForTop5Parts] = useState(null);
    const [dataForTop5Models, setDataForTop5Models] = useState(null);

    const renderLabel = (entry) => `${entry.value}`;

    const [activeTab, setActiveTab] = useState('general');


    const handleTop10FailureModeData = () => {
        setIsLoading(true);
        getTop10FailedModes()
            .then((res) => {
                setDataForTop10FailedModes(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop10FailedModes([]);
                setIsLoading(false);
            });
    }
    const handleTop5RegionData = () => {
        setIsLoading(true);
        getTop5RegionData()
            .then((res) => {
                setDataForTop5Regions(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop5Regions([]);
                setIsLoading(false);
            });
    }
    const handleTop5Parts = () => {
        setIsLoading(true);
        getTop5ClaimedParts()
            .then((res) => {
                setDataForTop5Parts(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop5Parts([]);
                setIsLoading(false);
            });
    }
    const handleTop5Models = () => {
        setIsLoading(true);
        getTop5ClaimedModel()
            .then((res) => {
                setDataForTop5Models(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop5Models([]);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        handleTop10FailureModeData();
        handleTop5RegionData();
        handleTop5Parts();
        handleTop5Models();
    }, [])


    const [inputFailedPartSearch, setInputFailedPartSearch] = useState("");
    const [selctedOptionsForPart, setSelctedOptionsForPart] = useState("");
    const [searchedDropDownParts, setSearchedDropDownParts] = useState(null);
    const [searchedParts, setsearchedParts] = useState("");
    const [searchedPartsGraph, setSearchedPartsGraph] = useState("");

    const [inputRegion, setInputRegion] = useState("");
    const [searchedRegion, setsearchedRegion] = useState("");
    const [searchedDropDownRegion, setSearchedDropDownRegion] = useState(null);
    const [searchedDropDownRegionLoading, setSearchedDropDownRegionLoading] = useState(false);
    const [searchedRegionLoading, setSearchedRegionLoading] = useState(false);
    const [searchedRegionGraphData, setsearchedRegionGraphData] = useState("");

    const [inputInsightSearch, setInputInsightSearch] = useState("");
    const [searchedDropdownInsight, setSearchedDropdownInsight] = useState("");
    const [searchedInsight, setSearchedInsight] = useState("");

    //Parts Functions

    const handleSelectedOptionForPart = (selectedOption) => {
        setSelctedOptionsForPart(selectedOption);
        setInputFailedPartSearch("");
        setsearchedParts("");
    }

    const handleSelectDropdownItemForParts = (currentItem) => {
        if (selctedOptionsForPart.value === 'partNumber')
            setInputFailedPartSearch(currentItem['part_no']);
        else
            setInputFailedPartSearch(currentItem['model']);
        $(`.scrollbar-${0}`).css("display", "none");
    }

    const handleDropDownSearchForParts = (e) => {
        const inputValue = e.target.value;
        setInputFailedPartSearch(inputValue);
        if (selctedOptionsForPart.value === 'partNumber') {
            const searchedPartsList = dataForParts.filter(parts =>
                parts.part_no.toLowerCase().includes(inputValue.toLowerCase())
            );
            setSearchedDropDownParts(searchedPartsList);
        }
        else {
            const searchedModelList = dataForModels.filter(models =>
                models.model.toLowerCase().includes(inputValue.toLowerCase())
            );
            setSearchedDropDownParts(searchedModelList);
        }
        $(`.scrollbar-${0}`).css("display", "block");
    }


    //Region Functions

    const handleSelectDropdownItemForRegion = (currentItem) => {
        setInputRegion(currentItem.city);
        $(`.scrollbar-${0}`).css("display", "none");
    }

    const handleDropDownSearchForRegion = (e) => {
        setSearchedDropDownRegionLoading(true);
        const inputValue = e.target.value;
        setInputRegion(inputValue);

        getCityDetails(inputValue)
            .then((res) => {
                setSearchedDropDownRegion(res);
                setSearchedDropDownRegionLoading(false);
                $(`.scrollbar-${0}`).css("display", "block");
            })
            .catch((err) => {
                setSearchedDropDownRegion([]);
                setSearchedDropDownRegionLoading(false);
                $(`.scrollbar-${0}`).css("display", "block");
            });
    }

    const handleSearchForRegion = () => {
        if (inputRegion) {
            setSearchedRegionLoading(true);
            const query = inputRegion;
            getCityDetails(query)
                .then((res) => {
                    setsearchedRegion(res[0]);
                })
                .catch((err) => {
                    setsearchedRegion([]);
                });
            getTop5PartsDetailsForCity(query)
                .then((res) => {
                    setsearchedRegionGraphData(res);
                })
                .catch((err) => {
                    setSearchedDropDownRegion([]);
                })
                .finally(() => {
                    setSearchedRegionLoading(false);
                })
        }
        else {
            setsearchedRegion(null);
            setsearchedRegionGraphData([]);
        }
    }

    const handleSearchForFailedPart = () => {
        if (inputFailedPartSearch) {
            const query = inputFailedPartSearch.toLowerCase();
            if (selctedOptionsForPart.value === 'partNumber') {
                let filtered = dataForParts.filter(parts =>
                    parts.part_no.toLowerCase().includes(query.toLowerCase())
                );
                setsearchedParts(filtered[0]);
            }
            else {
                let filtered = dataForModels.filter(models =>
                    models.model.toLowerCase().includes(query.toLowerCase())
                );
                setsearchedParts(filtered[0]);
                filtered = EachModelTop5.filter(models =>
                    models.model.toLowerCase().includes(query.toLowerCase())
                )
                setSearchedPartsGraph(filtered[0].data);
            }
        }
        else {
            setsearchedParts(null);
        }
    }

    //insght functions

    const handleDropDownSearchForInsights = (e) => {
        const inputValue = e.target.value;
        setInputInsightSearch(inputValue);

        const searchedInsghtList = dataForInsights.filter(insight =>
            insight.ques.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSearchedDropdownInsight(searchedInsghtList);
        $(`.scrollbar-${0}`).css("display", "block");
    }


    const handleSelectDropdownItemForInsight = (currentItem) => {
        setInputInsightSearch(currentItem.ques);
        $(`.scrollbar-${0}`).css("display", "none");
    }

    const handleSearchForInsights = () => {
        if (inputInsightSearch) {
            const query = inputInsightSearch.toLowerCase();
            let filtered = dataForInsights.filter(insight =>
                insight.ques.toLowerCase().includes(query)
            );
            setSearchedInsight(filtered);
        }
        else {
            setSearchedInsight(null);
        }
    }


    const viewGeneralTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">
                        General
                    </h3>
                </div>
                <div style={{ minHeight: "884px" }}>
                    <div className="container-fluid">
                        <Box
                            sx={{
                                mx: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}

                        >
                            <SelectBox
                                label={"Time Period"}
                                value={timePeriod}
                                options={timePeriodOptions}
                                handleChange={(e) => setTimePeriod(e.target.value)}
                                handleUnselect={() => setTimePeriod("")}
                                size={150}
                            />

                            <SelectBox
                                label={"All Models"}
                                value={models}
                                options={modelOptions}
                                handleChange={(e) => setModels(e.target.value)}
                                handleUnselect={() => setModels("")}
                                size={150}
                            />

                            <SelectBox
                                label={"Failure Classifications"}
                                value={failureTypes}
                                options={failureOptions}
                                handleChange={(e) => setFailureTypes(e.target.value)}
                                handleUnselect={() => setFailureTypes("")}
                                size={200}
                            />
                            <SelectBox
                                label={"Product Family"}
                                value={productFamily}
                                options={productFamilyOptions}
                                handleChange={(e) => setProductFamily(e.target.value)}
                                handleUnselect={() => setProductFamily("")}
                                size={150}
                            />
                            <SelectBox
                                label={"Part Group"}
                                value={partGroups}
                                options={partGroupsOptions}
                                handleChange={(e) => setPartGroups(e.target.value)}
                                handleUnselect={() => setPartGroups("")}
                                size={150}
                            />
                            <SelectBox
                                label={"Region"}
                                value={regions}
                                options={RegionOptions}
                                handleChange={(e) => setRegions(e.target.value)}
                                handleUnselect={() => setRegions("")}
                                size={150}
                            />
                        </Box>

                        <Grid
                            container
                            spacing={3}
                            sx={{
                                width: "100%",
                                borderRadius: 5,
                                marginBlock: 0,
                            }}
                        >
                            <Grid item lg={3} xs={12}>
                                <div
                                    className="card border"
                                    style={{ background: "#efefef" }}  //#1F0D35 #543A8F
                                >
                                    <div className="ml-3 my-2 font-weight-600 font-size-14 text-uppercase text-muted">Total Claims Paid (CP)</div>
                                    <div className="ml-3 mb-2  font-weight-700 font-size-14" >$1,800,000</div>
                                    <Divider style={{ background: '#CCC', width: '90%', margin: 'auto' }} />
                                    <div className="ml-3 m-3 text-secondary font-weight-500 font-size-13" >CP COVERAGE BY BRANDS</div>
                                    <PieChart width={400} height={268}>
                                        <Pie
                                            data={dataFor1}
                                            cx={140}
                                            cy={100}
                                            innerRadius={55}
                                            outerRadius={75}
                                            labelLine={false}
                                            label={renderLabel}
                                            paddingAngle={0}
                                            fill="#8884d8"
                                            dataKey="value"
                                        // cornerRadius={10} 
                                        >
                                            {dataFor1.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend
                                            align="left"
                                            wrapperStyle={{
                                                transform: 'translate(0, -50%)',
                                                padding: '10px',
                                                borderRadius: '10px'
                                            }} />
                                    </PieChart>
                                </div>
                                <div
                                    className="card border my-2"
                                    style={{ background: "#efefef" }}
                                >
                                    <div className="ml-3 my-2 font-weight-600 font-size-14 text-uppercase text-muted">Total Warranty Claims</div>
                                    <div className="ml-3 mb-2 font-weight-700 font-size-14" >8954</div>
                                    <Divider style={{ background: '#CCC', width: '90%', margin: 'auto' }} />
                                    <div className="ml-3 m-3 text-secondary font-weight-500 font-size-13 text-uppercase" >Total Claims by Brand</div>
                                    <PieChart width={400} height={275}>
                                        <Pie
                                            data={dataFor2}
                                            cx={140}
                                            cy={100}
                                            innerRadius={55}
                                            outerRadius={75}
                                            fill="#8884d8"
                                            labelLine={false}
                                            paddingAngle={0}
                                            dataKey="value"
                                            label={renderLabel}
                                        >
                                            {dataFor2.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={
                                                        colors[
                                                        index % colors.length
                                                        ]
                                                    }
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend
                                            align="left"
                                            wrapperStyle={{
                                                transform: 'translate(0, -50%)',
                                                padding: '10px',
                                                borderRadius: '10px'
                                            }} />
                                    </PieChart>
                                </div>
                            </Grid>
                            <Grid item lg={5} xs={12}>
                                <div className="card border" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                    <div className="my-2">
                                        <CostTrend />
                                    </div>
                                </div>
                                <div className="card border" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                    <div className="my-2">
                                        <ClaimsTrend />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <div className="card border px-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                    <div className="my-2 text-muted text-uppercase"><span className="font-weight-600 font-size-12">Total warranty Costs Paid </span><span className="font-weight-600" style={{ fontSize: '10' }}> (in millions AUD)</span></div>
                                    <div className=" font-weight-700 font-size-14"> Part Causing Failures</div>
                                    {PartsFailureLines.length !== 0 &&
                                        PartsFailureLines.map((parts, i) => (
                                            <div
                                                className="row my-2"
                                                key={`${parts.partName}-${i}`}
                                            >
                                                <div className="col-md-4">
                                                    <span
                                                        className="text-muted font-size-12 font-weight-500"
                                                    >
                                                        {parts.partName}
                                                    </span>
                                                </div>
                                                <div className="col-md-8">
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <ProgressBar
                                                                variant="primary"
                                                                now={
                                                                    parts.percentage *
                                                                    100
                                                                }
                                                                style={{
                                                                    height: 10,
                                                                    borderRadius: 5,
                                                                }}
                                                            />

                                                        </Box>
                                                        <span className="mx-2">
                                                            {parts.percentage}m
                                                        </span>
                                                    </Box>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="text-light font-size-12 font-weight-700 mb-1">{"Show All >"}</a>
                                    </div>
                                </div>
                                <div className="card border px-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                    <div className="my-2 text-muted text-uppercase"><span className="font-weight-600 font-size-12">Total warranty Costs Paid </span><span className="font-weight-600" style={{ fontSize: '10' }}> (in millions AUD)</span></div>
                                    <div className="font-weight-700 font-size-14">Region</div>
                                    {RegionLines.length !== 0 &&
                                        RegionLines.map((region, i) => (
                                            <div
                                                className="row my-2"
                                                key={`${region.cityName}-${i}`}
                                            >
                                                <div className="col-md-4">
                                                    <span
                                                        className="text-muted font-size-12 font-weight-500"
                                                    >
                                                        {region.cityName}
                                                    </span>
                                                </div>
                                                <div className="col-md-8">
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <ProgressBar
                                                                variant="primary"
                                                                now={
                                                                    region.percentage *
                                                                    100
                                                                }
                                                                style={{
                                                                    height: 10,
                                                                    borderRadius: 5,
                                                                    // backgroundColor:
                                                                    //     "#75ccdf",
                                                                }}
                                                            />

                                                        </Box>
                                                        <span className="mx-2">
                                                            {region.percentage}m
                                                        </span>
                                                    </Box>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="text-light font-size-12 font-weight-700 mb-1">{"Show All >"}</a>
                                    </div>
                                </div>
                                <div className="card border px-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                    <div className="my-2 text-muted text-uppercase"><span className="font-weight-600 font-size-12">Total warranty Costs Paid </span><span className="font-weight-600" style={{ fontSize: '10' }}> (in millions AUD)</span></div>
                                    <div className="font-weight-700 font-size-14">Failure Mode</div>
                                    {FailureModeLines.length !== 0 &&
                                        FailureModeLines.map((modes, i) => (
                                            <div
                                                className="row my-2"
                                                key={`${modes.modeName}-${i}`}
                                            >
                                                <div className="col-md-4">
                                                    <span
                                                        className="text-muted font-size-12 font-weight-500"
                                                    >
                                                        {modes.modeName}
                                                    </span>
                                                </div>
                                                <div className="col-md-8">
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <ProgressBar
                                                                variant="primary"
                                                                now={
                                                                    modes.percentage *
                                                                    100
                                                                }
                                                                style={{
                                                                    height: 10,
                                                                    borderRadius: 5,
                                                                }}
                                                            />

                                                        </Box>
                                                        <span className="mx-2">
                                                            {modes.percentage}m
                                                        </span>
                                                    </Box>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="text-light font-size-12 font-weight-700 mb-1">{"Show All >"}</a>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
    const viewFailureModesTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">
                        Failure Modes
                    </h3>
                </div>
                <div className="d-flex justify-content-center my-4">
                    <h5 className="font-weight-600 mt-3 mb-0 ">
                        Top 10 Failure Modes
                    </h5>
                </div>
                {isLoading ? <LoadingProgress /> : <div className="d-flex justify-content-center align-items-center  mb-3">
                    <Card sx={{ borderRadius: 4, backgroundColor: 'rgba(255,255,255,0.3)', margin: 5, padding: 4 }} variant="outlined">
                        <BarChart
                            layout="vertical"
                            width={900}
                            height={550}
                            data={dataForTop10FailedModes}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 50,
                                bottom: 50, // Increased bottom margin to make X-axis label visible
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis
                                type="category"
                                dataKey="failure_mode"
                                width={150} // Increased width to accommodate the Y-axis label
                                tick={{ fontSize: 13, wordWrap: 'break-word', whiteSpace: 'normal' }}
                                interval={0}
                            >
                                <Label value="Modes of Failure" position="insideLeft" angle={-90} offset={-25} fill="#333" className="font-size-16 font-weight-600" style={{ textAnchor: 'middle' }} />
                            </YAxis>
                            <XAxis
                                type="number"
                                domain={[0, 60]}
                                tickCount={5}
                                tickFormatter={(value) => `${value}%`}
                            >
                                <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                            </XAxis>
                            <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, 'Count']} labelStyle={{ display: 'none' }} />
                            <Bar dataKey="percentage" fill="#6454EA" barSize={20}>
                                <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                            </Bar>
                        </BarChart>
                    </Card>
                </div>}
            </>
        )
    }
    const viewRegionTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">
                        Region or Partners
                    </h3>
                    {searchedRegion && <div className="btn btn-primary" onClick={() => { setsearchedRegion(""); setInputRegion(""); }}>Back</div>}
                </div>
                <div className="d-flex justify-content-center align-items-center  mr-4">
                    <div className=" align-items-center m-0">
                        <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{ position: "relative" }}>
                            <div className="customselectsearch customize">
                                <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder="Search by Region or Partners"
                                    value={inputRegion}
                                    autoComplete="off"
                                    style={{ "width": "300px" }}
                                    onChange={handleDropDownSearchForRegion}
                                />
                                <div
                                    className="btn bg-primary text-white cursor"
                                    onClick={handleSearchForRegion}
                                >
                                    <span className="mr-2">
                                        <SearchIcon />
                                    </span>
                                    SEARCH
                                </div>
                                {
                                    searchedDropDownRegionLoading ? <InputBoxSearchLoader /> :
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                                            id="style"
                                        >
                                            {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 &&
                                                (
                                                    <li className="list-group-item">
                                                        No
                                                        Result
                                                        found
                                                    </li>
                                                )}
                                            {inputRegion.length !== 0 && searchedDropDownRegion.length !== 0 && searchedDropDownRegion.map((currentItem, j) =>
                                            (
                                                <li
                                                    className="list-group-item"
                                                    key={j}
                                                    onClick={() =>
                                                        handleSelectDropdownItemForRegion(
                                                            currentItem
                                                        )
                                                    }
                                                >
                                                    {
                                                        currentItem["city"]
                                                    }
                                                </li>
                                            )
                                            )}
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {!searchedRegion ?
                    <>
                        <div className="d-flex justify-content-center my-4">
                            <h5 className="font-weight-600 mt-3 mb-0 ">
                                Top 5 Regions
                            </h5>
                        </div>
                        {isLoading ? <LoadingProgress /> : <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
                            <Card sx={{ borderRadius: 4, backgroundColor: 'rgba(255,255,255,0.3)',padding:4 }} variant="outlined">
                                <BarChart
                                    layout="vertical"
                                    width={800}
                                    height={450}
                                    data={dataForTop5Regions}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 30, // Increased left margin to accommodate the Y-axis label
                                        bottom: 20,
                                    }}
                                >
                                    <YAxis
                                        type="category"
                                        dataKey="city"
                                        width={100} // Increased width to accommodate the Y-axis label
                                    >
                                        <Label value="Region" position="insideLeft" angle={-90} offset={-10} fill="#333" className="font-size-16 font-weight-600" />
                                    </YAxis>
                                    <XAxis
                                        type="number"
                                        domain={[0, 60]}
                                        tickCount={5}
                                        tickFormatter={(value) => `${value}%`}
                                    >
                                        <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                    </XAxis>
                                    <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, 'Count']} labelStyle={{ display: 'none' }} />
                                    <Bar dataKey="percentage" fill="#6454EA" barSize={20} radius={[0, 5, 5, 0]}>
                                        <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                    </Bar>
                                </BarChart>
                            </Card>
                        </div>}
                    </> :
                    <>{searchedRegionLoading ? <LoadingProgress /> :
                        <div className="container my-4">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: 'rgba(255,255,255,0.3)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} variant="outlined">
                                        <div className="text-light font-size-18 font-weight-600 my-2">{searchedRegion.city}</div>
                                        <div className="d-flex justify-content-center">
                                            <ProgressPie percentage={parseFloat(searchedRegion.percentage)} />
                                        </div>
                                        <div className="d-flex justify-content-center font-size-18 text-light font-weight-600">Claim Counts : {searchedRegion.claim_count}</div>
                                    </Card>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: 'rgba(255,255,255,0.3)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} variant="outlined">
                                        <div className="text-light font-size-18 font-weight-600 my-2 ">{searchedRegion.city}</div>
                                        <div className="d-flex justify-content-center align-items-center" >
                                            <BarChart
                                                layout="vertical"
                                                width={480}
                                                height={275}
                                                data={searchedRegionGraphData}
                                                margin={{
                                                    top: 20,
                                                    right: 0,
                                                    left: -50,
                                                    bottom: 10,
                                                }}
                                            >
                                                <YAxis
                                                    type="category"
                                                    dataKey="part_name"
                                                    width={200}
                                                    tick={{
                                                        fontSize: 12,
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal',
                                                        lineHeight: '15px'
                                                    }}
                                                    interval={0}
                                                    axisLine={false}
                                                />
                                                <XAxis
                                                    type="number"
                                                    domain={[0, 50]}
                                                    tickCount={3}
                                                    tickFormatter={(value) => `${value}%`}
                                                    width={300}
                                                />
                                                <Tooltip
                                                    formatter={(value, name, props) => [`${props.payload.total_claims_in_city}`, 'Count']}
                                                    labelStyle={{ display: 'none' }}
                                                />
                                                <Bar dataKey="claim_perc_in_city" fill="#6454EA" barSize={20}>
                                                    <LabelList dataKey="claim_perc_in_city" position="right" formatter={(value) => `${value}%`} />
                                                </Bar>
                                            </BarChart>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>}
                    </>

                }
            </>
        )
    }




    const viewFailedPartsTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">
                        Failed Parts & Models
                    </h3>
                    {searchedParts && <div className="btn btn-primary" onClick={() => { setsearchedParts(""); setInputFailedPartSearch(""); setSelctedOptionsForPart(""); }}>Back</div>}
                </div>

                <div className="d-flex justify-content-center align-items-center  mr-4">
                    <div className="row align-items-center m-0">
                        <div className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}

                            style={{ position: "relative", }}>
                            <div>
                                <Select
                                    options={
                                        [
                                            { label: "Part No", value: "partNumber" },
                                            { label: "Model No", value: "modelNumber" }
                                        ]
                                    }
                                    onChange={handleSelectedOptionForPart}
                                    value={selctedOptionsForPart}
                                    placeholder="Search By"
                                />
                            </div>
                            <div className="customselectsearch customize">
                                <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder={`Search ${selctedOptionsForPart.label}`}
                                    value={inputFailedPartSearch}
                                    autoComplete="off"
                                    style={{ "width": "250px" }}
                                    onChange={handleDropDownSearchForParts}
                                    disabled={selctedOptionsForPart === ""}
                                />
                                <div
                                    className="btn btn-md bg-primary text-white cursor"
                                    onClick={handleSearchForFailedPart}
                                >
                                    <span className="mr-2">
                                        <SearchIcon />
                                    </span>
                                    SEARCH
                                </div>
                                {
                                    <ul
                                        className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                                        id="style"
                                    >
                                        {inputFailedPartSearch.length !== 0 && searchedDropDownParts.length === 0 &&
                                            (
                                                <li className="list-group-item">
                                                    No
                                                    Result
                                                    found
                                                </li>
                                            )}
                                        {inputFailedPartSearch.length !== 0 && searchedDropDownParts.length !== 0 && searchedDropDownParts.map((currentItem, j) =>
                                        (
                                            <li
                                                className="list-group-item"
                                                key={j}
                                                onClick={() => handleSelectDropdownItemForParts(currentItem)}
                                            >
                                                {selctedOptionsForPart.value === 'partNumber' ? currentItem["part_no"] : currentItem['model']}
                                            </li>
                                        )
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {!searchedParts ? (isLoading ? <LoadingProgress /> : <div className="my-4">
                    <div className="row">
                        <div className="col-md-6">
                            <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: 'rgba(255,255,255,0.3)' }} variant="outlined">
                                <div className="d-flex justify-content-center my-4">
                                    <h5 className="font-weight-600 mt-3 mb-0 ">
                                        Top 5 Failed Parts
                                    </h5>
                                </div>
                                <div className="d-flex justify-content-center align-items-center  mb-3">
                                    <BarChart
                                        layout="vertical"
                                        width={500}
                                        height={350}
                                        data={dataForTop5Parts}
                                        margin={{
                                            right: 30,
                                            left: 25, // Increased left margin to accommodate the Y-axis label
                                            bottom: 20,
                                        }}
                                    >
                                        <YAxis
                                            type="category"
                                            dataKey="part_name"
                                            width={100} // Increased width to accommodate the Y-axis label
                                            tick={{ fontSize: 13, wordWrap: 'break-word', whiteSpace: 'normal' }}
                                            interval={0}
                                        >
                                            <Label value="Part Names" position="insideLeft" angle={-90} offset={-20} fill="#333" className="font-size-16 font-weight-600" textAnchor="center" />
                                        </YAxis>
                                        <XAxis
                                            type="number"
                                            domain={[0, 60]}
                                            tickCount={5}
                                            tickFormatter={(value) => `${value}%`}
                                        >
                                            <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                        </XAxis>
                                        <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, 'Count']} labelStyle={{ display: 'none' }} />
                                        <Bar dataKey="percentage" fill="#6454EA" barSize={20} radius={[0, 5, 5, 0]}>
                                            <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                        </Bar>
                                    </BarChart>
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-6">
                            <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: 'rgba(255,255,255,0.3)' }} variant="outlined">
                                <div className="d-flex justify-content-center my-4">
                                    <h5 className="font-weight-600 mt-3 mb-0 ">
                                        Top 5 Failed Models
                                    </h5>
                                </div>
                                <div className="d-flex justify-content-center align-items-center  mb-3">
                                    <BarChart
                                        layout="vertical"
                                        width={500}
                                        height={350}
                                        data={dataForTop5Models}
                                        margin={{
                                            right: 30,
                                            left: 25, // Increased left margin to accommodate the Y-axis label
                                            bottom: 20,
                                        }}
                                    >
                                        <YAxis
                                            type="category"
                                            dataKey="model"
                                            width={100} // Increased width to accommodate the Y-axis label
                                            tick={{ fontSize: 13, wordWrap: 'break-word', whiteSpace: 'normal' }}
                                            interval={0}
                                        >
                                            <Label value="Model" position="insideLeft" angle={-90} offset={-20} fill="#333" className="font-size-16 font-weight-600" />
                                        </YAxis>
                                        <XAxis
                                            type="number"
                                            domain={[0, 30]}
                                            tickCount={4}
                                            tickFormatter={(value) => `${value}%`}
                                        >
                                            <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                        </XAxis>
                                        <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, 'Count']} labelStyle={{ display: 'none' }} />
                                        <Bar dataKey="percentage" fill="#6454EA" barSize={20} radius={[0, 5, 5, 0]}>
                                            <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                        </Bar>
                                    </BarChart>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>)
                    : <>
                        <div className="container my-5">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: 'rgba(255,255,255,0.3)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} style={{ width: 525, height: 328 }} variant="outlined">
                                        <div className="text-light font-size-18 font-weight-600 mt-3 mb-2">{selctedOptionsForPart.value === 'partNumber' ? 'Mostly Failed At' : 'Most Failed Parts'}</div>
                                        <div className="d-flex justify-content-center align-items-center" >
                                            <BarChart
                                                layout="vertical"
                                                width={480}
                                                height={275}
                                                data={selctedOptionsForPart.value === 'partNumber' ? dataTopCity : searchedPartsGraph}
                                                margin={{
                                                    top: 20,
                                                    right: 0,
                                                    left: -70,
                                                    bottom: 10,
                                                }}
                                            >
                                                <YAxis
                                                    type="category"
                                                    dataKey={selctedOptionsForPart.value === 'partNumber' ? 'city' : 'part_name'}
                                                    width={220}
                                                    tick={{
                                                        fontSize: 12,
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'normal',
                                                        lineHeight: '15px'
                                                    }}
                                                    interval={0}
                                                    axisLine={false}
                                                />
                                                <XAxis
                                                    type="number"
                                                    domain={[0, 50]}
                                                    tickCount={3}
                                                    tickFormatter={(value) => `${value}%`}
                                                    width={300}
                                                />
                                                <Tooltip
                                                    formatter={(value, name, props) => [`${selctedOptionsForPart.value === 'partNumber' ? props.payload.claim_count : props.payload.total_claims}`, 'Count']}
                                                    labelStyle={{ display: 'none' }}
                                                />
                                                <Bar dataKey="perc" fill="#6454EA" barSize={20}>
                                                    <LabelList dataKey="perc" position="right" formatter={(value) => `${value}%`} />
                                                </Bar>
                                            </BarChart>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Card sx={{ borderRadius: 4, backgroundColor: 'rgba(255,255,255,0.3)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} style={{ width: 525, height: 328 }} variant="outlined">
                                        <div className="d-flex align-items-center mt-3">
                                            <span className="badge bg-danger rounded-circle" style={{ width: '10px', height: '10px' }}></span>
                                            <span className="ml-2 font-weight-500 font-size-18">Total Warranty Claimed</span>
                                            <span className="ml-auto mr-2 font-size-18 font-weight-500">
                                                {selctedOptionsForPart.value === "partNumber" ? `Part No : ${searchedParts.part_no}` : `Model: ${searchedParts.model}`}
                                            </span>
                                        </div>
                                        <CardContent className="d-flex flex-column justify-content-center h-100">
                                            <Typography variant="h1" component="div" className="display-4 font-weight-bold text-center text-light">
                                                {searchedParts.claim_count}
                                            </Typography>
                                            <div className="d-flex justify-content-center align-items-center my-3">
                                                <span className="mr-2 font-size-16 font-weight-500">Percentage:</span>
                                                <span className="font-size-16 font-weight-500" style={{ color: "#6C70FE" }}>{searchedParts.perc}%</span>
                                            </div>
                                            {selctedOptionsForPart.value === "partNumber" && <div className="text-center font-size-16 font-weight-500">PartName:  <span className="text-light">{searchedParts.part_name}</span></div>}
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </>}
            </>
        )
    }

    const viewActionableInsightsTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">
                        Actionable Insights
                    </h3>
                    {searchedInsight && <div className="btn btn-primary" onClick={() => { setSearchedInsight(""); setInputInsightSearch(""); }}>Back</div>}
                </div>
                <div className="d-flex justify-content-center align-items-center mr-4">
                    <div className="row align-items-center m-0">
                        <div className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}

                            style={{ position: "relative", }}>
                            <div className="customselectsearch customize">
                                <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder="Search by Symptoms"
                                    value={inputInsightSearch}
                                    autoComplete="off"
                                    style={{ "width": "300px" }}
                                    onChange={handleDropDownSearchForInsights}
                                />
                                <div
                                    className="btn btn-md bg-primary text-white cursor"
                                    onClick={handleSearchForInsights}
                                >
                                    <span className="mr-2">
                                        <SearchIcon />
                                    </span>
                                    SEARCH
                                </div>
                                {
                                    <ul
                                        className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                                        id="style"
                                    >
                                        {inputInsightSearch.length !== 0 && searchedDropdownInsight.length === 0 &&
                                            (
                                                <li className="list-group-item">
                                                    No
                                                    Result
                                                    found
                                                </li>
                                            )}
                                        {inputInsightSearch.length !== 0 && searchedDropdownInsight.length !== 0 && searchedDropdownInsight.map((currentItem, j) =>
                                        (
                                            <li
                                                className="list-group-item"
                                                key={j}
                                                onClick={() => handleSelectDropdownItemForInsight(currentItem)}
                                            >
                                                {
                                                    currentItem["ques"]
                                                }
                                            </li>
                                        )
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Card
                    sx={{
                        borderRadius: 4,
                        height: 'auto',
                        width: "93%",
                        margin: 4,
                        marginTop: 15,
                        backgroundColor: 'rgba(255,255,255,0.3)'
                    }}
                    variant="outlined">
                    <div>
                        {searchedInsight ? <ShowInsights searchedInsight={searchedInsight} /> : <InsightLanding />}
                    </div>
                </Card>
            </>
        )
    }

    return (
        <>
            <CustomizedSnackbar
                handleClose={handleSnackBarClose}
                open={openSnack}
                severity={severity}
                message={snackMessage}
            />

            <div className="content-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                        <h5 className="font-weight-600 mb-0 ">
                            Warranty Analytics
                        </h5>
                    </div>
                    <Card
                        sx={{
                            borderRadius: 4,
                            height: 'auto',
                            width: "100%",
                            marginBottom: 2
                        }}
                        variant="outlined"
                    >
                        <Box sx={{ width: "100%", height: "auto", marginTop: 2, minHeight: '884px' }}>
                            <TabContext value={activeTab}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={(e, tabValue) => setActiveTab(tabValue)}
                                        centered
                                    >
                                        <Tab label={`GENERAL`} value="general" />
                                        <Tab label={`FAILED PARTS & MODELS`} value="failedParts" />
                                        <Tab label={`REGION OR PARTNERS`} value="region" />
                                        <Tab label={`FAILURE MODES`} value="failureModes" />
                                        <Tab label={`ACTIONABLE INSIGHTS`} value="actionableInsights" />
                                    </TabList>
                                </Box>
                                <TabPanel value={activeTab} sx={{ minHeight: '884px',marginX:-2 }} className="mt-2 blue-white-gradient">
                                    {activeTab === "general" && viewGeneralTab()}
                                    {activeTab === "failureModes" && viewFailureModesTab()}
                                    {activeTab === "region" && viewRegionTab()}
                                    {activeTab === "failedParts" && viewFailedPartsTab()}
                                    {activeTab === "actionableInsights" && viewActionableInsightsTab()}
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default WarrantyAnalytics;
