import React, { useEffect, useState } from "react";

import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Tooltip, TextField, FormControlLabel, FormGroup, Switch } from "@mui/material";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import {
  authClaimentOptions,
  claimRequestObj,
  claimRequestTypeOptions,
  questionsOptions,
  makeOptions,
  familyOptions,
} from "../warrantyManagementConstants";
import SearchBox from "pages/Common/SearchBox";
import { customerSearch, machineSearch } from "services/searchServices";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { CLAIM_MASTER_URL } from "services/CONSTANTS";
import { isEmptySelect } from "pages/Common/textUtilities";
import ReplacementQuestion from "./ReplacementQuestion";
import ReplacementModal from "../returnMaster/ReplacementModal";
import ReplacementDetailsModal from "../returnMaster/ReplacementDetailsModal";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import Moment from "react-moment";
import EquipmentEnrollModal from "./EquipmentEnrollModal";

const WarrantyRequestAuthorization = ({
  show,
  hideModal,
  handleSnack,
  pwaNumber,
  setPwaNumber,
  warrantyRequestType = "",
  claimRecordId,
  setClaimRecordId,
  handleGoBackToQurestionsModal,
  autoReqObj,
  isReplacement,
  showRepalcementQueModal,
  handleCloseReplacementQueModal,
  showReplacmentModal,
  handleShowReplacementQueModal,
  handleShowReplacementModal,
  handleCloseReplacementModal,
  handleShowCreateNewEquModal,
  openEnrollEquModal,
  openEquFileUploadModal,
  handleOpenClaimOrderCreateModal,
  isPreAuthorized,
}) => {
  const [tabValue, setTabValue] = useState("generalRequest");

  const [recordData, setRecordData] = useState({
    ...claimRequestObj,
    // claimType: warrantyRequestType || "",
    claimType: autoReqObj?.requestType || "",
    customerNumber: autoReqObj?.customerNumber || "",
    customerName: autoReqObj?.customerName || "",
    replacement: isReplacement,
    claimStory: autoReqObj?.claiment?.value || autoReqObj?.claiment || "",
    // claiment: authClaimentOptions[1],
    claiment: autoReqObj?.claiment?.value || autoReqObj?.claiment || "",
    climentDetails: autoReqObj?.requesterDetails,
    // climentDetails: "ABC Plumber",
    requestDescription: "",
    // make: makeOptions[0],
    // family: familyOptions[0],
    usedFor: "",
    usageCondition: "",
  });

  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [searchSerialResults, setSearchSerialResults] = useState([]);

  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);

  const [isNewCreated, setIsNewCreated] = useState(false);

  const [equRecordId, setEquRecordId] = useState(null);

  const [viewOnlyTab, setViewOnlyTab] = useState({
    gerenalViewOnly: false,
    machineViewOnly: false,
    customerViewOnly: false,
  });

  useEffect(() => {
    if (claimRecordId && !isNewCreated) {
      const rUrl = `${CLAIM_MASTER_URL}/${claimRecordId}`;
      callGetApi(rUrl, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // request type
          const _claimType = claimRequestTypeOptions.find((obj) => obj.value === responseData.claimType);

          // make
          //   const _make = makeOptions.find((obj) => obj.value === responseData?.make);

          // family
          //   const _family = familyOptions.find((obj) => obj.value === responseData?.family);

          setRecordData({
            ...recordData,
            ...responseData,
            claimType: _claimType,
            claiment: responseData?.claimStory || "",
            // make: _make || makeOptions[0],
            // family: _family || familyOptions[0],
          });

          setViewOnlyTab({
            gerenalViewOnly: true,
            machineViewOnly: true,
            customerViewOnly: true,
          });

          setPwaNumber(responseData.authorizationCode);
        }
      });
    }
  }, [claimRecordId]);

  // select option change
  const handleSelectChange = (e, keyName) => {
    setRecordData({ ...recordData, [keyName]: e });
  };

  // select option change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      recordData.modelNumber = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      recordData.serialNumber = searchText;
      searchQueryMachine = searchText
        ? recordData.modelNumber
          ? `model:${recordData.modelNumber} AND makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setRecordData({
        ...recordData,
        modelNumber: currentItem.model,
        // make: currentItem.maker,
        // // equipmentId: currentItem.id,
      });
      setSearchModelResults([]);
    } else if (type === "makerSerialNumber") {
      setRecordData({
        ...recordData,
        make: currentItem.maker,
        family: currentItem.market,
        modelNumber: currentItem.model,
        serialNumber: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        equipmentNumber: currentItem.equipmentNumber,
        warrantyId: currentItem.warrantyId,
        customerNumber: currentItem.customerId,
        customerName: currentItem.customer,
      });
      setSearchSerialResults([]);
    }
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    recordData.customerNumber = searchText;
    recordData.customerName = "";
    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the customer from search result
  const handleCustSelect = (type, currentItem) => {
    setRecordData({
      ...recordData,
      // id: currentItem.id,
      customerNumber: currentItem.customerId,
      customerName: currentItem.fullName,
      // address: currentItem.addressDTO?.fullAddress,
      // city: currentItem.addressDTO?.district,
      // state: currentItem.addressDTO?.regionOrState,
      // zipCode: currentItem.addressDTO?.zipCode,
      // email: currentItem.email,
      // addressId: currentItem.addressDTO?.id,
    });
    setSearchCustResults([]);
  };

  // check general request tab input validation
  const checkGeneralRequestInputValidation = (recordObj) => {
    if (isEmptySelect(recordObj.claimType)) {
      handleSnack("error", "Request Type must not be empty.");
      return false;
    }
    return true;
  };

  // check general request tab input validation
  const checkMachineInputValidation = (recordObj) => {
    // if (!recordObj.modelNumber) {
    //     handleSnack("error", "Model must not be empty");
    //     return false;
    // } else if (recordObj.modelNumber && searchModelResults.length !== 0) {
    //     handleSnack("error", "Select model from search results.");
    //     return false;
    // } else if (recordObj.modelNumber && noOptionsModel) {
    //     handleSnack("error", "Model must be valid");
    //     return false;
    // } else if (!recordObj.modelNumber) {
    //     handleSnack("error", "Model must not be empty");
    //     return false;
    // } else if (recordObj.modelNumber && searchModelResults.length !== 0) {
    //     handleSnack("error", "Select model from search results.");
    //     return false;
    // } else if (recordObj.modelNumber && noOptionsModel) {
    //     handleSnack("error", "Model must be valid");
    //     return false;
    // }
    return true;
  };

  // check general request tab input validation
  const checkCustomerInputValidation = () => {
    // if (!recordObj.customerNumber) {
    //     handleSnack("error", "Customer Number must not be empty");
    //     return false;
    // } else if (recordObj.customerNumber && searchCustResults.length !== 0) {
    //     handleSnack("error", "Select Customer Number from search results.");
    //     return false;
    // } else if (recordObj.customerNumber && noOptionsCust) {
    //     handleSnack("error", "Customer Number must be valid");
    //     return false;
    // }
    return true;
  };

  // click on Save and Next button
  const handleClickSaveBtn = (e) => {
    const { id } = e.target;

    if (id === "generalRequest" && viewOnlyTab?.customerViewOnly) {
      setTabValue("machine");
      return;
    } else if (id === "machine" && viewOnlyTab?.machineViewOnly) {
      setTabValue("customer");
      return;
    } else if (id === "customer" && viewOnlyTab?.customerViewOnly) {
      hideModal();
      return;
    }

    const rObj = {
      ...recordData,
      claimType: recordData.claimType?.value || recordData.claimType || "EMPTY",
      claimStatus: "REGISTERED",
      payer: "CUSTOMER",
      //   claiment: recordData.claiment?.value || recordData.claiment || "EMPTY",
      claiment: recordData.claiment || "EMPTY",
      usedFor: recordData.usedFor?.value || recordData.usedFor || "EMPTY",
      usageCondition: recordData.usageCondition?.value || recordData.usageCondition || "EMPTY",
      authorizationCode: pwaNumber || "",
      //   make: recordData?.make?.value || recordData.make || "",
      //   family: recordData?.family?.value || recordData.family || "",
    };

    if (id === "generalRequest" && !checkGeneralRequestInputValidation(rObj)) {
      return;
    } else if (id === "machine" && !checkMachineInputValidation(rObj)) {
      return;
    } else if (id === "customer" && !checkCustomerInputValidation(rObj)) {
      return;
    }

    if (claimRecordId) {
      const rUrl = `${CLAIM_MASTER_URL}/${claimRecordId}`;
      callPutApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          if (id === "generalRequest") {
            setTabValue("machine");
          } else if (id === "machine") {
            setTabValue("customer");
          } else if (id === "customer") {
            if (recordData?.claiment.toUpperCase() === "PARTNER") {
              if (isReplacement) {
                handleShowReplacementQueModal();
              } else {
                handleGoBackToQurestionsModal();
              }
            } else {
              handleOpenClaimOrderCreateModal({ ...recordData, claimId: claimRecordId });
            }
          }
        }
      });
    } else {
      const rUrl = `${CLAIM_MASTER_URL}`;
      callPostApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setIsNewCreated(true);
          setPwaNumber(responseData?.authorizationCode);
          if (isPreAuthorized) {
            handleSnack("success", `Authorization code ${responseData.authorizationCode} created successfully.`);
          } else {
            handleSnack("success", `Warranty request is created successfully`);
          }

          setClaimRecordId(responseData.claimId);
          if (id === "generalRequest") {
            setTabValue("machine");
          } else if (id === "machine") {
            setTabValue("customer");
          } else if (id === "customer") {
            if (recordData?.claiment.toUpperCase() === "PARTNER") {
              if (isReplacement) {
                handleShowReplacementQueModal();
              } else {
                handleGoBackToQurestionsModal();
              }
            } else {
              handleOpenClaimOrderCreateModal({ ...recordData, claimId: claimRecordId });
            }
            // handleGoBackToQurestionsModal();
          }
        }
      });
    }
  };

  // General tab
  const viewGereralTabData = () => {
    return (
      <>
        <div className="row px-3 mt-0 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn bg-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, gerenalViewOnly: false })} id="general">
            Edit
          </button>
        </div>
        <div className="card border px-3 py-2 mb-3">
          {!viewOnlyTab.gerenalViewOnly ? (
            <div className="row input-fields">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REQUEST TYPE</label>
                  <Select
                    onChange={(e) => handleSelectChange(e, "claimType")}
                    options={claimRequestTypeOptions}
                    value={recordData.claimType}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="REQUEST TYPE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claimType?.label} />
            </div>
          )}
        </div>
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab.gerenalViewOnly ? (
            <div className="row input-fields">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIMENT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="claiment"
                    placeholder="claiment"
                    value={recordData.claiment}
                    disabled
                    // onChange={handleInputTextChange}
                  />
                  {/* <Select
                    onChange={(e) => handleSelectChange(e, "claiment")}
                    options={authClaimentOptions}
                    value={recordData.claiment}
                    styles={FONT_STYLE_SELECT}
                  /> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIMENT DETAILS</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="climentDetails"
                    placeholder="Claiment Details"
                    value={recordData.climentDetails}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">NAME OF APPROVER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="claimApprover"
                    placeholder="Name of Approver"
                    value={recordData.claimApprover}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="CLAIMENT" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claiment} />
              {/* <ReadOnlyField label="CLAIMENT" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claiment?.label} /> */}
              <ReadOnlyField label="CLAIMENT DETAILS" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.climentDetails} />
              <ReadOnlyField label="NAME OF APPROVER" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claimApprover} />
            </div>
          )}
        </div>
        {!viewOnlyTab.gerenalViewOnly ? (
          <div className="row input-fields">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REQUEST DESCRIPTION</label>
                {/* <input
                                type="text"
                                className="form-control border-radius-10 text-primary"
                                name="requestDescription"
                                placeholder="Request Description"
                                value={recordData.requestDescription}
                                onChange={handleInputTextChange}
                            /> */}
                <textarea
                  className="form-control border-radius-10 text-primary"
                  cols="30"
                  rows="4"
                  name="claimNotes"
                  placeholder="Request Description"
                  value={recordData.claimNotes}
                  onChange={handleInputTextChange}
                ></textarea>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField label="REQUEST DESCRIPTION" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claimNotes} />
          </div>
        )}
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab.gerenalViewOnly ? (
            <div className="row input-fields">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">APPROVED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // maxDate={new Date()}
                        closeOnSelect
                        value={recordData.claimReceiptDate}
                        onChange={(e) => handleSelectChange(e, "claimReceiptDate")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">FAILURE DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // maxDate={new Date()}
                        closeOnSelect
                        value={recordData.failDate}
                        onChange={(e) => handleSelectChange(e, "failDate")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginLeft: 0,
                      }}
                      control={
                        <Switch
                          checked={recordData.replacement}
                          onChange={(e) => {
                            return;
                          }}
                          // disabled
                          // onChange={(e) =>
                          //     setRecordData({
                          //         ...recordData,
                          //         replacement: e.target.checked,
                          //     })
                          // }
                        />
                      }
                      labelPlacement="top"
                      label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField
                label="APPROVED ON"
                className="col-lg-4 col-md-4 col-sm-4 col-12"
                value={recordData.claimReceiptDate ? <Moment format="DD/MM/YYYY">{recordData.claimReceiptDate}</Moment> : "NA"}
              />
              <ReadOnlyField
                label="FAILURE DATE"
                className="col-lg-4 col-md-4 col-sm-4 col-12"
                value={recordData.failDate ? <Moment format="DD/MM/YYYY">{recordData.failDate}</Moment> : "NA"}
              />
              <ReadOnlyField label="REPLACEMENT" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.replacement ? "YES" : "NO"} />
            </div>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewOnlyTab?.customerViewOnly && (
            <button className="btn btn-border-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
          )}
          <button className="btn bg-primary text-white" onClick={handleClickSaveBtn} id="generalRequest">
            {!viewOnlyTab?.customerViewOnly ? "Save & Next" : "Next"}
          </button>
        </div>
      </>
    );
  };

  //  machine tab
  const viewMachineTabData = () => {
    return (
      <>
        <div className="row px-3 mt-0 mb-3" style={{ justifyContent: "right" }}>
          {/* <button className="btn border-primary mx-2" onClick={handleShowCreateNewEquModal}>
            + Create New
          </button> */}
          <button className="btn btn-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: false })} id="general">
            Edit
          </button>
        </div>
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab?.machineViewOnly ? (
            <div className="row input-fields">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                  {/* <Select
                    onChange={(e) => setRecordData({ ...recordData, make: e })}
                    options={makeOptions}
                    value={recordData.make}
                    styles={FONT_STYLE_SELECT}
                  /> */}
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="reference"
                    placeholder="Auto Fill Search Model"
                    value={recordData.make}
                    disabled={true}
                    //   onChange={handleInputFieldsChange}
                  />
                  {/* <SearchBox
                                    value={recordData.make}
                                    onChange={(e) =>
                                        handleMachineSearch(
                                            "make",
                                            e.target.value
                                        )
                                    }
                                    type="model"
                                    // result={searchModelResults}
                                    // onSelect={handleModelSelect}
                                    // noOptions={noOptionsModel}
                                    disabled={true}
                                    placeholder="Make"
                                /> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                  <SearchBox
                    value={recordData.modelNumber}
                    onChange={(e) => handleMachineSearch("model", e.target.value)}
                    type="model"
                    placeholder="Search Model"
                    result={searchModelResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsModel}
                  />
                  {/* <div className="css-w8dmq8">*Mandatory</div> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                  <SearchBox
                    value={recordData.serialNumber}
                    onChange={(e) => handleMachineSearch("serialNo", e.target.value)}
                    type="makerSerialNumber"
                    result={searchSerialResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsSerial}
                    placeholder="Search Serial Number"
                  />
                  {/* <div className="css-w8dmq8">*Mandatory</div> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">FAMILY</label>
                  {/* <Select
                    onChange={(e) => setRecordData({ ...recordData, family: e })}
                    options={familyOptions}
                    value={recordData?.family}
                    styles={FONT_STYLE_SELECT}
                  /> */}
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="family"
                    placeholder="Auto Fill Search Model"
                    value={recordData.family}
                    disabled={true}
                    // onChange={handleInputFieldsChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">USED FOR</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="usedFor"
                    placeholder="Used For"
                    value={recordData.usedFor}
                    onChange={handleInputTextChange}
                  />
                  {/* <Select
                                    // onChange={(e) =>
                                    //   setRecordData({
                                    //     ...recordData,
                                    //     claimType: e,
                                    //   })
                                    // }
                                    options={claimRequestTypeOptions}
                                    // value={recordData.claimType}
                                    styles={FONT_STYLE_SELECT}
                                /> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">USAGE CONDITION</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="usageCondition"
                    placeholder="Usage Condition"
                    value={recordData.usageCondition}
                    onChange={handleInputTextChange}
                  />
                  {/* <Select
                                    // onChange={(e) =>
                                    //   setRecordData({
                                    //     ...recordData,
                                    //     claimType: e,
                                    //   })
                                    // }
                                    options={claimRequestTypeOptions}
                                    // value={recordData.claimType}
                                    styles={FONT_STYLE_SELECT}
                                /> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="MAKE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.make} />
              {/* <ReadOnlyField label="MAKE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.make?.label} /> */}
              <ReadOnlyField label="MODEL" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.modelNumber} />
              <ReadOnlyField label="SERIAL NUMBER" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.serialNumber} />
              <ReadOnlyField label="FAMILY" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.family} />
              {/* <ReadOnlyField label="FAMILY" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.family?.label} /> */}
              <ReadOnlyField label="USED FOR" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData?.usedFor} />
              <ReadOnlyField label="USAGE CONDITION" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData?.usageCondition} />
            </div>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewOnlyTab?.machineViewOnly && (
            <button className="btn btn-border-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
          )}
          <button className="btn bg-primary text-white" onClick={handleClickSaveBtn} id="machine">
            {!viewOnlyTab?.machineViewOnly ? "Save & Next" : "Next"}
          </button>
        </div>
      </>
    );
  };

  // customer tab
  const viewCustomerTabData = () => {
    return (
      <>
        <div className="row px-3 mt-0 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn btn-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, customerViewOnly: false })} id="general">
            Edit
          </button>
        </div>
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab?.customerViewOnly ? (
            <div className="row input-fields">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NUMBER</label>
                  <SearchBox
                    value={recordData.customerNumber}
                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                    type="customerId"
                    result={searchCustResults}
                    onSelect={handleCustSelect}
                    noOptions={noOptionsCust}
                    placeholder="Customer Number"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="customerName"
                    placeholder="Customer Name"
                    value={recordData.customerName}
                    onChange={handleInputTextChange}
                    // disabled={true}
                  />
                  {/* <SearchBox
                                    value={recordData.customerName}
                                    // onChange={(e) =>
                                    //   handleCustSearch("customerId", e.target.value)
                                    // }
                                    type="customerId"
                                    // result={searchCustResults}
                                    // onSelect={handleCustSelect}
                                    // noOptions={noOptionsCust}
                                    disabled={true}
                                    placeholder="Customer Name"
                                /> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="reference"
                    placeholder="Reference"
                    value={recordData?.reference}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="CUSTOMER NUMBER" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.customerNumber} />
              <ReadOnlyField label="CUSTOMER NAME" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.customerName} />
              <ReadOnlyField label="REFERENCE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData?.reference} />
            </div>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewOnlyTab?.customerViewOnly && (
            <button className="btn btn-border-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
          )}

          {viewOnlyTab?.customerViewOnly && (
            <button
              className="btn border-primary text-primary mx-3"
              onClick={() => handleOpenClaimOrderCreateModal({ ...recordData, claimId: claimRecordId })}
            >
              {recordData?.claimOrderId ? "Go to Claim Order" : "Create Claim Order"}
            </button>
          )}
          <button className="btn bg-primary text-white" onClick={handleClickSaveBtn} id="customer">
            {!viewOnlyTab?.customerViewOnly ? "Save & Next" : "Close"}
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      {show && (
        <Modal show={show} onHide={hideModal} size="xl">
          {/* <Modal.Header>
                <Modal.Title class="h5 mb-0">Warranty Request</Modal.Title>
            </Modal.Header> */}
          <Modal.Body>
            <Box sx={{ typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    className="custom-tabs-div"
                    aria-label="lab API tabs example"
                    onChange={(e, newTabValue) => setTabValue(newTabValue)}
                    centered
                  >
                    <Tab label={`GENERAL REQUEST`} value="generalRequest" />
                    <Tab label={`MACHINE`} value="machine" />
                    <Tab label={`CUSTOMER`} value="customer" />
                  </TabList>
                </Box>
                <div className="ligt-greey-bg p-3">
                  <div>
                    <span className="mr-3 cursor">
                      <i className="fa fa-pencil font-size-12" aria-hidden="true"></i>
                      <span className="ml-2">Edit</span>
                    </span>
                    <span className={`mr-3 cursor `}>
                      <SellOutlinedIcon className=" font-size-16" />
                      <span className="ml-2"> Authorization Code({pwaNumber})</span>
                    </span>
                  </div>
                </div>
                <TabPanel value={tabValue}>
                  {tabValue === "generalRequest" && viewGereralTabData()}
                  {tabValue === "machine" && viewMachineTabData()}
                  {tabValue === "customer" && viewCustomerTabData()}
                </TabPanel>
              </TabContext>
            </Box>
          </Modal.Body>
        </Modal>
      )}
      {showRepalcementQueModal && (
        <ReplacementQuestion
          show={showRepalcementQueModal}
          hideModal={handleCloseReplacementQueModal}
          handleSnack={handleSnack}
          handleShowReplacementModal={handleShowReplacementModal}
        />
      )}

      {showReplacmentModal && (
        <ReplacementDetailsModal
          show={showReplacmentModal}
          hideModal={handleCloseReplacementModal}
          handleSnack={handleSnack}
          requestObj={{ ...recordData, authorizationCode: pwaNumber, claiment: recordData?.claiment }}
          //   requestObj={{ ...recordData, authorizationCode: pwaNumber, claiment: recordData?.claiment?.label }}
        />
      )}

      {openEnrollEquModal && (
        <EquipmentEnrollModal
          show={openEnrollEquModal}
          hideModal={handleShowCreateNewEquModal}
          handleSnack={handleSnack}
          equRecordId={equRecordId}
          setEquRecordId={setEquRecordId}
          openFileUploadModal={openEquFileUploadModal}
        />
      )}
    </>
  );
};

export default WarrantyRequestAuthorization;
