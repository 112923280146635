import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ServiceSegmentMaster from "./ServiceSegmentMaster";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: "0.6rem",
  "&:last-child": {
    marginBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => {
  const { expanded } = props;
  return (
    <MuiAccordionSummary
      expandIcon={expanded ? <KeyboardArrowUpIcon sx={{ fontSize: "3rem" }} /> : <ChevronRightIcon sx={{ fontSize: "3rem" }} />}
      {...props}
    />
  );
})(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SegmentsMaster = ({ segments = [] }) => {
  const [expanded, setExpanded] = useState("");

  const [expandedOperation, setExpandedOperation] = useState("");

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setExpandedOperation("");
  };

  const handleExpandOperaton = (panel) => async (event, newExpanded) => {
    setExpandedOperation(newExpanded ? panel : false);

    // await fetchSegments(activeElement.bId)
  };

  // service estimate API
  const getServiceEstimate = () => {};

  // Labour API
  const getServiceLabour = () => {};

  // consumable API
  // external-work API
  // labour-item API

  return (
    <>
      {segments.length > 0 &&
        segments.map((element, index) => (
          <Accordion key={index} expanded={expanded === `${element?.segmentNumber}`} onChange={handleExpand(`${element?.segmentNumber}`)}>
            <AccordionSummary expanded={expanded === `${element?.segmentNumber}`}>
              {`Segment ${String(element.segmentNumber).padStart(2, "0")} - ${element.description}`}
            </AccordionSummary>
            <AccordionDetails>
              {element["operations"] && element["operations"].length > 0 ? (
                element["operations"].map((operation) => (
                  <Accordion key={index} expanded={expandedOperation === `panel${index}`} onChange={handleExpandOperaton(`panel${index}`)}>
                    {/* <AccordionSummary expanded={expandedOperation === `panel${index}`}>{`Operation ${operation?.description}`}</AccordionSummary> */}
                    <AccordionSummary expanded={expandedOperation === `panel${index}`}>{`${operation?.description}`}</AccordionSummary>
                    <AccordionDetails>
                      <ServiceSegmentMaster />
                      <div className="d-flex justify-content-center align-items-center">
                        <button className="btn border bg-white text-light-blue font-weight-500">+ LABOR</button>
                        <button className="btn border bg-white text-light-blue font-weight-500 ml-2">+ MATERIAL</button>
                        <button className="btn border bg-white text-light-blue font-weight-500 ml-2">+ OTHER COST</button>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <div className="d-flex justify-content-between align-items-baseline px-2">
                  <h5>No Operations Available...</h5>
                  <button className="btn bg-primary text-white">+Add New</button>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default SegmentsMaster;
