import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import $ from "jquery";
import SelectFilter from "react-select";
import { Link, useHistory } from "react-router-dom";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { SUPPLIER_CLAIM_MASTER } from "navigation/CONSTANTS";
import {
  claimTypeOptions,
  recentSupplierInProcessData,
  supplierClaimInProcessOptions,
  supplierClaimProcessingStatusOptions,
  supplierClaimStatusOptions,
} from "../warrantyManagementConstants";
import { Box, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_STYLE } from "pages/Common/constants";
import SupplierClaimProcessDetails from "./SupplierClaimProcessDetails";
import { useSelector } from "react-redux";

const SupplierClaimProcess = () => {
  const history = useHistory();

  const { currencyKeyValuePair } = useSelector((state) => state.commonAPIReducer);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);
  const [reocrds, setReocrds] = useState([...recentSupplierInProcessData]);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  //  handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // select search vendor
  const handleSelectVendorCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    obj.selectOptions = [];
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    // setShowSupplierDtls(false);
  };

  // evendor quipment input search
  const handleInputSearch = (e, id) => {
    const { value } = e.target;
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    let searchString = tempArray[id].selectCategory.value + "~" + value;
    if (tempArray[id].selectCategory.value && value) {
      if (tempArray[id].selectCategory?.value === "EDITING_STATUS") {
        const filteredOptions = supplierClaimStatusOptions.filter((option) => option?.label?.toLowerCase().includes(value.toLowerCase()));
        obj.selectOptions = filteredOptions;
        tempArray[id] = obj;
        setQuerySearchSelector([...tempArray]);
        $(`.scrollbar-${id}`).css("display", "block");
      } else if (tempArray[id].selectCategory.value === "PROCESSING_STATUS") {
        const filteredOptions = supplierClaimProcessingStatusOptions.filter((option) => option?.label?.toLowerCase().includes(value.toLowerCase()));
        obj.selectOptions = filteredOptions;
        tempArray[id] = obj;
        setQuerySearchSelector([...tempArray]);
        $(`.scrollbar-${id}`).css("display", "block");
      } else if (tempArray[id].selectCategory.value === "CLAIM_TYPE") {
        const filteredOptions = claimTypeOptions.filter((option) => option?.label?.toLowerCase().includes(value.toLowerCase()));
        obj.selectOptions = filteredOptions;
        tempArray[id] = obj;
        setQuerySearchSelector([...tempArray]);
        $(`.scrollbar-${id}`).css("display", "block");
      }
      //   getVendors(searchString)
      //     .then((res) => {
      //       obj.selectOptions = res;
      //       tempArray[id] = obj;
      //       setQuerySearchSelector([...tempArray]);
      //       $(`.scrollbar-${id}`).css("display", "block");
      //     })
      //     .catch((err) => {
      //       handleSnack("error", "Error occurred while searching spare parts!");
      //     });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = value;
  };

  // vendor input search list click
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    if (
      obj?.selectCategory?.value === "EDITING_STATUS" ||
      obj?.selectCategory?.value === "PROCESSING_STATUS" ||
      obj?.selectCategory?.value === "CLAIM_TYPE"
    ) {
      obj.inputSearch = currentItem?.label;
      // obj.inputSearch = currentItem[obj.selectCategory.value];
    }
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete vendor search query list
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    // try {
    //   if (searchStr) {
    //     const res = await getVendors(`${searchStr}`);
    //     setShowSupplierDtls(true);
    //     // setMasterData(res);
    //   } else {
    //     handleSnack("info", "Please fill the search criteria!");
    //   }
    // } catch (err) {
    //   handleSnack("error", "Error occurred while fetching spare parts!");
    // }
  };

  const handleViewDetails = (row) => {
    setOpenDetailsModal(true);
  };

  const columns = [
    {
      field: "claimNumber",
      headerName: "Claim Number",
      flex: 1,
    },
    {
      field: "processingStatus",
      headerName: "Processing Status",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 1,
    },
    {
      field: "objectNumber",
      headerName: "Object Number",
      flex: 1,
    },
    {
      field: "repairDate",
      headerName: "Repair Date",
      flex: 1,
    },
    {
      field: "decision",
      headerName: "Decision",
      flex: 1,
    },
    {
      field: "damageDate",
      headerName: "Damage Date",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      //   width: 150,
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => handleViewDetails(row)}>
                <Tooltip title="View">
                  <VisibilityIcon />
                  {/* <EditOutlinedIcon /> */}
                </Tooltip>
              </div>
            }
            label="View "
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
            <h5 className="font-weight-600 mb-0 ">Supplier Claim in Process</h5>
            <button className="btn border-primary text-primary" onClick={() => history.push(`${SUPPLIER_CLAIM_MASTER}`)}>
              <ArrowBackIcon className="mx-1" />
              Back
            </button>
          </div>
          <div className="bg-primary px-3 mb-3 border-radius-6">
            <div className="row align-items-center">
              <div className="col-12 mx-2">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center bg-primary w-100">
                    <div className="d-flex mr-3 py-3" style={{ whiteSpace: "pre" }}>
                      <h5 className="mr-2 mb-0 text-white">
                        <span>Search</span>
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mr-4">
                      <div className="row align-items-center m-0">
                        {querySearchSelector.map((obj, i) => (
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{
                              position: "relative",
                              zIndex: 20 - i,
                            }}
                            key={"query" + i}
                          >
                            {i > 0 && (
                              <SelectFilter
                                isClearable={true}
                                defaultValue={{ label: "And", value: "AND" }}
                                options={[
                                  { label: "And", value: "AND", id: i },
                                  { label: "OR", value: "OR", id: i },
                                ]}
                                placeholder="And/Or"
                                onChange={(e) => handleOperator(e, i)}
                                value={obj.selectOperator}
                              />
                            )}

                            <div>
                              <SelectFilter
                                // isClearable={true}
                                options={supplierClaimInProcessOptions}
                                onChange={(e) => handleSelectVendorCategory(e, i)}
                                value={obj.selectCategory}
                              />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{ position: "relative" }}
                                type="text"
                                placeholder="Search string"
                                value={obj.inputSearch}
                                onChange={(e) => handleInputSearch(e, i)}
                                id={"inputSearch-" + i}
                                autoComplete="off"
                              />
                              {querySearchSelector.length - 1 === i && (
                                <div className="btn bg-primary text-white" onClick={() => handleQuerySearchClick()}>
                                  <span className="mr-2">
                                    <SearchIcon />
                                  </span>
                                  SEARCH
                                </div>
                              )}
                              {obj.selectOptions && obj.selectOptions.length > 0 && (
                                <ul className={`list-group customselectsearch-list scrollbar-repair-autocomplete scrollbar-${i} style`} id="style">
                                  {obj.selectOptions.map((currentItem, j) => (
                                    <li className="list-group-item" key={j} onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}>
                                      {/* {`${currentItem?.customerId} - ${currentItem?.fullName}`} */}
                                      {(obj?.selectCategory?.value === "EDITING_STATUS" ||
                                        obj?.selectCategory?.value === "PROCESSING_STATUS" ||
                                        obj?.selectCategory?.value === "CLAIM_TYPE") &&
                                        currentItem["label"]}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link to="#" className="btn-sm text-black border mr-2" style={{ border: "1px solid #872FF7" }}>
                            <span style={{ color: "white" }}>+</span>
                          </Link>
                        </div>

                        <div onClick={handleDeleteQuerySearch}>
                          <Link to="#" className="btn-sm border mr-2">
                            <i className="fa fa-trash fa-lg" style={{ color: "white" }}></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <Box
              sx={{
                width: "100%",
                // height: 400,
              }}
            >
              <DataGrid
                sx={GRID_STYLE}
                rows={reocrds}
                columns={columns}
                autoHeight
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                getRowId={(row) => row.id}
              />
            </Box>
          </div>
        </div>
      </div>
      {openDetailsModal && (
        <SupplierClaimProcessDetails
          show={openDetailsModal}
          hideModal={() => setOpenDetailsModal(false)}
          handleSnack={handleSnack}
          currencyKeyValuePair={currencyKeyValuePair}
        />
      )}
    </>
  );
};

export default SupplierClaimProcess;
