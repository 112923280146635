// Error occurred while fetching spare parts!
export const INPUT_SEARCH_ERROR_MESSAGE = "Please fill current search criteria";
export const INPUT_SEARCH_API_ERROR_MESSAGE = "Somthing went wrong";
export const INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE = "No information is found for your search, change the search criteria";
export const EMPTY_SEARCH_FIELDS_ERROR_MESSAGE = "Please fill the search criteria!";
export const FILL_DATA_PROPERLY_ERROR_MESSAGE = "Please fill the data Properly";

export const WITH_PARTS = "BUILDER_WITH_SPAREPART";
export const WITHOUT_PARTS = "BUILDER_WITHOUT_SPAREPART";
export const NEW_SEGMENT = "New Segment";
export const NEW_OPERATION = "New Operation";
export const SERVICE_TEMPLATE = "SERVICE_TEMPLATE";
export const PART_AND_SERVICE_TEMPLATE = "PART_AND_SERVICE_TEMPLATE";
export const INITIAL_PAGE_NO = 0;
export const INITIAL_PAGE_SIZE = 5;

export const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#872ff7",
    color: "#fff",
    fontSize: 12,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
};
export const FONT_STYLE = {
  color: "#872ff7",
  fontSize: "17px",
  fontWeight: "500",
  padding: 0,
};

export const FONT_STYLE_SELECT = {
  placeholder: (provided) => ({
    ...provided,
    ...FONT_STYLE,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 10,
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    ...FONT_STYLE,
  }),
};

export const SPAREPART_SEARCH_Q_OPTIONS = [
  {
    label: "Part No",
    value: "partNumber",
  },
  {
    label: "Description",
    value: "partDescription",
  },
  { label: "Model", value: "model" },
  {
    label: "Group No",
    value: "groupNumber",
  },
  {
    label: "Bec Code",
    value: "becCode",
  },
  { label: "Type", value: "partType" },
];

export const CONS_EXT_PRICE_OPTIONS = [
  {
    value: "LIST",
    label: "List Price",
  },
  {
    value: "PER_ON_TOTAL",
    label: "Percentage on Total",
  },
  {
    value: "PER_ON_LABOR",
    label: "Percentage on Labour",
  },
];

export const CONSUMABLE_SEARCH_Q_OPTIONS = [
  {
    label: "Id",
    value: "consumableId",
  },
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Vendor",
    value: "sourceOrVendor",
  },
];

export const EXTWORK_SEARCH_Q_OPTIONS = [
  {
    label: "Id",
    value: "activityId",
  },
  {
    label: "Activity Name",
    value: "activityDescription",
  },
  {
    label: "Supplying Vendor",
    value: "supplyingVendorName",
  },
];

export const LABOR_PRICE_OPTIONS = [
  {
    value: "LIST",
    label: "List Price",
  },
  {
    value: "COST_PLUS",
    label: "Cost Plus",
  },
];

export const MISC_PRICE_OPTIONS = [
  { value: "PER_ON_TOTAL", label: "Percentage on Total" },
  {
    value: "PER_ON_LABOR",
    label: "Percentage on Labour",
  },
];

export const orderTypeOptions = [
  { label: "Emmergency", value: "EMMERGENCY" },
  { label: "Stock Order", value: "STOCK_ORDER" },
];

export const storeOptions = [
  { label: "Store 1", value: "STORE_1" },
  { label: "Store 2", value: "STORE_2" },
  { label: "Store 3", value: "STORE_3" },
  { label: "Store 4", value: "STORE_4" },
];

export const paymentTermsOptions = [
  { label: "Immediate", value: "IMMEDIATE" },
  { label: "Credit", value: "CREDIT" },
];

export const billingTypesOptions = [
  { label: "Pay Fixed Price", value: "PAY_FIXED_PRICE" },
  { label: "Pay Support", value: "PAY_SUPPORT" },
  { label: "Pay Fixed Price Fixed Time", value: "PAY_FIXED_PRICE_FIXED_TIME" },
  { label: "Fixed Price Cost Model", value: "FIXED_PRICE_COST_MODEL" },
  { label: "Performance Based", value: "PERFORMANCE_BASED" },
  { label: "Pay Service Used", value: "PAY_SERVICE_USED" },
];

export const deliveryPriorityOptions = [
  { label: "Normal", value: "NORMAL" },
  { label: "Urgent", value: "URGENT" },
  //   { label: "Emergency", value: "EMERGENCY" },
  { label: "Very Urgent", value: "VERY_URGENT" },
];

export const shippingCondition = [
  { label: "Ship to Me", value: "SHIP_TO_ME" },
  { label: "Pickup", value: "PICKUP" },
];

export const shippingPointOptions = [
  // { label: "Shipping Point 1", value: "SHIPPING_POINT_1" },
  // { label: "Shipping Point 2", value: "SHIPPING_POINT_2" },
  // { label: "Shipping Point 3", value: "SHIPPING_POINT_3" },
  // { label: "Shipping Point 4", value: "SHIPPING_POINT_4" },
  // { label: "Shipping Point 5", value: "SHIPPING_POINT_5" },
  { label: "Van - DL 12 A 2947", value: "Van - DL 12 A 2947" },
  { label: "Van - MU 01 AB 1232", value: "Van - MU 01 AB 1232" },
  { label: "Van - KA 02 BW 1284", value: "Van - KA 02 BW 1284" },
  { label: "Van - DU 01 AB 3847", value: "Van - DU 01 AB 3847" },
  { label: "Van - MU 13 AX 1283", value: "Van - MU 13 AX 1283" },
];
