import React, { useState } from "react";

import CodeIcon from "@mui/icons-material/Code";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import SendIcon from "@mui/icons-material/Send";
import { DataGrid } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";

const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: "#872ff7",
    color: "gray",
    fontSize: 14,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    // borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
  marginInline: "auto",
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: 4,
  height: 400,
};

const data = [
  {
    id: 1,
    accountname: "Signal",
    employee: "1514",
    Opportunityname: "Signal -Test",
    amount: "20500",
  },
  {
    id: 2,
    accountname: "ServiceTitan",
    employee: "3662",
    Opportunityname: "ServiceTitan",
    amount: "48000",
  },
  {
    id: 3,
    accountname: "Docker",
    employee: "850",
    Opportunityname: "Docker",
    amount: "60000",
  },
  {
    id: 4,
    accountname: "Sprour Social Inc",
    employee: "800",
    Opportunityname: "Sprour Social Inc",
    amount: "48000",
  },
];

const ViewResultDetails = ({ show, hideModal }) => {
  const [textValue, setTextValue] = useState("");
  const [text, setText] = useState("");

  // for the no of letters
  const handleInput = (e) => {
    setTextValue(e.target.value); // Get the text from the contentEditable div
  };

  const column = [
    {
      field: "accountname",
      headerName: "Account Name",
      flex: 1,
    },
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      // width: 70,
    },
    {
      field: "Opportunityname",
      headerName: "Opportunity Name",
      flex: 1,
      // width: 70,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,

      // width: 70,
    },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg" centered>
        <div className="customer-details-modal-title">
          <div className="container-fluid mt-2">
            <div className="card pl-1 py-2 pr-5">
              <div className="d-flex align-items-center ">
                <div className="card border p-2">
                  <img
                    src={"../assets/images/user/6.jpg"}
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
                <div className=" d-flex align-items-center ml-2 mb-4 text-dark font-weight-bold font-size-14 ">
                  What are the best open opportunities by company size ?
                </div>
              </div>
              <div className="d-flex align-items-baseline ">
                <div className="text-dark mt-1">
                  <WbSunnyIcon fontSize="large" />
                </div>
                <div className="ml-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className=" font-weight-600 text-dark ">RESULTS</span>
                    <span className="font-weight-600 text-dark">
                      <ConstructionOutlinedIcon className="mr-2" />
                      Show Steps
                    </span>
                  </div>

                  <p className="font-weight-600 font-size-14 text-black mt-3">
                    The query retrieves the top 10 open opportunities sorted by
                    company size(number of employees) and Opportunity amount.It
                    joins the opportunities with their associated accounts to
                    get the company name and size, and filters out opportunities
                    that are qualified out.
                  </p>

                  <div className="mt-3">
                    <DataGrid
                      sx={{ ...GRID_STYLE }}
                      rows={data}
                      columns={column}
                      rowsPerPageOptions={[5, 10, 20]}
                      autoHeight
                    />
                  </div>
                  <div className=" mt-3">
                    <div className="d-flex justify-content-between align-content-center">
                      <div className="card border text-dark font-weight-600 p-2 ">
                        GENERATE MODEL
                      </div>
                      <div className="d-flex justify-content-evenly">
                        <div className="card border p-2 ml-2">
                          <CheckCircleIcon style={{ fontSize: 30 }} />
                        </div>
                        <div className="card border p-2 ml-2">
                          <DownloadIcon style={{ fontSize: 30 }} />
                        </div>
                        <div className="card border p-2 ml-2">
                          <CodeIcon style={{ fontSize: 30 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-4">
                <div
                  className="position-relative ml-3"
                  style={{ display: "inline-block", width: "100%" }}
                >
                  {textValue.length === 0 && (
                    <div
                      className="position-absolute d-flex align-items-center text-secondary"
                      style={{ top: "10px", left: "15px" }}
                    >
                      <ConstructionOutlinedIcon className="mr-2" />
                      <span>Ask AI a question or make a request...</span>
                    </div>
                  )}

                  <textarea
                    name="textValue"
                    cols={92}
                    rows={1}
                    value={textValue}
                    className="text-primary rounded border  p-2"
                    style={{ backgroundColor: "#f3eafe" }}
                    onChange={(e) => setTextValue(e.target.value)}
                  ></textarea>
                  <div
                    className="position-absolute d-flex align-items-center text-secondary font-weight-bold font-size-14"
                    style={{
                      top: "40%",
                      right: "2px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {`${textValue.length}/2000`}
                    <span>
                      <SendIcon
                        className="ml-4 mb-1"
                        style={{ transform: "rotate(-40deg)" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewResultDetails;
