import React, { useEffect } from "react";

import TextField from "@mui/material/TextField";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";
import Moment from "react-moment";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { WARRANTY_ASSESSMENT_MASTER_URL } from "services/CONSTANTS";
import { callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";

const AssessmentDetails = (props) => {
  const {
    viewOnlyTab,
    setRequestTab,
    setViewOnlyTab,
    assesstementData,
    setAssesstementData,
    underWarrantyOptions,
    warrantyTypeOptions,
    assessmentClaimCategory,
    assessmentCategoryType,
    handleSnack,
    warrantyData,
    setAssesstTabViewOnly,
    assesstTabViewOnly,
    setAssesstmentId,
    assesstmentId,
    claimRecordId,
    warrantyId,
    warrantyTitle,
    claimStatus,
    handleUpdateWarrantyRequest,
    assessClaimCategory,
    setAssessClaimCategory,
    assessCategoryType,
    setAssessCategoryType,
    pvPanelReqObj,
    setPvPanelReqObj,
    pvInversterReqObj,
    setPvInversterReqObj,
    pvOptimiserReqObj,
    setPvOptimiserReqObj,
    pvBatteryReqObj,
    setPvBatteryReqObj,
    pvOtherReqObj,
    setPvOtherReqObj,
  } = props;

  //   useEffect(() => {

  //   }, []);

  //Individual assessment field value change
  const handleAssesstementDataChange = (e) => {
    const { name, value } = e.target;
    setAssesstementData({ ...assesstementData, [name]: value });
  };

  // claim category Change
  const handleCategoryTypeChange = (e) => {
    setAssessClaimCategory("");
    setAssessCategoryType(e);
  };

  // Individual assessment field value change
  const handleAssesstementSelectDataChange = (e, keyName) => {
    setAssesstementData({ ...assesstementData, [keyName]: e });
  };

  // ! ------------- PV Panel Input fields Value Change Start ------------- ! //

  // PV Panel Input fields Value Change
  const handlePvPanelInputValueChange = (e) => {
    const { name, value } = e.target;
    setPvPanelReqObj({ ...pvPanelReqObj, [name]: value });
  };

  // PV Panel Select fields Value Change
  const handlePvPanelSelectValueChange = (keyName, value) => {
    setPvPanelReqObj({ ...pvPanelReqObj, [keyName]: value });
  };

  // PV Inverter Input fields Value Change
  const handlePvInverterValueChange = (e) => {
    const { name, value } = e.target;
    setPvInversterReqObj({ ...pvInversterReqObj, [name]: value });
  };

  // PV Inverter Select fields Value Change
  const handlePvInverterSelectChange = (keyName, value) => {
    setPvInversterReqObj({ ...pvInversterReqObj, [keyName]: value });
  };

  // PV Optimiser Input fields Value Change
  const handlePvOptimiserValueChange = (e) => {
    const { name, value } = e.target;
    setPvOptimiserReqObj({ ...pvOptimiserReqObj, [name]: value });
  };

  // PV Optimiser Select fields Value Change
  const handlePvOptimiserSelectChange = (keyName, value) => {
    setPvOptimiserReqObj({ ...pvOptimiserReqObj, [keyName]: value });
  };

  // PV Battery Input fields Value Change
  const handlePvBatteryValueChange = (e) => {
    const { name, value } = e.target;
    setPvBatteryReqObj({ ...pvBatteryReqObj, [name]: value });
  };

  // PV Battery Select fields Value Change
  const handlePvBatterySelectChange = (keyName, value) => {
    setPvBatteryReqObj({ ...pvBatteryReqObj, [keyName]: value });
  };

  // PV Other Input fields Value Change
  const handlePvOtherValueChange = (e) => {
    const { name, value } = e.target;
    setPvOtherReqObj({ ...pvOtherReqObj, [name]: value });
  };

  // PV Other Select fields Value Change
  const handlePvOtherSelectChange = (keyName, value) => {
    setPvOtherReqObj({ ...pvOtherReqObj, [keyName]: value });
  };

  // add update assessment
  const handleAddUpdateAssesstment = () => {
    if (assesstTabViewOnly) {
      setRequestTab("evaluation");
      return;
    }
    const reqObj = {
      ...assesstementData,
      machineUnderWarranty: assesstementData.machineUnderWarranty?.value || "EMPTY",
      assessmentType: assesstementData.assessmentType?.value || "EMPTY",
      claimRecordId: claimRecordId,
      // warrantyEndDate: warrantyEndDate,
    };

    if (assesstmentId) {
      callPutApi(
        null,
        `${WARRANTY_ASSESSMENT_MASTER_URL}/${assesstmentId}`,
        reqObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setAssesstmentId(responseData.assessmentId);
            handleSnack("success", "Warranty Assessment Updated  Successfully");
            // setViewOnlyTab({ ...viewOnlyTab, assesstViewOnly: true });
            setRequestTab("evaluation");
            setViewOnlyTab({ ...viewOnlyTab, assesstViewOnly: true });
            handleUpdateWarrantyRequest("assessment", responseData["assessmentId"]);
            setAssesstTabViewOnly(true);
          } else {
            handleSnack("error", "Something went wrong.");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong.");
        }
      );
    } else {
      callPostApi(
        null,
        WARRANTY_ASSESSMENT_MASTER_URL,
        {
          ...reqObj,
          warrantyId: warrantyId,
          warrantyTitle: warrantyTitle,
        },
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setAssesstmentId(responseData.assessmentId);
            handleSnack("success", "Warranty Assessment Created Successfully");
            // setViewOnlyTab({ ...viewOnlyTab, assesstViewOnly: true });
            handleUpdateWarrantyRequest("assessment", responseData["assessmentId"]);

            setRequestTab("evaluation");
            setAssesstTabViewOnly(true);
            setViewOnlyTab({
              ...viewOnlyTab,
              assesstViewOnly: true,
            });
          } else {
            handleSnack("error", "Something went wrong.");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong.");
        }
      );
    }
  };

  // pv panel View Mode
  const pvPanelViewDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">APPLIANCE - PV PRODUCT INFORMATION - PV PANEL</h5>
          <div className="row mt-3">
            <ReadOnlyField label="PV PANEL MODEL" value={pvPanelReqObj?.pvPanelModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PV PANEL P/N" value={pvPanelReqObj?.pvPanelPN} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="SYSTEM SIZE(DC)" value={pvPanelReqObj?.systemSize} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="NO. OF PANELS IN SYSTEM" value={pvPanelReqObj?.noOfPanelSystem} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="NO. OF PANELS PER STRING(S)" value={pvPanelReqObj?.noOfPanelPerString} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="NO. OF STRING(S)" value={pvPanelReqObj?.noOfStrings} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PANEL ORIENTATION" value={pvPanelReqObj?.panelOrientation} className="col-md-3 col-sm-3" />
            <ReadOnlyField
              label="PV PANEL DATE OF PURCHASE"
              value={pvPanelReqObj?.pvPanelPurchaseDate ? <Moment format="DD/MM/YYYY">{pvPanelReqObj.pvPanelPurchaseDate}</Moment> : "NA"}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField
              label="INSATALLATION COMMISSIONING DATE"
              value={
                pvPanelReqObj?.installactionCommissioningDate ? (
                  <Moment format="DD/MM/YYYY">{pvPanelReqObj.installactionCommissioningDate}</Moment>
                ) : (
                  "NA"
                )
              }
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="INVERTER BRAND" value={pvPanelReqObj?.inverterBrand} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER MODAL" value={pvPanelReqObj?.inverterModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER OUTPUT(AC)" value={pvPanelReqObj?.inverterOutput} className="col-md-3 col-sm-3" />
            <ReadOnlyField
              label="INVERTER PURCHASE DATE"
              value={pvPanelReqObj?.inverterPurchaseDate ? <Moment format="DD/MM/YYYY">{pvPanelReqObj.inverterPurchaseDate}</Moment> : "NA"}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="INVERTER QUANTITY" value={pvPanelReqObj?.inverterQuantity} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PV BATEERY CONNECTED(Y/N)" value={pvPanelReqObj?.pvBatteryConnected} className="col-md-3 col-sm-3" />
          </div>
        </div>
      </>
    );
  };

  // pv Inverter View Mode
  const pvInverterViewDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3b1 PV INVERTER</h5>
          <div className="row mt-3">
            <ReadOnlyField
              label="DATE OF PURCHASE"
              value={pvInversterReqObj?.dateofPurchase ? <Moment format="DD/MM/YYYY">{pvInversterReqObj.dateofPurchase}</Moment> : "NA"}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField
              label="INSATALLATION COMMISSIONING DATE"
              value={
                pvInversterReqObj?.installactionCommissioningDate ? (
                  <Moment format="DD/MM/YYYY">{pvInversterReqObj.installactionCommissioningDate}</Moment>
                ) : (
                  "NA"
                )
              }
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="INVERTER BRAND" value={pvInversterReqObj?.inverterBrand} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER MODAL" value={pvInversterReqObj?.inverterModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER P/N (if ABB-Fimer)" value={pvInversterReqObj?.inverterPN} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER WK NUMBER (if ABB-Fimer)" value={pvInversterReqObj?.inverterWKNumber} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER FIRMWARE VERSION" value={pvInversterReqObj?.inverterFirmwareVersion} className="col-md-3 col-sm-3" />
            <ReadOnlyField
              label="INVERTER INSTALLED INDOOR/OUTDOOR(Y/N)?"
              value={pvInversterReqObj?.inverterInstalled}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="WiFi CARD, DATA LOGGER INSTALLED(Y/N)?" value={pvInversterReqObj?.wifiCard} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="ANY SHIELD ON TOP OF INVERTER(Y/N)?" value={pvInversterReqObj?.shieldOnTop} className="col-md-3 col-sm-3" />
            <ReadOnlyField
              label="DISTANCE FROM INVERTER BASE TO GROUND"
              value={pvInversterReqObj?.distanceBaseToGround}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField
              label="IF CONNECTED FOR MONITORING, SITE NAME/ID LOGIN DETAILS"
              value={pvInversterReqObj?.siteName}
              className="col-md-6 col-sm-6"
            />
            <ReadOnlyField
              label="ISTANCE FROM INVERTER TO NEAREST OBSTACLE IS <= 0.6 m (Y/N)?"
              value={pvInversterReqObj?.distanceInverterToNearestObstacle}
              className="col-md-6 col-sm-6"
            />
            <ReadOnlyField
              label="DISTANCE FROM INVERTER TO NEAREST INVERTER, IF MORE THAN 1 INVERTER IS <= 1.2 m (Y/N)?"
              value={pvInversterReqObj?.distanceInverterToNearestInverter}
              className="col-md-3 col-sm-3"
            />
          </div>
          <h5 className="my-2 font-size-500">ADDITIONAL HYBRID INVERTER INFO ONLY</h5>
          <div className="row mt-3">
            <ReadOnlyField label="PV PANEL BRAND" value={pvInversterReqObj?.pvPanelBrand} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PV PANEL MODEL" value={pvInversterReqObj?.pvPanelModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="Qty. OF PV PANEL MODEL PER MPPT" value={pvInversterReqObj?.qtyPvPanel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="Qty. OF MPPT CONNECTED TO INVERTER" value={pvInversterReqObj?.qtyMPPTConnected} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="BATTERY PACK BRAND" value={pvInversterReqObj?.batteryPackBrand} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="POWER OF BACK-UP LOAD" value={pvInversterReqObj?.powerBackUpLoad} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="TYPE OF BACK-UP LOAD" value={pvInversterReqObj?.typeOfBackUpLoad} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="WORK MODE FROM PV MASTER APP" value={pvInversterReqObj?.workModelPvMaster} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="METER TYPE (GoodWe, SolarEdge or CE25)" value={pvInversterReqObj?.meterType} className="col-md-3 col-sm-3" />
          </div>
        </div>
      </>
    );
  };

  // pv optimiser View Mode
  const pvOptimiserViewDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3b2 PV OPTIMISER</h5>
          <div className="row mt-3">
            <ReadOnlyField
              label="DATE OF PURCHASE"
              value={pvOptimiserReqObj?.pvPanelPurchaseDate ? <Moment format="DD/MM/YYYY">{pvOptimiserReqObj.pvPanelPurchaseDate}</Moment> : "NA"}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField
              label="INSATALLATION COMMISSIONING DATE"
              value={
                pvOptimiserReqObj?.installactionCommissioningDate ? (
                  <Moment format="DD/MM/YYYY">{pvOptimiserReqObj.installactionCommissioningDate}</Moment>
                ) : (
                  "NA"
                )
              }
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="OPTIMISER BRAND" value={pvOptimiserReqObj?.optimiserBrand} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="MODEL or P/N" value={pvOptimiserReqObj?.modelOrPN} className="col-md-3 col-sm-3" />
          </div>
        </div>
      </>
    );
  };

  // pv Battery View Mode
  const pvBatteryViewDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3c PV BATTERY</h5>
          <div className="row mt-3">
            <ReadOnlyField label="BATTERY MODEL" value={pvBatteryReqObj?.batteryModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="BATTERY P/N" value={pvBatteryReqObj?.batteryPN} className="col-md-3 col-sm-3" />
            <ReadOnlyField
              label="INSTALLATION TYPE (Off Grid or On Grid with backup)"
              value={pvBatteryReqObj?.installationType}
              className="col-md-6 col-sm-6"
            />
            <ReadOnlyField
              label="IS SYSTEM CONNECTED TO THE INTERNET(Y/N)?"
              value={pvBatteryReqObj?.isSystemConnected}
              className="col-md-6 col-sm-6"
            />
            <ReadOnlyField
              label="INSATALLATION COMMISSIONING DATE"
              value={
                pvBatteryReqObj?.installactionCommissioningDate ? (
                  <Moment format="DD/MM/YYYY">{pvBatteryReqObj.installactionCommissioningDate}</Moment>
                ) : (
                  "NA"
                )
              }
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField
              label="PV BATTERY DATE OF PURCHASE"
              value={pvBatteryReqObj?.pvBatteryDateOfPurchase ? <Moment format="DD/MM/YYYY">{pvBatteryReqObj.pvBatteryDateOfPurchase}</Moment> : "NA"}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="INVERTER BRAND" value={pvBatteryReqObj?.inverterBrand} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER MODEL" value={pvBatteryReqObj?.inverterModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="INVERTER FIRMWARE VERSION" value={pvBatteryReqObj?.inverterFirmwareVersion} className="col-md-3 col-sm-3" />
          </div>
        </div>
      </>
    );
  };

  // pv Other View Mode
  const pvOtherViewDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3d PV OTHER: RAILS,RACKING, SOLAHART GATEWAY, BOS KITS, LABELS</h5>
          <div className="row mt-3">
            <ReadOnlyField
              label="DATE OF PURCHASE"
              value={pvOtherReqObj?.dateofPurchase ? <Moment format="DD/MM/YYYY">{pvOtherReqObj.dateofPurchase}</Moment> : "NA"}
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField
              label="INSATALLATION COMMISSIONING DATE"
              value={
                pvOtherReqObj?.installactionCommissioningDate ? (
                  <Moment format="DD/MM/YYYY">{pvOtherReqObj.installactionCommissioningDate}</Moment>
                ) : (
                  "NA"
                )
              }
              className="col-md-3 col-sm-3"
            />
            <ReadOnlyField label="COMPONENT MODEL or P/N" value={pvOtherReqObj?.componentModel} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="PV SYSTEM SIZE(kW)" value={pvOtherReqObj?.pvSystemSize} className="col-md-3 col-sm-3" />
            <ReadOnlyField label="ROOF SHEET MATERIAL TYPE" value={pvOtherReqObj?.roofSheetMaterialType} className="col-md-6 col-sm-6" />
            <ReadOnlyField
              label="CORROSIVE CATEGORY OF INSTALLATION SITE (C1 to C5)"
              value={pvOtherReqObj?.corrosiveType}
              className="col-md-6 col-sm-6"
            />
          </div>
        </div>
      </>
    );
  };

  // pv Panel Edit Mode
  const pvPanelEditDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">APPLIANCE - PV PRODUCT INFORMATION - PV PANEL</h5>
          <div className="row input-fields mt-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PV PANEL MODEL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="pvPanelModal"
                  placeholder="PV Panel Modal"
                  value={pvPanelReqObj?.pvPanelModal}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PV PANEL P/N</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="pvPanelPN"
                  placeholder="PV Panel P/N"
                  value={pvPanelReqObj?.pvPanelPN}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SYSTEM SIZE(DC)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="systemSize"
                  placeholder="System Size"
                  value={pvPanelReqObj?.systemSize}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">NO. OF PANELS IN SYSTEM</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="noOfPanelSystem"
                  placeholder="No. of Panels in system"
                  value={pvPanelReqObj?.noOfPanelSystem}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">NO. OF PANELS PER STRING(S)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="noOfPanelPerString"
                  placeholder="No. of Panels per string"
                  value={pvPanelReqObj?.noOfPanelPerString}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">NO. OF STRING(S)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="noOfStrings"
                  placeholder="No. of String"
                  value={pvPanelReqObj?.noOfStrings}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PANEL ORIENTATION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="panelOrientation"
                  placeholder="Panel Orientation"
                  value={pvPanelReqObj?.panelOrientation}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">PV PANEL DATE OF PURCHASE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvPanelReqObj?.pvPanelPurchaseDate}
                      onChange={(e) => handlePvPanelSelectValueChange("pvPanelPurchaseDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">INSATALLATION COMMISSIONING DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvPanelReqObj?.installactionCommissioningDate}
                      onChange={(e) => handlePvPanelSelectValueChange("installactionCommissioningDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER BRAND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterBrand"
                  placeholder="Inverter Brand"
                  value={pvPanelReqObj?.inverterBrand}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER MODAL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterModel"
                  placeholder="Inverter Modal"
                  value={pvPanelReqObj?.inverterModel}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER OUTPUT(AC)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterOutput"
                  placeholder="Inverter AC"
                  value={pvPanelReqObj?.inverterOutput}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">INVERTER PURCHASE DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvPanelReqObj?.inverterPurchaseDate}
                      onChange={(e) => handlePvPanelSelectValueChange("inverterPurchaseDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER QUANTITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterQuantity"
                  placeholder="Inverter Quantity"
                  value={pvPanelReqObj?.inverterQuantity}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PV BATEERY CONNECTED(Y/N)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="pvBatteryConnected"
                  placeholder="Yes/No"
                  value={pvPanelReqObj?.pvBatteryConnected}
                  onChange={handlePvPanelInputValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // pv Inverter Edit Mode
  const pvInverterEditDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3b1 PV INVERTER</h5>
          <div className="row input-fields mt-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">DATE OF PURCHASE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvInversterReqObj?.dateofPurchase}
                      onChange={(e) => handlePvInverterSelectChange("dateofPurchase", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className="mr-2">INSTALLATION-COMMISSIONING DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvInversterReqObj?.installactionCommissioningDate}
                      onChange={(e) => handlePvInverterSelectChange("installactionCommissioningDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER BRAND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterBrand"
                  placeholder="Inverter Brand"
                  value={pvInversterReqObj?.inverterBrand}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER MODEL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterModel"
                  placeholder="Inverter Model"
                  value={pvInversterReqObj?.inverterBrand}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER P/N (if ABB-Fimer)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterPN"
                  placeholder="Inverter P/N"
                  value={pvInversterReqObj?.inverterPN}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER WK NUMBER (if ABB-Fimer)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterWKNumber"
                  placeholder="Inverter WK Number"
                  value={pvInversterReqObj?.inverterWKNumber}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER FIRMWARE VERSION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterFirmwareVersion"
                  placeholder="Inverter Firmware Version"
                  value={pvInversterReqObj?.inverterFirmwareVersion}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER INSTALLED INDOOR/OUTDOOR</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterInstalled"
                  placeholder="Inverter Installed Indoor/Outdoor"
                  value={pvInversterReqObj?.inverterInstalled}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">WiFi CARD, DATA LOGGER INSTALLED(Y/N)?</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="wifiCard"
                  placeholder="Yes/No"
                  value={pvInversterReqObj?.wifiCard}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ANY SHIELD ON TOP OF INVERTER(Y/N)?</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="shieldOnTop"
                  placeholder="Yes/No"
                  value={pvInversterReqObj?.shieldOnTop}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DISTANCE FROM INVERTER BASE TO GROUND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="distanceBaseToGround"
                  placeholder="Inverter Brand"
                  value={pvInversterReqObj?.distanceBaseToGround}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">IF CONNECTED FOR MONITORING, SITE NAME/ID LOGIN DETAILS</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="siteName"
                  placeholder="Login Details"
                  value={pvInversterReqObj?.siteName}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  DISTANCE FROM INVERTER TO NEAREST OBSTACLE IS {"<="} 0.6 m (Y/N)?
                </label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="distanceInverterToNearestObstacle"
                  placeholder="Nearest Obstacle Disatance"
                  value={pvPanelReqObj?.distanceInverterToNearestObstacle}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  DISTANCE FROM INVERTER TO NEAREST INVERTER, IF MORE THAN 1 INVERTER IS {"<="} 1.2 m (Y/N)?
                </label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="distanceInverterToNearestInverter"
                  placeholder="Nearest Inverter Disatance"
                  value={pvPanelReqObj?.distanceInverterToNearestInverter}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
          </div>
          <h6 className="my-2 font-weight-500">ADDITIONAL HYBRID INVERTER INFO ONLY</h6>
          <div className="row input-fields mt-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PV PANEL BRAND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="pvPanelBrand"
                  placeholder="Panel Brand"
                  value={pvPanelReqObj?.pvPanelBrand}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PV PANEL MODEL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="pvPanelModel"
                  placeholder="Panel Model"
                  value={pvPanelReqObj?.pvPanelModel}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Qty. OF PV PANEL MODEL PER MPPT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="qtyPvPanel"
                  placeholder="PV Panel Quantity"
                  value={pvPanelReqObj?.qtyPvPanel}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Qty. OF MPPT CONNECTED TO INVERTER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="qtyMPPTConnected"
                  placeholder="MPPT Connected Quantity"
                  value={pvPanelReqObj?.qtyMPPTConnected}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">BATTERY PACK BRAND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="batteryPackBrand"
                  placeholder="Battery Pack Brand"
                  value={pvPanelReqObj?.batteryPackBrand}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">POWER OF BACK-UP LOAD</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="powerBackUpLoad"
                  placeholder="Power Back up Load"
                  value={pvPanelReqObj?.powerBackUpLoad}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">TYPE OF BACK-UP LOAD</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="typeOfBackUpLoad"
                  placeholder="Type Back up Load"
                  value={pvPanelReqObj?.typeOfBackUpLoad}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">WORK MODE FROM PV MASTER APP</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="workModelPvMaster"
                  placeholder="Work Mode"
                  value={pvPanelReqObj?.workModelPvMaster}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">METER TYPE (GoodWe, SolarEdge or CE25)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="meterType"
                  placeholder="Meter Type"
                  value={pvPanelReqObj?.meterType}
                  onChange={handlePvInverterValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // pv optimiser Edit Mode
  const pvOptimiserEditDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3b2 PV OPTIMISER</h5>
          <div className="row input-fields mt-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">DATE OF PURCHASE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvOptimiserReqObj?.dateofPurchase}
                      onChange={(e) => handlePvOptimiserSelectChange("dateofPurchase", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className="mr-2">INSTALLATION-COMMISSIONING DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvOptimiserReqObj?.installactionCommissioningDate}
                      onChange={(e) => handlePvOptimiserSelectChange("installactionCommissioningDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">OPTIMISER BRAND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="optimiserBrand"
                  placeholder="Optimiser Brand"
                  value={pvOptimiserReqObj?.optimiserBrand}
                  onChange={handlePvOptimiserValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">MODEL or P/N</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="modelOrPN"
                  placeholder="Model or P/N"
                  value={pvOptimiserReqObj?.modelOrPN}
                  onChange={handlePvOptimiserValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // pv Battery Edit Mode
  const pvBatteryEditDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3c PV BATTERY</h5>
          <div className="row input-fields mt-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">BATTERY MODEL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="batteryModel"
                  placeholder="Battery Model"
                  value={pvBatteryReqObj?.batteryModel}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">BATTERY P/N</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="batteryPN"
                  placeholder="Battery P/N"
                  value={pvBatteryReqObj?.batteryPN}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INSTALLATION TYPE (Off Grid or On Grid with backup)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="installationType"
                  placeholder="Installation Type"
                  value={pvBatteryReqObj?.installationType}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">IS SYSTEM CONNECTED TO THE INTERNET(Y/N)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="isSystemConnected"
                  placeholder="Yes/No"
                  value={pvBatteryReqObj?.isSystemConnected}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className="mr-2">INSTALLATION-COMMISSIONING DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvBatteryReqObj?.installactionCommissioningDate}
                      onChange={(e) => handlePvBatterySelectChange("installactionCommissioningDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">PV BATTERY DATE OF PURCHASE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvBatteryReqObj?.pvBatteryDateOfPurchase}
                      onChange={(e) => handlePvBatterySelectChange("pvBatteryDateOfPurchase", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER BRAND</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterBrand"
                  placeholder="Inverter Brand"
                  value={pvBatteryReqObj?.inverterBrand}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER MODEL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterModel"
                  placeholder="Inverter Model"
                  value={pvBatteryReqObj?.inverterModel}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVERTER FIRMWARE VERSION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="inverterFirmwareVersion"
                  placeholder="Inverter Firmware Version"
                  value={pvBatteryReqObj?.inverterFirmwareVersion}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // pv Other Edit Mode
  const pvOtherEditDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
          <h5 className="my-2">3d PV OTHER: RAILS,RACKING, SOLAHART GATEWAY, BOS KITS, LABELS</h5>
          <div className="row input-fields mt-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">DATE OF PURCHASE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvOtherReqObj?.dateofPurchase}
                      onChange={(e) => handlePvOtherSelectChange("dateofPurchase", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className="mr-2">INSTALLATION-COMMISSIONING DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={pvOtherReqObj?.installactionCommissioningDate}
                      onChange={(e) => handlePvOtherSelectChange("installactionCommissioningDate", e)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">COMPONENT MODEL or P/N</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="componentModel"
                  placeholder="Component Model or P/N"
                  value={pvOtherReqObj?.componentModel}
                  onChange={handlePvOtherValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PV SYSTEM SIZE(kW)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="pvSystemSize"
                  placeholder="System Size"
                  value={pvOtherReqObj?.pvSystemSize}
                  onChange={handlePvOtherValueChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ROOF SHEET MATERIAL TYPE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="roofSheetMaterialType"
                  placeholder="Matterial Type"
                  value={pvBatteryReqObj?.roofSheetMaterialType}
                  onChange={handlePvOtherValueChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CORROSIVE CATEGORY OF INSTALLATION SITE (C1 to C5)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="corrosiveType"
                  placeholder="Corrosive Type"
                  value={pvBatteryReqObj?.corrosiveType}
                  onChange={handlePvBatteryValueChange}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {assesstTabViewOnly || claimStatus?.value === "CLAIM_SUBMITTED" || claimStatus?.value === "ARCHIVED" ? (
        <>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row mt-3">
              <ReadOnlyField
                label="ASSESSMENT DATE"
                value={assesstementData.assessmentDate ? <Moment format="DD/MM/YYYY">{assesstementData.assessmentDate}</Moment> : "NA"}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField label="CLAIM CATEGORY" value={assessClaimCategory?.label} className="col-md-4 col-sm-4" />
              <ReadOnlyField
                label={
                  (assessClaimCategory === "" ? "CATEGORY" : assessClaimCategory?.value === "" ? "CATEGORY" : assessClaimCategory?.value) + " TYPE"
                }
                value={assessCategoryType?.label}
                className="col-md-4 col-sm-4"
              />
            </div>
          </div>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row mt-3">
              <ReadOnlyField
                label="IS THE MACHINE OR COMPONENT UNDER WARRANTY?"
                value={assesstementData.machineUnderWarranty?.label}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField
                label="WHAT TYPE OF WARRANTY ASSESSMENT IS IT?"
                value={assesstementData.assessmentType?.label}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField label="WHAT IS THE CUSTOMER COMPLAINING ABOUT?" value={assesstementData.complainRow1} className="col-md-12 col-sm-12" />
              <ReadOnlyField label="" value={assesstementData.complainRow2} className="col-md-12 col-sm-12" />
              <ReadOnlyField label="" value={assesstementData.complainRow2} className="col-md-12 col-sm-12" />
            </div>
          </div>
          {assessClaimCategory?.value === "PV_PANEL" && pvPanelViewDetails()}
          {assessClaimCategory?.value === "PV_INVERTER" && pvInverterViewDetails()}
          {assessClaimCategory?.value === "PV_OPTIMISER" && pvOptimiserViewDetails()}
          {assessClaimCategory?.value === "PV_BATTERY" && pvBatteryViewDetails()}
          {assessClaimCategory?.value === "PV_OTHER" && pvOtherViewDetails()}

          <div className="row px-3" style={{ justifyContent: "right" }}>
            <button type="button" className="btn bg-primary text-white" onClick={handleAddUpdateAssesstment}>
              Next
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row input-fields mt-2">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    <span className=" mr-2">ASSESSMENT DATE</span>
                  </label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={assesstementData.assessmentDate}
                        onChange={(e) => handleAssesstementSelectDataChange(e, "assessmentDate")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM CATEGORY</label>
                  <Select
                    className="text-primary"
                    options={assessmentCategoryType}
                    value={assessCategoryType}
                    onChange={handleCategoryTypeChange}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    {assessCategoryType === "" ? "CATEGORY" : assessCategoryType?.value} TYPE
                  </label>
                  <Select
                    className="text-primary"
                    options={assessmentClaimCategory}
                    value={assessClaimCategory}
                    onChange={(e) => setAssessClaimCategory(e)}
                    styles={FONT_STYLE_SELECT}
                    isDisabled={assessCategoryType === "" || assessCategoryType?.value === "EQUIPMENT" ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row input-fields mt-2">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">IS THE MACHINE OR COMPONENT UNDER WARRANTY?</label>
                  <Select
                    className="text-primary"
                    options={underWarrantyOptions}
                    value={assesstementData.machineUnderWarranty}
                    onChange={(e) => handleAssesstementSelectDataChange(e, "machineUnderWarranty")}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WHAT TYPE OF WARRANTY ASSESSMENT IS IT?</label>
                  <Select
                    className="text-primary"
                    options={warrantyTypeOptions}
                    onChange={(e) => handleAssesstementSelectDataChange(e, "assessmentType")}
                    value={assesstementData.assessmentType}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WHAT IS THE CUSTOMER COMPLAINING ABOUT?</label>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={assesstementData.complainRow1}
                        name="complainRow1"
                        placeholder="Complaining About the...."
                        onChange={handleAssesstementDataChange}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={assesstementData.complainRow2}
                        name="complainRow2"
                        placeholder="Complaining About the...."
                        onChange={handleAssesstementDataChange}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={assesstementData.complainRow3}
                        name="complainRow3"
                        placeholder="Complaining About the...."
                        onChange={handleAssesstementDataChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {assessClaimCategory?.value === "PV_PANEL" && pvPanelEditDetails()}

          {assessClaimCategory?.value === "PV_INVERTER" && pvInverterEditDetails()}
          {assessClaimCategory?.value === "PV_OPTIMISER" && pvOptimiserEditDetails()}
          {assessClaimCategory?.value === "PV_BATTERY" && pvBatteryEditDetails()}
          {assessClaimCategory?.value === "PV_OTHER" && pvOtherEditDetails()}

          <div className="row mx-1" style={{ justifyContent: "right" }}>
            <button type="button" className="btn btn-light bg-primary text-white" onClick={handleAddUpdateAssesstment}>
              Save & Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AssessmentDetails;
