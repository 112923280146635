import React, { useState } from "react";

import deleteIcon from "../../../assets/icons/svg/delete.svg";
import copyIcon from "../../../assets/icons/svg/Copy.svg";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";
import { FONT_STYLE, FONT_STYLE_SELECT, GRID_STYLE } from "pages/Common/constants";
import { TextField, Tooltip } from "@mui/material";
import Select from "react-select";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { claimVersionOptions } from "../warrantyManagementConstants";

const SupplierClaimProcessDetails = ({ show, hideModal, handleSnack, currencyKeyValuePair }) => {
  const [activeTab, setActiveTab] = useState("partnerRepairDetails");

  const [rowModesModel, setRowModesModel] = useState({});
  const [claimOrderVersion, setClaimOrderVersion] = useState(claimVersionOptions[0]);

  // change claim request active tab
  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  const itemInforColumns = [
    {
      field: "material",
      headerName: "Material",
      flex: 1,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
    },
    {
      field: "partsCausingDamage",
      headerName: "Parts Causing Damage",
      flex: 1,
      editable: true,
    },
    {
      field: "returnPartStatus",
      headerName: "Return Part Status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Recieved", value: "RECIEVED" },
        { label: "Wrong Part", value: "WRONG_PART" },
        { label: "Part Missing", value: "PART_MISSING" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.relatedPartsId]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              //   onClick={handleSaveClick(row.relatedPartsId)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              //   onClick={handleCancelClick(row.relatedPartsId)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            // onClick={handleEditClick(row.relatedPartsId)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            // onClick={handleDeleteClick(row.relatedPartsId)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleSaveAndNext = (e) => {
    const { id } = e.target;
    if (id === "partnersAndRepair") {
      setActiveTab("additionalDetails");
    } else if (id === "additionalDetails") {
      setActiveTab("itemInformation");
    } else if (id === "itemInformation") {
      hideModal();
      //   setActiveTab("");
    }
  };

  const partnerAndRepairDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2">
          <div className="row input-fields mt-2">
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SOURCE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="source"
                  placeholder="Source"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="supplier"
                  placeholder="Supplier Name"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">EQUIPMENT/PART NUMBER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="partNumber"
                  placeholder="Equipment Number"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">DAMAGE DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      //   // maxDate={new Date()}
                      closeOnSelect
                      //   value={assesstementData.assessmentDate}
                      //   onChange={(e) => handleAssesstementSelectDataChange(e, "assessmentDate")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            style: FONT_STYLE,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">REPAIR DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      //   // maxDate={new Date()}
                      closeOnSelect
                      //   value={assesstementData.assessmentDate}
                      //   onChange={(e) => handleAssesstementSelectDataChange(e, "assessmentDate")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            style: FONT_STYLE,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DECISION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="decision"
                  placeholder="Decision"
                />
              </div>
            </div>
          </div>
          <div className={`row mb-2 mx-0`} style={{ justifyContent: "right" }}>
            <button type="button" className="btn bg-primary text-white mx-0" id="partnersAndRepair" onClick={handleSaveAndNext}>
              Save & Next
            </button>
          </div>
        </div>
      </>
    );
  };

  const additionalDetails = () => {
    return (
      <>
        <div className="card border px-3 py-2">
          <div className="row input-fields mt-2">
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PLANT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="plant"
                  placeholder="Plant"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PURCHASING ORGANIZATION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="purchasingOrganization"
                  placeholder="Purchasing Organization Name"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DISTRIBUTION CHANNEL</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="distributinChannel"
                  placeholder="Distribution Channel"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DIVISION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  //   value={warrantyId}
                  name="division"
                  placeholder="Division"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                <Select
                  className="text-primary"
                  options={currencyKeyValuePair}
                  //   value={assesstementData.machineUnderWarranty}
                  //   onChange={(e) => handleAssesstementSelectDataChange(e, "machineUnderWarranty")}
                  styles={FONT_STYLE_SELECT}
                />
              </div>
            </div>
          </div>
          <div className={`row mb-2 mx-0`} style={{ justifyContent: "right" }}>
            <button type="button" className="btn bg-primary text-white mx-0" id="additionalDetails" onClick={handleSaveAndNext}>
              Save & Next
            </button>
          </div>
        </div>
      </>
    );
  };

  const itemInformation = () => {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            sx={GRID_STYLE}
            rows={[]}
            columns={itemInforColumns}
            autoHeight
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            getRowId={(row) => row.id}
          />
        </Box>
        <div className={`row mt-2 mx-0`} style={{ justifyContent: "right" }}>
          <button type="button" className="btn bg-primary text-white mx-0" id="itemInformation" onClick={handleSaveAndNext}>
            Save & Close
          </button>
        </div>
      </>
    );
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <div className="card border px-3 py-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="font-weight-600 mb-0">Supplier Claim - 29088</h5>
              <div className="d-flex justify-content-center align-items-center">
                <div className="ml-3">
                  <span class="claim-version-span">{claimOrderVersion?.label}</span>
                  {/* <Select
                                            value={claimOrderVersion}
                                            onChange={(e) =>
                                                setClaimOrderVersion(e)
                                            }
                                            className="claim-order-version"
                                            options={claimVersionOptions}
                                            classNamePrefix="claim-version-select"
                                            // isDisabled
                                        /> */}
                </div>
                <div className="ml-3">
                  <Select
                    // value={claimStatus}
                    // onChange={(e) => {
                    //   setClaimStatus(e);
                    //   handleSaveClaimOrderChanges({
                    //     claimOrderStatus: e?.value,
                    //   });
                    // }}
                    className="custom-claimStatus-selectbtn"
                    options={[]}
                    // isDisabled={claimStatus?.value === "SETTLED"}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <span className={`px-3 py-1 mx-2 settlement-status`}>Settlement Closed</span>
                {/* <Select
                  //   value={settledClaimType}
                  //   onChange={(e) => setSettledClaimType(e)}
                  className="custom-claimStatus-selectbtn"
                  options={[]}
                /> */}
              </div>
              {/* <div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        <IconButton
                                            className="btn bg-primary text-white font-size-14 pr-0 ml-2"
                                            style={{ borderRadius: "5px" }}
                                            onClick={handleOpenActionMenu}
                                            disabled={activeTopTab !== "settlement"}
                                            size="small"
                                            aria-controls={openActionMenu ? "account-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openActionMenu ? "true" : undefined}
                                        >
                                            <span className="convert mx-2">
                                                Action{" "}
                                                <span>
                                                    <KeyboardArrowDownIcon />
                                                </span>
                                            </span>
                                        </IconButton>
                                    </Box>
                                    <Menu
                                        className="convert-top-left"
                                        anchorEl={actionMenuAnchorEl}
                                        id="account-menu"
                                        open={openActionMenu}
                                        onClose={handleCloseActionMenu}
                                        onClick={handleCloseActionMenu}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                "&:before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{
                                            horizontal: "right",
                                            vertical: "top",
                                        }}
                                        anchorOrigin={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                        }}
                                    >
                                        {!(
                                            claimStatus?.value === "REGISTERED" ||
                                            claimStatus?.value === "CLAIM_SUBMITTED" ||
                                            claimStatus?.value === "CLAIM_ACKNOWLEDGED" ||
                                            claimStatus?.value === "CLAIM_ACCEPTED" ||
                                            claimStatus?.value === "CONTESTED"
                                        ) && (
                                            <MenuItem
                                                className="custommenu"
                                                // onClick={() =>
                                                //     setConvertToQuoteModalShow(true)
                                                // }
                                            >
                                                Accept
                                            </MenuItem>
                                        )}
                                        {(claimStatus?.value === "REGISTERED" ||
                                            claimStatus?.value === "CLAIM_SUBMITTED" ||
                                            claimStatus?.value === "CLAIM_ACKNOWLEDGED" ||
                                            claimStatus?.value === "CLAIM_ACCEPTED" ||
                                            claimStatus?.value === "CONTESTED") && (
                                            <MenuItem
                                                className="custommenu"
                                                // onClick={() =>
                                                //     setConvertToQuoteModalShow(true)
                                                // }
                                            >
                                                Cancel
                                            </MenuItem>
                                        )}
                                        {!(
                                            claimStatus?.value === "REGISTERED" ||
                                            claimStatus?.value === "CLAIM_SUBMITTED" ||
                                            claimStatus?.value === "CLAIM_ACKNOWLEDGED" ||
                                            claimStatus?.value === "CLAIM_ACCEPTED" ||
                                            claimStatus?.value === "CONTESTED"
                                        ) && (
                                            <MenuItem
                                                className="custommenu"
                                                // onClick={() =>
                                                //     setConvertToQuoteModalShow(true)
                                                // }
                                            >
                                                Contest
                                            </MenuItem>
                                        )}
                                        <Divider />
                                    </Menu>
                                </div> */}
              <div className="d-flex justify-content-center align-items-center">
                {/* <a className="ml-3 cursor">
                                        <Tooltip title="Notes">
                                            <DescriptionOutlinedIcon className="text-grey font-size-28" />
                                        </Tooltip>
                                    </a> */}
                <a href="#" className="ml-3 font-size-14" title="Delete">
                  <Tooltip title="Delete">
                    <img src={deleteIcon}></img>
                  </Tooltip>
                </a>
                <a href={undefined} className="ml-3 font-size-14" title="Copy">
                  <Tooltip title="Copy">
                    <img src={copyIcon}></img>
                  </Tooltip>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={`card border my-2 px-3`}>
          <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList className="custom-tabs-div" onChange={handleTabChange}>
                  <Tab label="PARTNER & REPAIR DETAILS" value="partnerRepairDetails" />
                  <Tab label="ADDITIONAL DETAILS" value="additionalDetails" />
                  <Tab label="ITEM INFORMATION " value="itemInformation" />
                </TabList>
              </Box>
              <TabPanel value="partnerRepairDetails">{partnerAndRepairDetails()}</TabPanel>
              <TabPanel value="additionalDetails">{additionalDetails()}</TabPanel>
              <TabPanel value="itemInformation">{itemInformation()}</TabPanel>
            </TabContext>
          </Box>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SupplierClaimProcessDetails;
