import React, { useState } from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Modal } from "react-bootstrap";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const ClaimErrorsModal = ({ show, hideModal }) => {
    const [tabValue, setTabValue] = useState("1");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <Modal show={show} onHide={hideModal} className="right fade" closeButton>
            <Modal.Header>
                <Modal.Title>
                    <ErrorOutlineIcon className="mr-2" style={{ fontSize: "32px" }} />
                    Errors
                </Modal.Title>
                <button type="button" className="close" onClick={hideModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className=" mt-2">
                    <h6 className="px-3">FILTER</h6>
                    <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tabValue}>
                            <Box className="custom-tabs" sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList className="custom-tabs-div" onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label="Parts" value="1" />
                                    <Tab label="Labor" value="2" />
                                    <Tab label="Expenses" value="3" />
                                    <Tab label="Others" value="4" />
                                </TabList>
                            </Box>
                            <TabPanel className="px-3" value="1">
                                <div className="card border p-3 mb-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0">Part number is not correct</h6>
                                        <p className="mb-0">Now</p>
                                    </div>
                                    {/* <p className="mb-0"> Part list header component code</p> */}
                                    <p className="mb-0">
                                        Fix{" "}
                                        <a href="#" className="btn">
                                            Go to field
                                        </a>
                                    </p>
                                </div>
                                <div className="card border p-3 mb-0 mt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0">Parts price in not updated</h6>
                                        <p className="mb-0">Now</p>
                                    </div>
                                    {/* <p className="mb-0"> Part number is missing</p> */}
                                    <p className="mb-0">
                                        Fix{" "}
                                        <a href="#" className="btn">
                                            Go to field
                                        </a>
                                    </p>
                                </div>
                                <div className="card border p-3 mb-0 mt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0">Part number is not correct</h6>
                                        <p className="mb-0">Now</p>
                                    </div>
                                    {/* <p className="mb-0 font-size-10"> Part list header component code</p> */}
                                    <p className="mb-0">
                                        Fix{" "}
                                        <a href="#" className="btn">
                                            Go to field
                                        </a>
                                    </p>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ClaimErrorsModal;
