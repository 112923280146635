import React, { useEffect, useState } from "react";

import { Divider, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Moment from "react-moment";
import Select from "react-select";
import { Modal } from "react-bootstrap";

import { deliveryTypeOptions, plantByOptions, sippingConditionOptions, stoReqQObj } from "../warehouseConstents";
import { FONT_STYLE, GRID_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { SHIPMENT_HEADER_MASTER_URL, SHIPMENT_PARTS_MASTER_URL } from "services/CONSTANTS";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import LoadingProgress from "pages/Repair/components/Loader";

const AddUpdateSTO = ({ show, hideModal, handleSnack, recordId = null, partsOrderObj, pendingParts = [] }) => {
  const [stoRecordObj, setStoRecordObj] = useState({ ...stoReqQObj, orderNumber: partsOrderObj?.partsOrderName || "" });
  const [shipmentHeaderId, setShipmentHeaderId] = useState(null);
  const [viewModeOn, setViewModeOn] = useState(false);

  const [loading, setLoading] = useState(false);

  const [shipmentItemsData, setShipmentItemsData] = useState([...pendingParts]);

  useEffect(() => {
    if (recordId) {
      getSTODetails(recordId);
    }
  }, [recordId]);

  // get the sto details |Shipment header details
  const getSTODetails = async (recordId) => {
    setViewModeOn(true);
    setLoading(true);
    const rUrl = `${SHIPMENT_HEADER_MASTER_URL}/${recordId}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          const _stoNumber = responseData.returnNumber ? "SO" + responseData["returnNumber"].substring(2) : "";

          const _orderNumber = responseData.returnNumber ? "SO" + responseData["returnNumber"].substring(2) : "";

          const _shippingCondition = sippingConditionOptions.find((item) => item.value === responseData?.returnType);

          // const _deliveryType = deliveryTypeOptions.find((item) => item.value === responseData?.rmaType);

          setStoRecordObj({
            ...responseData,
            stoNumber: _stoNumber,
            // orderNumber: _orderNumber,
            orderNumber: responseData.rmaType,
            referenceDescription: responseData?.rmaReason,
            referenceId: responseData?.rmaNumber,
            // deliveryType: _deliveryType || deliveryTypeOptions[0],
            deliveryType: deliveryTypeOptions[0],
            shippingCondition: _shippingCondition || "",
            senderPlant: plantByOptions[0],
            senderStorageLocation: responseData?.senderLocation,
            receiverPlant: plantByOptions[1],
          });

          if (responseData.shipmentParts.length > 0) {
            getShipmentPartsByIds(responseData.shipmentParts);
          }
          setShipmentHeaderId(responseData.shipmentHeaderId);
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        handleSnack("error", "Error fetching STO details");
      }
    );
  };

  // get shippment parts details
  const getShipmentPartsByIds = (partsIds = []) => {
    const rUrl = `${SHIPMENT_PARTS_MASTER_URL}/by-ids?` + partsIds.map((id) => `ids=${id}`).join("&");
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setShipmentItemsData(responseData);
        } else {
          setShipmentItemsData([]);
        }
      },
      (error) => {
        setShipmentItemsData([]);
      }
    );
  };

  // change the input text value
  const handleChangeInputText = (e) => {
    const { name, value } = e.target;
    setStoRecordObj({ ...stoRecordObj, [name]: value });
  };

  // change the select option
  const handleSelectValueChange = (e, keyName) => {
    setStoRecordObj({ ...stoRecordObj, [keyName]: e });
  };

  const columns = [
    {
      field: "shipmentPartNumber",
      headerName: "Part Number",
      flex: 1,
    },
    {
      field: "shipmentPartDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity Ordered",
      flex: 1,
      renderCell: ({ row }) => row?.quantity - (row?.allocate || 0) || 0,
    },
  ];

  // submit the data
  const handleSubmit = () => {
    const rUrl = SHIPMENT_HEADER_MASTER_URL;
    const _shipmentParts = shipmentItemsData.map((row) => row.shipmentPartId);
    const rObj = {
      ...stoRecordObj,
      // shipmentHeaderId: stoRecordObj?.stoNumber,
      rmaReason: stoRecordObj?.referenceDescription,
      rmaType: stoRecordObj.orderNumber,
      rmaNumber: stoRecordObj?.referenceId,
      returnType: stoRecordObj.shippingCondition?.value || "INTRA_COMPANY",
      senderLocation: stoRecordObj?.senderStorageLocation,
      senderPlant: stoRecordObj?.senderPlant?.value || "",
      shipmentParts: [..._shipmentParts],
    };

    // deliveryType: _deliveryType || deliveryTypeOptions[0],

    if (shipmentHeaderId) {
      callPutApi(null, `${rUrl}/${shipmentHeaderId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          handleSnack("success", "STO updated successfully.");
          setViewModeOn(true);
        } else {
          handleSnack("info", "Something went wrong.");
        }
      });
    } else {
      const _stoNumber = "SO" + (Math.floor(Math.random() * 9000) + 1000);
      callPostApi(null, `${rUrl}`, { ...rObj, returnNumber: _stoNumber }, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", "STO created successfully & Updated in ERP.");
          handleSnack("info", "STO accepted in ERP & Updated in GR.");
          setStoRecordObj({ ...stoRecordObj, stoNumber: responseData._stoNumber });
          setShipmentHeaderId(responseData.shipmentHeaderId);
          setViewModeOn(true);
        } else {
          handleSnack("info", "Something went wrong.");
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      {loading ? (
        <LoadingProgress />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center px-3 my-2">
            <h4 className="font-weight-500">Stock Transfer Order Details</h4>
            {shipmentHeaderId && (
              <button className="btn border-primary text-primary py-2" onClick={() => setViewModeOn(false)}>
                Edit
              </button>
            )}
          </div>
          <Divider />
          <Modal.Body>
            <div className="card border px-3 py-2">
              {viewModeOn ? (
                <div className="row mt-2">
                  <ReadOnlyField label="STO NUMBER" value={stoRecordObj?.stoNumber} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="ORDER NUMBER" value={stoRecordObj?.orderNumber} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="REFERENCE DESCRIPTION" value={stoRecordObj?.referenceDescription} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="REFERENCE ID" value={stoRecordObj?.referenceDescription} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="DELIVERY TYPE" value={stoRecordObj?.deliveryType?.label} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="SHIPPPING CONDITION" value={stoRecordObj?.shippingCondition?.label} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="SENDER PLANT" value={stoRecordObj?.senderPlant?.label} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="SENDER STORAGE LOCATION" value={stoRecordObj?.senderStorageLocation} className="col-md-3 col-sm-3" />
                  <ReadOnlyField
                    label="SHIPPED ON"
                    value={stoRecordObj?.shippedOn ? <Moment format="DD/MM/YYYY">{stoRecordObj?.shippedOn}</Moment> : "NA"}
                    className="col-md-3 col-sm-3"
                  />
                  <ReadOnlyField label="TRACKING NUMBER" value={stoRecordObj?.trackingNumber} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="RECEIVER PLANT" value={stoRecordObj?.receiverPlant?.label} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="RECEIVER LOCATION" value={stoRecordObj?.receiverLocation} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="RECEIVER ADDRESS" value={stoRecordObj?.receiverAddress} className="col-md-3 col-sm-3" />
                </div>
              ) : (
                <div className="row input-fields">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">STO NUMBER</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.stoNumber}
                        name="stoNumber"
                        placeholder="STO NUMBER"
                        disabled={recordId ? true : false}
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">ORDER NUMBER</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.orderNumber}
                        name="orderNumber"
                        placeholder="Order NUMBER"
                        disabled={recordId ? true : false}
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">REFERENCE DESCRIPTION</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.referenceDescription}
                        name="referenceDescription"
                        placeholder="Reference Description"
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">REFERENCE ID</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.referenceId}
                        name="referenceId"
                        placeholder="Reference ID"
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">DELIVERY TYPE</label>
                      <Select
                        className="text-primary"
                        value={stoRecordObj?.deliveryType}
                        onChange={(e) => handleSelectValueChange(e, "deliveryType")}
                        options={deliveryTypeOptions}
                        styles={FONT_STYLE_SELECT}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SHIPPPING CONDITION</label>
                      <Select
                        className="text-primary"
                        value={stoRecordObj?.shippingCondition}
                        onChange={(e) => handleSelectValueChange(e, "shippingCondition")}
                        options={sippingConditionOptions}
                        styles={FONT_STYLE_SELECT}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SENDER PLANT</label>
                      <Select
                        className="text-primary"
                        value={stoRecordObj?.senderPlant}
                        onChange={(e) => handleSelectValueChange(e, "senderPlant")}
                        options={plantByOptions}
                        styles={FONT_STYLE_SELECT}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SENDER STORAGE LOCATION</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.senderStorageLocation}
                        name="senderStorageLocation"
                        placeholder="Sender Storage Location"
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SHIPPED ON</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={stoRecordObj?.shippedOn}
                            onChange={(e) => setStoRecordObj({ ...stoRecordObj, shippedOn: e })}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">TRACKING NUMBER</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.trackingNumber}
                        name="trackingNumber"
                        placeholder="Tracking Number"
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">RECEIVER PLANT</label>
                      <Select
                        className="text-primary"
                        value={stoRecordObj?.receiverPlant}
                        onChange={(e) => handleSelectValueChange(e, "receiverPlant")}
                        options={plantByOptions}
                        styles={FONT_STYLE_SELECT}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">RECEIVER LOCATION</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.receiverLocation}
                        name="receiverLocation"
                        placeholder="Receiver Location"
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">RECEIVER ADDRESS</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        value={stoRecordObj?.receiverAddress}
                        name="receiverAddress"
                        placeholder="Receiver Address"
                        onChange={handleChangeInputText}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="card border px-3 py-3">
              <h5>Products</h5>
              <DataGrid
                sx={GRID_STYLE}
                getRowId={(row) => row.shipmentPartId}
                rows={shipmentItemsData}
                autoHeight
                columns={columns}
                // columns={partsColumns}
                editMode="row"
                // paginationMode="server"
                rowsPerPageOptions={[5, 10, 20]}
                pagination
              />
            </div>
            <div className="row px-1" style={{ justifyContent: "right" }}>
              <button className={`btn border-primary text-primary mx-2`} onClick={hideModal}>
                Close
              </button>
              {!viewModeOn && (
                <button className="btn bg-primary text-white mx-2" onClick={handleSubmit}>
                  Submit
                </button>
              )}
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default AddUpdateSTO;
