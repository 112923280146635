import React, { useState } from "react";

import { Box, Divider, Grid, Typography } from "@mui/material";

import Select from "react-select";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { useHistory } from "react-router-dom";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { SUPPLIER_CLAIM_MANAGE, SUPPLIER_CLAIM_PROCESS } from "navigation/CONSTANTS";
import { supplierClaimInProcessRecord, supplierClaimProcessColours, supplierClaimProcessFilterOptions } from "../warrantyManagementConstants";

const SupplierClaimDashboard = () => {
  const history = useHistory();
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  // naviagte manage Supplier claims screen
  const handleManageSupplierClaims = () => {
    history.push(`${SUPPLIER_CLAIM_MANAGE}`);
  };

  // naviagte to Supplier claims in process screen
  const handleSupplierClaimProcess = () => {
    history.push(`${SUPPLIER_CLAIM_PROCESS}`);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
            <h4 className="font-weight-600 mb-4">Supplier Claim</h4>
            <button className="btn bg-primary text-white" onClick={handleManageSupplierClaims}>
              Manage Supplier Claims
            </button>
          </div>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="card border py-2">
                  <Typography className="px-3" sx={{ fontWeight: 500 }}>
                    Recentely Created Supplier Claims
                  </Typography>
                  <p className="px-3">Last 7 days</p>
                  <Divider />
                  <div className="font-size-13">
                    <div style={{ background: "#F2F2F2", fontWeight: 500 }}>
                      <div className="row px-3 py-2 cursor">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">Supplier Claim Number</div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-12">Supplier</div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-12">Created At</div>
                      </div>
                    </div>
                    <Divider />
                    <div className="row px-3 py-2 cursor">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- 2024</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">Ravi Raj</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">10/12/2024</div>
                    </div>
                    <Divider />
                    <div className="row px-3 py-2 cursor">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- 2024</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">Ravi Raj</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">10/12/2024</div>
                    </div>
                    <Divider />
                    <div className="row px-3 py-2 cursor">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- 2024</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">Ravi Raj</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">10/12/2024</div>
                    </div>
                    <Divider />
                    <div className="row px-3 py-2 cursor">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- 2024</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">Ravi Raj</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">10/12/2024</div>
                    </div>
                    <Divider />
                    <div className="row px-3 py-2 cursor">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- 2024</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">Ravi Raj</div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-12">10/12/2024</div>
                    </div>
                  </div>
                  <Divider />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="card border py-2">
                  <Typography className="px-3" sx={{ fontWeight: 500 }}>
                    Approved/Rejected Supplier Claims
                  </Typography>
                  <Typography className="px-3 mb-2" variant="h5">
                    1
                  </Typography>
                  <Divider />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="card border py-2">
                  <Typography className="px-3" sx={{ fontWeight: 500 }}>
                    Supplier Claims in Process
                  </Typography>
                  <Typography className="px-3 mb-2" variant="h5">
                    2
                  </Typography>
                  <Divider />
                  <div className=" py-2 px-3">
                    <Select options={supplierClaimProcessFilterOptions} styles={FONT_STYLE_SELECT} />
                  </div>
                  <Divider className="mt-0" />
                  <div className="supplier-claim-process cursor" onClick={handleSupplierClaimProcess}>
                    <PieChart width={450} height={280} style={{ cursor: "pointer" }}>
                      <Pie
                        data={supplierClaimInProcessRecord}
                        // cx={250}
                        cy={100}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={1}
                        dataKey="value"
                      >
                        {supplierClaimInProcessRecord.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={supplierClaimProcessColours[index % supplierClaimProcessColours.length]}
                            // style={{ cursor: "pointer" }}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend layout="vertical" align="center" />
                    </PieChart>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default SupplierClaimDashboard;
