import axios from "axios";
import { SYSTEM_ERROR } from "config/CONSTANTS";
import Cookies from "js-cookie";
import { DATA_SVC_COMPONENT_ID, DATA_SVC_EQUIPMENT, DATA_SVC_PARTS_BY_GROUP_NO, DATA_SVC_SUBCOMPONENT, FETCH_GAP_TO_ENTITLEMENT, FETCH_PARTS_SEGMENT, FETCH_PARTS_SEGMENT_DETAILS, FETCH_PROPENSITY_TO_BUY, FETCH_PROPENSITY_TO_BUY_DET } from "./CONSTANTS";
var CookiesSetData = Cookies.get("loginTenantDtl");
var getCookiesJsonData;
if (CookiesSetData != undefined) {
    getCookiesJsonData = JSON.parse(CookiesSetData);
}
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `${getCookiesJsonData?.access_token}`,
    },
    // xsrfCookieName: "XSRF-TOKEN",
    // xsrfHeaderName: "X-XSRF-TOKEN",
};
//Search Equipment
export const getEquipmentSearch = (searchString) => {
    console.log("Equipment Service > searchEquipment called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DATA_SVC_EQUIPMENT() + `/search/search=${searchString}`, config)
                .then((res) => {
                    console.log("searchEquipment > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("searchEquipment > axios err=", err);
                    reject("Error in searchEquipment axios!");
                });
        } catch (error) {
            console.error("in Equipment Service > searchEquipment, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const fetchEquipments = (searchString) => {
    console.log("service Commerce > Fetch Equipments Called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DATA_SVC_EQUIPMENT() + `/search?search=${searchString}`, config)
                .then((res) => {
                    console.log("EquipmentMaster -> Fetch Equipments response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("fetchEquipments > axios err=", err);
                    reject("Error in fetchEquipments axios!");
                });
        } catch (error) {
            console.error("fetchEquipments general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const fetchComponents = (componetIds) => {
    console.log("EquipmentMaster > Fetch components Called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DATA_SVC_COMPONENT_ID(), componetIds,config)
                .then((res) => {
                    console.log("EquipmentMaster -> Fetch Compoents response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("fetchComponents > axios err=", err);
                    reject("Error in fetchcomponents axios!");
                });
        } catch (error) {
            console.error("fetchComponents general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const fetchSubComponents = (componetId) => {
    console.log("service Commerce > Fetch subcomponents Called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DATA_SVC_SUBCOMPONENT(), componetId, config)
                .then((res) => {
                    console.log("EquipmentService -> Fetch SubCompoents response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("fetchComponents > axios err=", err);
                    reject("Error in fetchSubComponents axios!");
                });
        } catch (error) {
            console.error("fetchSubComponents general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const fetchParts = (groupNumber) => {
    console.log("service Commerce > Fetch Parts Called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DATA_SVC_PARTS_BY_GROUP_NO(), groupNumber,config)
                .then((res) => {
                    console.log("EquipmentService -> Fetch Parts response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("fetch Parts > axios err=", err);
                    reject("Error in fetch Parts axios!");
                });
        } catch (error) {
            console.error("fetchSubComponents general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};