import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";

import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "react-drag-drop-files";
import { TextField, FormControlLabel, Switch, FormGroup, Tooltip } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";


import {
    dataTableStyle,
    searchOptions,
    warrantySearchOptions,
} from "./equipmentConstant";
import EquipmentSearchComponent from "./EquipmentSearchComponent";
import { dataTableCustomStyle } from "pages/Common/PortfolioAndSolutionConstants";
import { FONT_STYLE } from "pages/Common/constants";
import { fetchParts } from "services/equipmentService";
import LoadingProgress from "pages/Repair/components/Loader";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];




const EquipmentDataTablePagination = ({
    columns,
    data,
    title,
    buttonText = "Upload",
    expandable = false,
    expandablColumns,
    showEditComponentModal,
    selectededitComponentDetails,
    setShowEditComponentModal,
    currPage,
    setCurrPage,
    dataPerPage,
    setDataPerPage,
    noOfRows,
}) => {


    const updatedSubComponentColumns = [
        {
            id: "subComponentId",
            name: <div>Sub-Component #</div>,
            selector: (row) => row.serialNumber,
            wrap: true,
            sortable: false,
            cell: (row) => row.componentCode || "293745",
            // selector: (row) => (
            //     <div
            //         className="cursor"
            //         style={{ fontSize: 12, textDecoration: "underline" }}
            //         onClick={async () => {
            //             await fetchPartsDetails(2276044); //row.groupNumber
            //             setOpenPartModal(true);
            //         }}
            //     >
            //         {row.componentCode || "293745"}
            //     </div>
            // ),
        },
        {
            id: "subComponentSerialNumber",
            name: <div>Serial Number</div>,
            selector: (row) => row.serialNumber,
            wrap: true,
            sortable: false,
            cell: (row) => row.serialNumber || "ZMX00507",
        },
        {
            id: "subComponentWarrentyCategory",
            name: <div>Warranty Category</div>,
            selector: (row) => row.warrantyCategory,
            wrap: true,
            sortable: false,
            cell: (row) => row.warrantyCategory || "Supplier Warranty",
        },
        {
            id: "subComponentWarrentyStartDate",
            name: <div>Warranty Start Date</div>,
            selector: (row) => row.warrantyStartDate,
            wrap: true,
            sortable: false,
            cell: (row) => row.warrantyStartDate || "2024-03-05",
        },
        {
            id: "subComponentWarrentyEndDate",
            name: <div>Warranty End Date</div>,
            selector: (row) => row.warrantyEndDate,
            wrap: true,
            sortable: false,
            cell: (row) => row.warrantyEndDate || "2025-04-05",
        },
        {
            id: "subComponentSupplierId",
            name: <div>Supplier ID</div>,
            selector: (row) => row.supplierId,
            wrap: true,
            sortable: false,
            cell: (row) => row.supplierId || "PS235",
        },
        {
            id: "subComponentSupplierId",
            name: <div>Supplier Name</div>,
            selector: (row) => row.supplierName,
            wrap: true,
            sortable: false,
            cell: (row) => row.supplierName || "Caterpillar",
        },
        {
            name: (
                <>
                    <div>Actions</div>
                </>
            ),
            wrap: true,
            sortable: true,
            minWidth: 120,
            cell: (row) => (
                <div className="d-flex justify-content-center align-items-center row-svg-div" style={{ minWidth: "100px !important" }}>
                    <Tooltip title="Edit" className="cursor mr-2">
                        <EditOutlinedIcon
                            onClick={() => { handleSubcomponetEdit(row) }}
                        />
                    </Tooltip>
                    <Tooltip title="Related Parts" className="cursor ml-2">
                        <AddIcon
                            onClick={() => { setShowRelatedParts(true); handlerelatedPartsModal(row) }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ]

    const partsColumns = [
        {
            name: 'Part #',
            selector: row => row.partNumber,
            sortable: true,
            style: {
                width: '100px', // Adjust this value as needed
            },
        },
        {
            name: 'Description',
            selector: row => row.partDescription,
            sortable: true,
            style: {
                width: '200px', // Adjust this value as needed
            },
        },
        {
            name: 'Quantity',
            selector: row => row.quantity ?? 1,
            sortable: true,
            style: {
                width: '100px', // Adjust this value as needed
            },
        },
        {
            name: 'M/O',
            selector: row => row.mandatory ?? 'Mandatory',
            sortable: true,
            style: {
                width: '100px', // Adjust this value as needed
            },
        },
        {
            name: (
                <>
                    <div>Actions</div>
                </>
            ),
            wrap: true,
            sortable: true,
            style: {
                width: '100px', // Adjust this value as needed
            },
            cell: (row) => (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minWidth: '60px' }}
                >
                    <AddShoppingCartIcon className="cursor" />
                </div>
            ),
        },
    ];

    const [uploadFileImage, setUploadFileImage] = useState("general");
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [selectededitSubComponentDetails, setSelectededitSubComponentDetails] = useState();
    const [showEditSubComponentModal, setShowEditSubComponentModal] = useState(false);
    const [showRelatedParts, setShowRelatedParts] = useState(false);
    const [relatedPartList, setRelatedPartList] = useState();
    const [loading, setLoading] = useState(false);
    const handleImageFileUpload = (e, value) => {
        setUploadFileImage(value);
    };

    const hideFileUploadModal = () => {
        setShowFileUploadModal(false);
    }
    const hideShowAddNewModal = () => {
        setShowAddNewModal(false);
    }

    const handleSubcomponetEdit = (row) => {
        console.log(row);
        setSelectededitSubComponentDetails(row);
        setShowEditSubComponentModal(true);
    }

    const handlerelatedPartsModal = async (row) => {
        //row.groupNumber
        try {
            setLoading(true);
            const partList = await fetchParts([2276044]);
            setRelatedPartList(partList);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    const relatedPartsModal = () => {
        return (
            <Modal show={showRelatedParts} onHide={() => { setShowRelatedParts(false) }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Related Parts</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="p-3">
                        {loading ? <LoadingProgress /> : <DataTable
                            columns={partsColumns}
                            data={relatedPartList}
                            customStyles={dataTableStyle}
                            noHeader
                            pagination={false}
                            fixedHeader
                            fixedHeaderScrollHeight="500px"
                        />}
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-end m-0 p-3">
                    <div className="mr-2">
                        <button className="btn border w-100 bg-white" onClick={() => { setShowRelatedParts(false) }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const fileUploadModal = () => {
        return (
            <Modal show={showFileUploadModal} onHide={hideFileUploadModal} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Import Files</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="p-3">
                        <div className="add-new-recod">
                            <div>
                                <FontAwesomeIcon
                                    className="cloudupload"
                                    icon={faCloudUploadAlt}
                                />
                                <h6 className="font-weight-500 mt-3">
                                    Drag and drop files to upload <br /> or
                                </h6>
                                <FileUploader
                                    name="file"
                                    types={fileTypes}
                                    handleChange={handleImageFileUpload}
                                />
                            </div>
                        </div>
                        <p className="mt-3">
                            Single upload file should not be more than 10MB. Only the .lgs,
                            .lgsx file types are allowed
                        </p>
                    </div>
                </Modal.Body>
                <div className="row m-0 p-3">
                    <div className="col-md-6 col-sm-6">
                        <button className="btn border w-100 bg-white" onClick={hideFileUploadModal}>
                            Cancel
                        </button>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <button className="btn btn-primary w-100 cursor" onClick={hideFileUploadModal}>
                            <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} /> Upload
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const editSubComponentModal = () => {
        return (
            <Modal show={showEditSubComponentModal} onHide={() => setShowEditSubComponentModal(false)} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>Edit SubComponent</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="row input-fields">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    SubComponent Id
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="subComponentId"
                                    placeholder="SubComponent Id"
                                    value={selectededitSubComponentDetails.id ? selectededitSubComponentDetails.id : ""}
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Feature Code (SAP)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="featureCode"
                                    placeholder="Feature Code (SAP)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Feature Description (SAP)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="featureDescription"
                                    placeholder="Feature Description (SAP)"
                                    value={selectededitSubComponentDetails.featureDesc ? selectededitSubComponentDetails.featureDesc : ""}
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Reference (SAP Variant / Other System)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="reference"
                                    placeholder="Reference (SAP Variant / Other System)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Quantity (optional)
                                </label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="quantity"
                                    placeholder="Quantity (optional)"
                                    value={1}
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Serial Number (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="serialNumber"
                                    placeholder="Serial Number (optional)"
                                    // onChange={handleInputChange}
                                    value={"ZMX00507"}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Warranty Start Date
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            value={"03/05/2024"}
                                            //onChange={() => { }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Warranty End Date
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            value={"03/05/2025"}
                                            //onChange={() => { }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <FormGroup>
                                    <FormControlLabel
                                        style={{ alignItems: "start", marginLeft: 0 }}
                                        control={
                                            <Switch
                                                onChange={() => { }}
                                                checked={true}
                                                // value={true}
                                                name="warranty"
                                            />
                                        }
                                        labelPlacement="top"
                                        label={
                                            <span className="text-light-dark font-size-14 font-weight-500">
                                                Warranty (yes / no)
                                            </span>
                                        }
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Supplier Name (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="supplierName"
                                    placeholder="Supplier Name (optional)"
                                    // onChange={handleInputChange}'
                                    value={"Caterpillar"}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    SubComponent code
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="subCompCode"
                                    placeholder="SubComp Code"
                                    // onChange={handleInputChange}
                                    value={selectededitSubComponentDetails.componentCode ? selectededitSubComponentDetails.componentCode : ""}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    SubComponent Description
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="subCompCodeDescription"
                                    placeholder="SubComp Code Description"
                                    // onChange={handleInputChange}
                                    value={selectededitSubComponentDetails.description ? selectededitSubComponentDetails.description : ""}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Assignment
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="assignment"
                                    placeholder="Assignment"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-end mb-2 mr-2">
                    <div className="mr-2">
                        <button className="btn border w-100 bg-white" onClick={() => setShowEditSubComponentModal(false)}>
                            Cancel
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary w-100 cursor" onClick={() => setShowEditSubComponentModal(false)}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const addnewComponentModal = () => {
        return (
            <Modal show={showAddNewModal} onHide={hideShowAddNewModal} size="xl" centered>
                <Modal.Header>
                    <Modal.Title> Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="row input-fields">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Component Id
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="componentId"
                                    placeholder="Component Id"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Feature Code (SAP)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="featureCode"
                                    placeholder="Feature Code (SAP)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Feature Description (SAP)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="featureDescription"
                                    placeholder="Feature Description (SAP)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Reference (SAP Variant / Other System)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="reference"
                                    placeholder="Reference (SAP Variant / Other System)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Quantity (optional)
                                </label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="quantity"
                                    placeholder="Quantity (optional)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Serial Number (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="serialNumber"
                                    placeholder="Serial Number (optional)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Warranty Start Date
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            value={new Date()}
                                            //onChange={() => { }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Warranty End Date
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            value={new Date()}
                                            //onChange={() => { }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <FormGroup>
                                    <FormControlLabel
                                        style={{ alignItems: "start", marginLeft: 0 }}
                                        control={
                                            <Switch
                                                // onChange={handleSwitchChange}
                                                // checked={true}
                                                name="warranty"
                                            />
                                        }
                                        labelPlacement="top"
                                        label={
                                            <span className="text-light-dark font-size-14 font-weight-500">
                                                Warranty (yes / no)
                                            </span>
                                        }
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Supplier Name (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="supplierName"
                                    placeholder="Supplier Name (optional)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Comp Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="compCode"
                                    placeholder="Comp Code"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Comp Code Description
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="compCodeDescription"
                                    placeholder="Comp Code Description"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Assignment
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="assignment"
                                    placeholder="Assignment"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-end mb-2 mr-2">
                    <div className="mr-2">
                        <button className="btn border w-100 bg-white" onClick={hideShowAddNewModal}>
                            Cancel
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary w-100 cursor" onClick={hideShowAddNewModal}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const editComponentModal = () => {
        return (
            <Modal show={showEditComponentModal} onHide={() => setShowEditComponentModal(false)} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>Edit Component</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="row input-fields">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Component Id
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="componentId"
                                    placeholder="Component Id"
                                    value={selectededitComponentDetails.id ? selectededitComponentDetails.id : ""}
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Feature Code (SAP)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="featureCode"
                                    placeholder="Feature Code (SAP)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Feature Description (SAP)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="featureDescription"
                                    placeholder="Feature Description (SAP)"
                                    value={selectededitComponentDetails.featureDesc ? selectededitComponentDetails.featureDesc : ""}
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Reference (SAP Variant / Other System)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="reference"
                                    placeholder="Reference (SAP Variant / Other System)"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Quantity (optional)
                                </label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="quantity"
                                    placeholder="Quantity (optional)"
                                    value={1}
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Serial Number (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="serialNumber"
                                    placeholder="Serial Number (optional)"
                                    // onChange={handleInputChange}
                                    value={"ZMX00507"}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Warranty Start Date
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            value={"03/05/2024"}
                                            //onChange={() => { }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Warranty End Date
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            value={"03/05/2025"}
                                            //onChange={() => { }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <FormGroup>
                                    <FormControlLabel
                                        style={{ alignItems: "start", marginLeft: 0 }}
                                        control={
                                            <Switch
                                                onChange={() => { }}
                                                checked={true}
                                                // value={true}
                                                name="warranty"
                                            />
                                        }
                                        labelPlacement="top"
                                        label={
                                            <span className="text-light-dark font-size-14 font-weight-500">
                                                Warranty (yes / no)
                                            </span>
                                        }
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Supplier Name (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="supplierName"
                                    placeholder="Supplier Name (optional)"
                                    // onChange={handleInputChange}'
                                    value={"Caterpillar"}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Comp Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="compCode"
                                    placeholder="Comp Code"
                                    // onChange={handleInputChange}
                                    value={selectededitComponentDetails.componentCode ? selectededitComponentDetails.componentCode : ""}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Comp Code Description
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="compCodeDescription"
                                    placeholder="Comp Code Description"
                                    // onChange={handleInputChange}
                                    value={selectededitComponentDetails.description ? selectededitComponentDetails.description : ""}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-14 font-weight-500">
                                    Assignment
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="assignment"
                                    placeholder="Assignment"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-end mb-2 mr-2">
                    <div className="mr-2">
                        <button className="btn border w-100 bg-white" onClick={() => setShowEditComponentModal(false)}>
                            Cancel
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary w-100 cursor" onClick={() => setShowEditComponentModal(false)}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    const expendSubComponentRow = (data, searchFlag) => {
        return (
            <div className="p-5 border-bottom">
                {console.log("data ====== ", data)}
                <div className="border border-radius-10">
                    <div className="d-flex align-items-center justify-content-between p-3">
                        <div className="d-flex align-items-center">
                            <h6 className="mb-0 font-weight-600 font-size-14 mr-3">
                                Sub Component
                            </h6>
                            {/* <div className="d-flex align-items-center">
                <a className="mr-2 cursor">
                  <span>
                    <ModeEditOutlineOutlinedIcon />
                  </span>
                </a>
                <a className="mr-2 cursor">
                  <span>
                    <ShareOutlinedIcon />
                  </span>
                </a>
              </div> */}
                        </div>
                        {!searchFlag && (
                            <div className="border-left d-flex align-items-center">
                                <a
                                    style={{ whiteSpace: "pre" }}
                                    className="btn-sm cursor"
                                // onClick={handleAddMoreBundleService}
                                >
                                    <span className="mr-2">
                                        <AddIcon />
                                    </span>
                                    Add
                                </a>
                            </div>
                        )}
                    </div>
                    {/* {data.subComponents.length !== 0 && ( */}
                    <DataTable
                        customStyles={dataTableCustomStyle}
                        data={data.subComponents}
                        columns={updatedSubComponentColumns}
                        pagination={true}
                    />
                    {/* )} */}
                </div>
            </div>
        );
    };
    return (
        <>
            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-md-8">
                        <div className="d-flex align-items-center">
                            <h6 className="font-weight-500 mb-0 mr-3">{title}</h6>
                            <EquipmentSearchComponent
                                searchOptions={
                                    title.toLowerCase() === "warranty"
                                        ? warrantySearchOptions
                                        : searchOptions
                                }
                                searchPlaceholder={title}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 text-right">
                        <a href="#" className="btn border  mr-2" onClick={() => { setShowAddNewModal(true) }}>
                            <span className="mr-1">
                                <AddIcon />
                            </span>
                            Add New
                        </a>
                        <a href="#" className="btn  border " onClick={() => { setShowFileUploadModal(true) }}>
                            <span className="mr-1">
                                <AddIcon />
                            </span>
                            {buttonText}
                        </a>
                    </div>
                </div>
                <div className="table-responsive mt-3">
                    <div
                        className="custom-table  table-child"
                        style={{ height: "auto", width: "100%" }}
                    >
                        <DataTable
                            columns={columns}
                            data={data}
                            customStyles={dataTableStyle}
                            expandableRows={expandable}
                            expandableRowsComponent={(itemData) =>
                                expendSubComponentRow(itemData.data, true)
                            }
                            pagination={true}
                            paginationServer

                        />
                    </div>
                </div>
            </div>
            {showFileUploadModal && fileUploadModal()}
            {showAddNewModal && addnewComponentModal()}
            {showEditComponentModal && editComponentModal()}
            {showEditSubComponentModal && editSubComponentModal()}
            {showRelatedParts && relatedPartsModal()}
        </>
    );
};

export default EquipmentDataTablePagination;
