import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

const ProgressPie = ({Name,percentage,Count }) => {
    const COLORS = ['#0088FE','#E5E5E5'];
    const data = [
        { name: 'Progress', value: percentage },
        { name: 'Remaining', value: 100 - percentage }
    ];

    

    // Determine label text and style based on percentage
    const labelValue = `${percentage===100?'':percentage+'%'}`;
    const labelFill= '#6C70FE';

    return (
        <PieChart width={400} height={250}>
            <Pie
                data={data}
                cx={200}
                cy={160}
                startAngle={180}
                endAngle={0}
                innerRadius={100}
                outerRadius={115}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
                // cornerRadius={7}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} cornerRadius={7} />
                ))}
                <Label value={labelValue} position="center" fill={labelFill} fontSize={20} dy={-20} />
            </Pie>
        </PieChart>
    );
}

export default ProgressPie;
