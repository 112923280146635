
import { SYSTEM_ERROR } from "config/CONSTANTS";
import { GET_TOP_10_FAILURE_MODES,GET_TOP_5_REGION_DATA,GET_TOP_5_CLAIMED_PARTS, GET_TOP_5_CLAIMED_MODEL, GET_CITY_DETAILS, GET_TOP_5_DETAILS_FOR_CITY } from "services/CONSTANTS";
import Cookies from "js-cookie";
import axios from "axios";
var CookiesSetData = Cookies.get("loginTenantDtl");

var getCookiesJsonData;
if (CookiesSetData != undefined) {
    getCookiesJsonData = JSON.parse(CookiesSetData);
}


const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `${getCookiesJsonData?.access_token}`,
    },
};

export const getTop10FailedModes = () => {
    console.log("getTop10FailedModes called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(GET_TOP_10_FAILURE_MODES, config)
                .then((res) => {
                    console.log("getTop10FailedModes > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("getTop10FailedModes > axios err=", err);
                    reject("Error in getTop10FailedModes axios!");
                });
        } catch (error) {
            console.error("in WarrantyAnalytics > getTop10FailedModes, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getTop5RegionData = () => {
    console.log("getRegionData called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(GET_TOP_5_REGION_DATA, config)
                .then((res) => {
                    console.log("getTop5RegionData > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("getTop5RegionData > axios err=", err);
                    reject("Error in getTop5RegionData axios!");
                });
        } catch (error) {
            console.error("in WarrantyAnalytics > getTop5RegionData, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const getTop5ClaimedParts = () => {
    console.log("getTop5ClaimedParts called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(GET_TOP_5_CLAIMED_PARTS, config)
                .then((res) => {
                    console.log("getTop5ClaimedParts > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("getTop5ClaimedParts > axios err=", err);
                    reject("Error in getTop5ClaimedParts axios!");
                });
        } catch (error) {
            console.error("in WarrantyAnalytics > getTop5ClaimedParts, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getTop5ClaimedModel = () => {
    console.log("getTop5ClaimedParts called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(GET_TOP_5_CLAIMED_MODEL, config)
                .then((res) => {
                    console.log("getTop5ClaimedModel > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("getTop5ClaimedModel > axios err=", err);
                    reject("Error in getTop5ClaimedParts axios!");
                });
        } catch (error) {
            console.error("in WarrantyAnalytics > getTop5ClaimedModel, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const getCityDetails = (str) => {
    console.log("getCityDetails called...");
    const searchStr=GET_CITY_DETAILS+str;
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(searchStr, config)
                .then((res) => {
                    console.log("getCityDetails > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("getCityDetails > axios err=", err);
                    reject("Error in getCityDetails axios!");
                });
        } catch (error) {
            console.error("in WarrantyAnalytics > getCityDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getTop5PartsDetailsForCity = (str) => {
    console.log("getTop5PartsDetailsForCity called...");
    const searchStr=GET_TOP_5_DETAILS_FOR_CITY+str;
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(searchStr, config)
                .then((res) => {
                    console.log("getTop5PartsDetailsForCity > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject(res.data);
                })
                .catch((err) => {
                    console.log("getTop5PartsDetailsForCity > axios err=", err);
                    reject("Error in getTop5PartsDetailsForCity axios!");
                });
        } catch (error) {
            console.error("in WarrantyAnalytics > getTop5PartsDetailsForCity, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


