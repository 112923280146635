// App.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardContent, Typography, Button, Pagination } from '@mui/material';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';

const InsightCard = ({ title, description }) => {
    return (
        <Card style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', height: 150 }} variant="outlined">
            <CardContent>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}


const InsightFullCard = ({ answer, recommended_Parts }) => {
    return (
        <div className='row'>
            <div className='col-md-9'>
                <InsightCard
                    title="Check/Repair"
                    description={answer}
                />
            </div>
            <div className='col-md-3'>
                <InsightCard
                    title="Recommended Parts"
                    description={recommended_Parts}
                />
            </div>
        </div>
    )
}

const ShowInsights = ({ searchedInsight }) => {
    return (
        <div className='container px-3 py-3'>
            <div className="row mt-5 mb-3">
                <div className='col'>
                    <Typography variant='h4' component='div' className='mb-2'>
                       {searchedInsight[0].ques}
                    </Typography>
                    <Typography variant="h5" component="div" gutterBottom className='font-weight-700 mt-4 mb-2'>
                        <WbIncandescentOutlinedIcon fontSize='large' style={{ marginRight: '10px', transform: 'rotate(180deg)' }} className='text-success' /> <span className='text-light'> Found {searchedInsight.length} insight </span>
                    </Typography>
                </div>
            </div>
            <InsightFullCard answer={searchedInsight[0].ans} recommended_Parts={searchedInsight[0].recommended_parts} />
            <div className="row my-4">
                <div className="col d-flex justify-content-center">
                    <div className="btn btn-primary mx-2 font-weight-500 disabled cursor">PREVIOUS</div>
                    <div className="btn btn-primary font-weight-500 disabled cursor" >NEXT</div>
                </div>
            </div>
        </div>
    );
}


export default ShowInsights
