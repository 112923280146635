import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tab, TextField } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import shareIcon from "../../assets/icons/svg/share.svg";
import folderaddIcon from "../../assets/icons/svg/folder-add.svg";
import uploadIcon from "../../assets/icons/svg/upload.svg";
import cpqIcon from "../../assets/icons/svg/CPQ.svg";
import deleteIcon from "../../assets/icons/svg/delete.svg";
import copyIcon from "../../assets/icons/svg/Copy.svg";
import editIcon from "../../assets/icons/svg/edit.svg";
import SearchIcon from "@mui/icons-material/Search";
import { MuiMenuComponent } from "pages/Operational";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import {
    GET_LABOUR_PRICE,
    GET_SPAREPART_PRICE,
    PRICING_LABOUR_PRICE_SEARCH,
    PRICING_PARTS_PRICE_SEARCH,
} from "services/CONSTANTS";
import $ from "jquery";
import InputBoxSearchLoader from "pages/SolutionModules/use-case-4/useCase4Common/InputBoxSearchLoader";
import { FONT_STYLE } from "pages/Repair/CONSTANTS";
import LoadingProgress from "pages/Repair/components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
const dataForPartsDummy = [
    {
        id: 1,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 2,
        country: "USA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Dollar",
    },
    {
        id: 3,
        country: "AUSTRALIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "AUD",
    },
    {
        id: 4,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 5,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 6,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 7,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 8,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 9,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 10,
        country: "INDIA",
        productModel: "BS31",
        partId: 35,
        description: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
];

const dataForLaborDummy = [
    {
        id: 1,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 2,
        country: "USA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Dollar",
    },
    {
        id: 3,
        country: "AUSTRALIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "AUD",
    },
    {
        id: 4,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 5,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 6,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 7,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 8,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 9,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
    {
        id: 10,
        country: "INDIA",
        chargeCode: "BS31",
        code: 35,
        serviceType: "Motor 50HP",
        unit: "50",
        price: "200$",
        validFrom: "01/01/2024",
        validTo: "01/01/2030",
        Status: "Available",
        currency: "Rupees",
    },
];

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: "#872ff9",
    }),
};

function PriceMaintenance() {
    const activityOptions = ["None", "Atria", "Callisto"];
    const countryoptions = [
        { value: "usa", label: "USA" },
        { value: "india", label: "INDIA" },
        { value: "australia", label: "AUSTRALIA" },
        { value: "uk", label: "UK" },
    ];
    const modeloptions = [
        { value: "6FGV3", label: "6FGV3" },
        { value: "RTG-95XLN", label: "RTG-95XLN" },
    ];
    const joboptions = [
        { value: "GROUND_CLEAN", label: "GROUND-CLEAN" },
        { value: "ADJUST", label: "ADJUST" },
        { value: "INSPECT", label: "INSPECT" },
    ];
    const serviceoptions = [
        { value: "WARRANTY PRICE,  ", label: "WEEKEND" },
        { value: "SELL Price", label: "WEEKDAYS" },
        { value: "OVERTIME", label: "OVERTIME" },
        { value: "NORMAL", label: "NORMAL" },
    ];
    const laborpriceoptions = [
        { value: "WARRANTY PRICE", label: "WARRANTY PRICE" },
        { value: "SELL PRICE", label: "SELL PRICE" },
    ];
    const partspriceoptions = [
        { value: "warrantyprice", label: "WARRANTY PRICE" },
        { value: "listprice", label: "LIST PRICE" },
        { value: "costprice", label: "COST PRICE" },
    ];

    const chargeCodeOptions = [
        { value: "MINIMUM_SERVICE_CALL", label: "Minimum Service Call" },
        { value: "ADDITIONAL_ITEMS", label: "Additional Items" },
        {
            value: "NON_SCHEDULED_ADDITIONAL_ITEMS",
            label: "Non-Scheduled Additional Items",
        },
        { value: "EXTRA_APPROVED_TIME", label: "Extra Approved Time" },
    ];

    const [selectedOption, setSelectedOption] = useState(null);

    const [showPartsModal, setShowPartsModal] = React.useState(false);
    const [showLaborsModal, setShowLaborsModal] = React.useState(false);
    const [showEditModalShipping, setShowEditModalShipping] = useState(false);
    const [showEditModalTax, setShowEditModalTax] = useState(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [file, setFile] = useState(null);

    const [activeTab, setActiveTab] = useState("parts");

    const handleOpenPartsModal = () => setShowPartsModal(true);
    const handleOpenLaborsModal = () => setShowLaborsModal(true);

    const handleOpenEditModalShipping = (rowData) => {
        setSelectedRowData(rowData);
        setShowEditModalShipping(true);
    };
    const handleOpenEditModalTax = (rowData) => {
        setShowEditModalTax(true);
        setSelectedRowData(rowData);
    };

    const [dataForSpareParts, setDataForSpareParts] = useState([
        ...dataForPartsDummy,
    ]);
    const [pageNoParts, setpageNoParts] = useState(0);
    const [pageSizeParts, setpageSizeParts] = useState(5);
    const [dataForLabour, setDataForLabour] = useState([...dataForLaborDummy]);
    const [pageNoLabor, setpageNoLabor] = useState(0);
    const [pageSizeLabor, setpageSizeLabor] = useState(5);

    const [sparePartsPriceType, setSparePartsPriceType] = useState({
        value: "warrantyprice",
        label: "WARRANTY PRICE",
    });
    const [sparePartsModelType, setSparePartsModelType] = useState("");
    const [laborPriceType, setLaborPriceType] = useState({
        value: "warrantyprice",
        label: "WARRANTY PRICE",
    });
    const [laborJobCode, setLaborJobCode] = useState("");
    const [laborChargeCode, setLaborChargeCode] = useState("");
    const [partIdInput, setPartIdInput] = useState("");
    const [partDescriptionInput, setPartDescriptionInput] = useState("");
    const [partNumberSuggestions, setPartNumberSuggestions] = useState([]);
    const [partDescriptionSuggestions, setPartDescriptionSuggestions] =
        useState("");
    const [loadingPartsId, setloadingPartsId] = useState(false);
    const [loadingPartsDescriptions, setloadingPartsDescriptions] =
        useState(false);
    const [partIdSelected, setPartIdSelected] = useState(false);
    const [partDescriptionSelected, setPartDescriptionSelected] =
        useState(false);

    const [partSearched, setPartSearched] = useState(false);
    const [laborSearched, setLaborSearched] = useState(false);

    const [searchedParts, setSearchedParts] = useState([]);
    const [searchedLabors, setSearchedLabors] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [selectedShippingData, setSelectedShippingData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedRowData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSaveChanges = () => {
        setDataForSpareParts((prevRows) =>
            prevRows.map((row) =>
                row.partId === selectedRowData.partId ? selectedRowData : row
            )
        );

        handleEditModalShipp();
    };

    const handleSaveChangesTax = () => {
        setDataForSpareParts((prevRows) =>
            prevRows.map((row) =>
                row.partId === selectedRowData.partId ? selectedRowData : row
            )
        );

        console.log("Saving data:", selectedRowData);
        handleEditModalTx();
    };

    const handleLaborsModal = () => {
        setShowLaborsModal(false);
    };
    const handleEditModalShipp = () => {
        setShowEditModalShipping(false);
        setSelectedRowData(null);
    };
    const handleEditModalTx = () => {
        setShowEditModalTax(false);
        setSelectedRowData(null);
    };
    const handlePartsModal = () => {
        setShowPartsModal(false);
    };
    const handleModelChangeParts = (selectedOption) => {
        setSparePartsModelType(selectedOption);
    };
    const handleJobChangeLabor = (selectedjobOption) => {
        setLaborJobCode(selectedjobOption);
    };
    const handlePriceChangeLabor = (selectedServiceOption) => {
        setLaborPriceType(selectedServiceOption);
    };
    const handleChargeCodeChangeLabor = (selectedCountryOption) => {
        setLaborChargeCode(selectedCountryOption);
    };
    const handlePriceChangeParts = (selectedPriceOption) => {
        setSparePartsPriceType(selectedPriceOption);
    };

    const handleReadFile = (file) => {
        if (file) {
            setFile(file);
        }
    };
    const handleUploadFile = async () => {
        // console.log("Upload");
        const form = new FormData();
        form.append("file", file);
        // await uploadPartsToPartlist(partListNo, form)
        //   .then((result) => {
        //     // fetchPartsOfPartlist(partListNo, page, pageSize);
        //     handleSnack(
        //       "success",
        //       `New parts have been uploaded to the partlist: ${partListId}`
        //     );
        //     if (result) {
        //       fetchAllDetails(builderId, generalData.version);
        //     }
        //   })
        //   .catch((err) => {
        //     handleSnack("error", `Failed to upload the parts!`);
        //   });
        setFileUploadOpen(false);
    };

    const handlePartIdInput = (e) => {
        setPartNumberSuggestions([]);
        let input = e.target.value;
        setPartIdInput(input);
        setPartIdSelected(false);
        if (input) {
            setloadingPartsId(true);
            let partIdUrl = PRICING_PARTS_PRICE_SEARCH(`partId~"${input}"`);
            callGetApi(
                partIdUrl,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        setPartNumberSuggestions(responseData);
                    } else {
                        setPartNumberSuggestions([]);
                    }
                    setloadingPartsId(false); // Set loading state to false after API call completes
                },
                (error) => {
                    setPartNumberSuggestions([]);
                    setloadingPartsId(false); // Set loading state to false if there's an error
                }
            );
            $(`.scrollbar-${0}`).css("display", "block");
        }
    };

    const handlePartDescriptionInput = (e) => {
        setPartDescriptionSuggestions([]);
        let input = e.target.value;
        setPartDescriptionInput(input);
        setPartDescriptionSelected(false);
        if (input) {
            setloadingPartsDescriptions(true);
            let partDescUrl = PRICING_PARTS_PRICE_SEARCH(
                `description~"${input}"`
            );
            callGetApi(
                partDescUrl,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        setPartDescriptionSuggestions(responseData);
                    } else {
                        setPartDescriptionSuggestions([]);
                    }
                    setloadingPartsDescriptions(false); // Set loading state to false after API call completes
                },
                (error) => {
                    setPartDescriptionSuggestions([]);
                    setloadingPartsDescriptions(false); // Set loading state to false if there's an error
                }
            );
            $(`.scrollbar-${1}`).css("display", "block");
        }
    };

    const handleSelectDropdownItemForpartId = (currentItem) => {
        setPartIdInput(currentItem.partId);
        $(`.scrollbar-${0}`).css("display", "none");
        setPartIdSelected(true);
        handleDataForParts();
        setPartNumberSuggestions([]);
    };

    const handleSelectDropdownItemForpartDescription = (currentItem) => {
        setPartDescriptionInput(currentItem.description);
        $(`.scrollbar-${1}`).css("display", "none");
        setPartDescriptionSelected(true);
        handleDataForParts();
        setPartDescriptionSuggestions([]);
    };

    const [loadingParts, setLoadingParts] = useState(false);
    const [loadingLabors, setLoadingLabors] = useState(false);

    const handleDataForParts = () => {
        setSearchedParts([]);
        let fullSearchUrl = PRICING_PARTS_PRICE_SEARCH("");
        let flag = false;

        if (sparePartsModelType) {
            flag = true;
            fullSearchUrl += `productModel:"${sparePartsModelType.value}"`;
        }
        if (partIdSelected) {
            fullSearchUrl += flag
                ? ` AND partId:"${partIdInput}"`
                : `partId:"${partIdInput}"`;
            flag = true;
        }
        if (partDescriptionSelected) {
            fullSearchUrl += flag
                ? ` AND description:"${partDescriptionInput}"`
                : `description:"${partDescriptionInput}"`;
            flag = true;
        }

        if (!flag) return;

        setLoadingParts(true); // Set loading to true before the API call

        callGetApi(
            fullSearchUrl,
            (response) => {
                setLoadingParts(false); // Set loading to false after the API call is successful
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setSearchedParts(responseData);
                    setPartSearched(true);
                } else {
                    setSearchedParts([]);
                }
            },
            (error) => {
                setLoadingParts(false); // Set loading to false after the API call fails
                setSearchedParts([]);
            }
        );
    };

    const handleDataForLabors = () => {
        setSearchedLabors([]);
        let fullSearchUrl = PRICING_LABOUR_PRICE_SEARCH("");
        let flag = false;
        if (laborChargeCode) {
            flag = true;
            fullSearchUrl =
                fullSearchUrl + `chargeCode:"${laborChargeCode.value}"`;
        }
        if (laborJobCode) {
            fullSearchUrl = flag
                ? fullSearchUrl + ` AND serviceType:"${laborJobCode.value}"`
                : fullSearchUrl + `serviceType:"${laborJobCode.value}"`;
            flag = true;
        }
        if (!flag) return;
        setLoadingLabors(true);
        callGetApi(
            fullSearchUrl,
            (response) => {
                setLoadingLabors(false);
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setSearchedLabors(responseData);
                    setLaborSearched(true);
                } else {
                    setSearchedParts([]);
                }
            },
            (error) => {
                setLoadingLabors(false);
                setSearchedLabors([]);
            }
        );
    };

    const columnsforSpareParts = [
        {
            field: "country",
            headerName: "Country",
            flex: 1,
            width: 70,
            valueGetter: (params) => params.row.country || "USA",
        },
        {
            field: "productModel",
            headerName: "Model",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.productModel || "992K",
        },
        { field: "partId", headerName: "Part Number", flex: 1, width: 130 },
        {
            field: "description",
            headerName: "Part Description ",
            flex: 1,
            width: 130,
        },
        { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
        { field: "price", headerName: "Unit Price", flex: 1, width: 130 },
        { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
        { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.status || "Active",
        },
        { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    ];
    const columnsforShippingPrice = [
        { field: "partId", headerName: "Part Number", flex: 1, width: 130 },
        {
            field: "description",
            headerName: "Part Description ",
            flex: 1,
            width: 130,
        },
        { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
        { field: "price", headerName: "Unit Price", flex: 1, width: 130 },

        {
            field: "shippingPrice",
            headerName: "Shipping Price",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.shippingPrice || 30.2, // Default value if missing
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.status || "Active",
        },
        { field: "currency", headerName: "Currency", flex: 1, width: 130 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            width: 100,
            renderCell: (params) => (
                <IconButton
                    // color="primary"
                    onClick={() => handleOpenEditModalShipping(params.row)}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
    ];
    const columnsforTax = [
        { field: "partId", headerName: "Part Number", flex: 1, width: 130 },
        {
            field: "description",
            headerName: "Part Description ",
            flex: 1,
            width: 130,
        },
        { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
        { field: "price", headerName: "Unit Price", flex: 1, width: 130 },

        {
            field: "taxPrice",
            headerName: "Tax Price",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.shippingPrice || 20.25, // Default value if missing
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.status || "Active",
        },
        { field: "currency", headerName: "Currency", flex: 1, width: 130 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            width: 100,
            renderCell: (params) => (
                <IconButton
                    // color="primary"
                    onClick={() => handleOpenEditModalTax(params.row)}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    const columnsforLabor = [
        {
            field: "country",
            headerName: "Country",
            flex: 1,
            width: 70,
            valueGetter: (params) => params.row.country || "USA",
        },
        { field: "chargeCode", headerName: "Charge Code", flex: 1, width: 130 },
        {
            field: "jobCode",
            headerName: "Job Code",
            flex: 1,
            width: 130,
            valueGetter: (params) =>
                params.row.jobCode || laborJobCode.value || "GROUND-CLEAN",
        },
        {
            field: "serviceType",
            headerName: " Service Type ",
            flex: 1,
            width: 130,
        },
        { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
        { field: "price", headerName: "Unit Price", flex: 1, width: 130 },
        { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
        { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 130,
            valueGetter: (params) => params.row.status || "Active",
        },
        { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    ];

    const handleSparePartData = async () => {
        callGetApi(
            `${GET_SPAREPART_PRICE}?pageNumber=${pageNoParts}&pageSize=${pageSizeParts}`,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setDataForSpareParts(responseData);
                } else {
                }
            },
            (error) => {}
        );
    };
    const handleLabordata = async () => {
        const labor_price_url = `${GET_LABOUR_PRICE}?pageNumber=${pageNoLabor}&pageSize=${pageSizeLabor}`;
        callGetApi(
            labor_price_url,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setDataForLabour(responseData);
                } else {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const handleClearParts = () => {
        setSearchedParts([]);
        setPartSearched(false);
        setSparePartsModelType("");
        setPartIdInput("");
        setPartDescriptionInput("");
        setPartIdSelected(false);
        setPartDescriptionSelected("");
    };
    const handleClearLabor = () => {
        setSearchedLabors([]);
        setLaborSearched(false);
        setLaborChargeCode("");
        setLaborJobCode("");
    };

    useEffect(() => {
        handleDataForParts();
    }, [partIdSelected, partDescriptionSelected, sparePartsModelType]);

    useEffect(() => {
        handleDataForLabors();
    }, [laborJobCode, laborChargeCode]);

    useEffect(() => {
        handleSparePartData();
    }, [pageNoParts, pageSizeParts]);

    useEffect(() => {
        handleLabordata();
    }, [pageSizeLabor, pageNoLabor]);

    const viewPartsTab = () => {
        return (
            <>
                <div className="card p-4 border-blue">
                    <div className=" mt-4 border card p-3">
                        <div className="d-flex justify-content-between mb-2">
                            <h4 className="text-light">
                                Select From Options Below
                            </h4>
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleClearParts}
                                >
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PRICE TYPE
                                    </label>
                                    <Select
                                        defaultValue={sparePartsPriceType}
                                        onChange={handlePriceChangeParts}
                                        options={partspriceoptions}
                                        placeholder="Spare Parts"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group ">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        MODEL
                                    </label>
                                    <Select
                                        value={sparePartsModelType}
                                        onChange={handleModelChangeParts}
                                        options={modeloptions}
                                        placeholder="Select"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PART NUMBER
                                    </label>
                                    <div
                                        class="input-group icons border-radius-10 "
                                        style={{
                                            overflow: "hidden",
                                            border: "1px solid #872ff9",
                                        }}
                                    >
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text bg-transparent border-0 pr-0 "
                                                id="basic-addon1"
                                            >
                                                <SearchIcon />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            class="form-control search-form-control"
                                            aria-label="Search Dashboard"
                                            value={partIdInput}
                                            onChange={handlePartIdInput}
                                        />
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                                            id="style"
                                            style={{
                                                maxHeight:
                                                    partNumberSuggestions.length >
                                                    5
                                                        ? "200px"
                                                        : "auto",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {loadingPartsId ? (
                                                <InputBoxSearchLoader />
                                            ) : (
                                                <>
                                                    {partIdInput.length != 0 &&
                                                        partNumberSuggestions.length ===
                                                            0 && (
                                                            <li className="list-group-item">
                                                                No Result found
                                                            </li>
                                                        )}
                                                    {partIdInput.length != 0 &&
                                                        partNumberSuggestions.length !==
                                                            0 &&
                                                        partNumberSuggestions.map(
                                                            (
                                                                currentItem,
                                                                j
                                                            ) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    key={j}
                                                                    onClick={() =>
                                                                        handleSelectDropdownItemForpartId(
                                                                            currentItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        currentItem.partId
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PARTS DESCRIPTION
                                    </label>
                                    <div
                                        class="input-group icons border-radius-10 "
                                        style={{
                                            overflow: "hidden",
                                            border: "1px solid #872ff9",
                                            position: "relative",
                                        }}
                                    >
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text bg-transparent border-0 pr-0 "
                                                id="basic-addon1"
                                            >
                                                <SearchIcon />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            class="form-control search-form-control"
                                            aria-label="Search Dashboard"
                                            value={partDescriptionInput}
                                            onChange={
                                                handlePartDescriptionInput
                                            }
                                        />
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${1} style`}
                                            id="style"
                                            style={{
                                                maxHeight:
                                                    partDescriptionSuggestions.length >
                                                    5
                                                        ? "200px"
                                                        : "auto",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {loadingPartsDescriptions ? (
                                                <InputBoxSearchLoader />
                                            ) : (
                                                <>
                                                    {partDescriptionInput.length !=
                                                        0 &&
                                                        partDescriptionSuggestions.length ===
                                                            0 && (
                                                            <li className="list-group-item">
                                                                No Result found
                                                            </li>
                                                        )}
                                                    {partDescriptionInput.length !=
                                                        0 &&
                                                        partDescriptionSuggestions.length !==
                                                            0 &&
                                                        partDescriptionSuggestions.map(
                                                            (
                                                                currentItem,
                                                                j
                                                            ) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    key={j}
                                                                    onClick={() =>
                                                                        handleSelectDropdownItemForpartDescription(
                                                                            currentItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        currentItem.description
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card border">
                        <div className="d-flex align-items-center justify-content-between px-3">
                            <div className="">
                                <div className="d-flex ">
                                    <h5 className=" mb-0">
                                        <span>Warranty Price</span>
                                    </h5>
                                    <p className=" mb-0">
                                        <a href="#" className="ml-3 ">
                                            <img src={editIcon}></img>
                                        </a>
                                        <a href="#" className="ml-3 ">
                                            <img src={shareIcon}></img>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center ">
                                <div className=" text-center border-left py-4 pl-3">
                                    <a
                                        href="#"
                                        className="mr-3 font-size-14 cursor"
                                        title="Upload"
                                        onClick={() => setFileUploadOpen(true)}
                                    >
                                        <img src={uploadIcon}></img>
                                    </a>
                                    <a
                                        onClick={() => handleOpenPartsModal()}
                                        className="cursor"
                                    >
                                        + Add Price
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className=""
                            style={{
                                height: 400,
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                        >
                            {loadingParts && <LoadingProgress />}
                            {!loadingParts &&
                                (!partSearched ? (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        page={pageNoParts}
                                        rows={dataForSpareParts}
                                        columns={columnsforSpareParts}
                                        pageSize={pageSizeParts}
                                        checkboxSelection
                                        paginationMode="server"
                                        onPageChange={(newPage) =>
                                            setpageNoParts(newPage)
                                        }
                                        onPageSizeChange={(newPageSize) => {
                                            setpageSizeParts(newPageSize);
                                            setpageNoParts(0);
                                        }}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={100}
                                    />
                                ) : (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        rows={searchedParts}
                                        columns={columnsforSpareParts}
                                        checkboxSelection
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={searchedParts.length}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const viewLabourTab = () => {
        return (
            <>
                <div className="card p-4 border-blue">
                    <div className=" mt-4  card border p-3">
                        <div className="d-flex justify-content-between mb-2">
                            <h4 className="text-light">
                                Select From Options Below
                            </h4>
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleClearLabor}
                                >
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PRICE TYPE
                                    </label>
                                    <Select
                                        defaultValue={laborPriceType}
                                        onChange={handlePriceChangeLabor}
                                        options={laborpriceoptions}
                                        placeholder="Select Pricetype"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        CHARGE CODE
                                    </label>
                                    <Select
                                        value={laborChargeCode}
                                        onChange={handleChargeCodeChangeLabor}
                                        options={chargeCodeOptions}
                                        placeholder="Select Charge Code"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        JOB CODE
                                    </label>
                                    <Select
                                        value={laborJobCode}
                                        onChange={handleJobChangeLabor}
                                        options={joboptions}
                                        placeholder="Job Code"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border mt-4">
                        <div className="d-flex align-items-center justify-content-between px-3">
                            <div className="">
                                <div className="d-flex ">
                                    <h5 className=" mb-0">
                                        <span>Service Price</span>
                                    </h5>
                                    <p className=" mb-0">
                                        <a href="#" className="ml-3 ">
                                            <img src={editIcon}></img>
                                        </a>
                                        <a href="#" className="ml-3 ">
                                            <img src={shareIcon}></img>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center ">
                                <div className=" text-center border-left py-4 pl-3">
                                    <a
                                        href="#"
                                        className="mr-3 font-size-14 cursor"
                                        title="Upload"
                                        onClick={() => setFileUploadOpen(true)}
                                    >
                                        <img src={uploadIcon}></img>
                                    </a>
                                    <a
                                        href="#"
                                        className="cursor"
                                        onClick={handleOpenLaborsModal}
                                    >
                                        + Add Labors
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className=""
                            style={{
                                height: 400,
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                        >
                            {loadingLabors && <LoadingProgress />}
                            {!loadingLabors &&
                                (!laborSearched ? (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        page={pageNoLabor}
                                        rows={dataForLabour}
                                        columns={columnsforLabor}
                                        pageSize={pageSizeLabor}
                                        checkboxSelection
                                        paginationMode="server"
                                        onPageChange={(newPage) =>
                                            setpageNoLabor(newPage)
                                        }
                                        onPageSizeChange={(newPageSize) => {
                                            setpageSizeLabor(newPageSize);
                                            setpageNoLabor(0);
                                        }}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={100}
                                    />
                                ) : (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        rows={searchedLabors}
                                        columns={columnsforLabor}
                                        checkboxSelection
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={searchedLabors.length}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const viewDiscountTab = () => {
        return (
            <>
                <div className="">
                    <div className=" mt-4 border-blue card p-3">
                        {/* <div className="row"> */}
                        {/* <div className="col-md-6 col-sm-6"> */}
                        <div className="d-flex justify-content-between mb-2">
                            <h4 className="text-light">
                                Select From Options Below
                            </h4>
                            <div>
                                <button className="btn btn-primary">
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PRICE TYPE
                                    </label>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={joboptions}
                                        placeholder="Job Code"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        SERVICE TYPE
                                    </label>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={serviceoptions}
                                        placeholder="Material/Customer"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        COUNTRY
                                    </label>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={countryoptions}
                                        placeholder="Select Country"
                                    />
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="card border mt-4">
                            <div className="d-flex align-items-center justify-content-between px-3">
                                <div className="">
                                    <div className="d-flex ">
                                        <h5 className=" mb-0">
                                            <span>DISCOUNT</span>
                                        </h5>
                                        <p className=" mb-0">
                                            <a href="#" className="ml-3 ">
                                                <img src={editIcon}></img>
                                            </a>
                                            <a href="#" className="ml-3 ">
                                                <img src={shareIcon}></img>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ">
                                    <div className=" text-center border-left py-4 pl-3">
                                        <a
                                            href="#"
                                            className="mr-3 font-size-14 cursor"
                                            title="Upload"
                                            onClick={() =>
                                                setFileUploadOpen(true)
                                            }
                                        >
                                            <img src={uploadIcon}></img>
                                        </a>
                                        <a
                                            href="#"
                                            className="cursor"
                                            onClick={handleOpenLaborsModal}
                                        >
                                            + Add Discounts
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                className=""
                                style={{
                                    height: 400,
                                    width: "100%",
                                    backgroundColor: "#fff",
                                }}
                            >
                                <DataGrid
                                    sx={{
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#872ff9",
                                            color: "#fff",
                                        },
                                    }}
                                    rows={dataForLaborDummy}
                                    columns={columnsforLabor}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const viewShippingPriceTab = () => {
        return (
            <>
                <div className="card p-4 border-blue">
                    <div className=" mt-4 border card p-3">
                        <div className="d-flex justify-content-between mb-2">
                            <h4 className="text-light">
                                Select From Options Below
                            </h4>
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleClearParts}
                                >
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PRICE TYPE
                                    </label>
                                    <Select
                                        defaultValue={sparePartsPriceType}
                                        onChange={handlePriceChangeParts}
                                        options={partspriceoptions}
                                        placeholder="Spare Parts"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group ">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        MODEL
                                    </label>
                                    <Select
                                        value={sparePartsModelType}
                                        onChange={handleModelChangeParts}
                                        options={modeloptions}
                                        placeholder="Select"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PART NUMBER
                                    </label>
                                    <div
                                        class="input-group icons border-radius-10 "
                                        style={{
                                            overflow: "hidden",
                                            border: "1px solid #872ff9",
                                        }}
                                    >
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text bg-transparent border-0 pr-0 "
                                                id="basic-addon1"
                                            >
                                                <SearchIcon />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            class="form-control search-form-control"
                                            aria-label="Search Dashboard"
                                            value={partIdInput}
                                            onChange={handlePartIdInput}
                                        />
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                                            id="style"
                                            style={{
                                                maxHeight:
                                                    partNumberSuggestions.length >
                                                    5
                                                        ? "200px"
                                                        : "auto",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {loadingPartsId ? (
                                                <InputBoxSearchLoader />
                                            ) : (
                                                <>
                                                    {partIdInput.length != 0 &&
                                                        partNumberSuggestions.length ===
                                                            0 && (
                                                            <li className="list-group-item">
                                                                No Result found
                                                            </li>
                                                        )}
                                                    {partIdInput.length != 0 &&
                                                        partNumberSuggestions.length !==
                                                            0 &&
                                                        partNumberSuggestions.map(
                                                            (
                                                                currentItem,
                                                                j
                                                            ) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    key={j}
                                                                    onClick={() =>
                                                                        handleSelectDropdownItemForpartId(
                                                                            currentItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        currentItem.partId
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PARTS DESCRIPTION
                                    </label>
                                    <div
                                        class="input-group icons border-radius-10 "
                                        style={{
                                            overflow: "hidden",
                                            border: "1px solid #872ff9",
                                            position: "relative",
                                        }}
                                    >
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text bg-transparent border-0 pr-0 "
                                                id="basic-addon1"
                                            >
                                                <SearchIcon />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            class="form-control search-form-control"
                                            aria-label="Search Dashboard"
                                            value={partDescriptionInput}
                                            onChange={
                                                handlePartDescriptionInput
                                            }
                                        />
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${1} style`}
                                            id="style"
                                            style={{
                                                maxHeight:
                                                    partDescriptionSuggestions.length >
                                                    5
                                                        ? "200px"
                                                        : "auto",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {loadingPartsDescriptions ? (
                                                <InputBoxSearchLoader />
                                            ) : (
                                                <>
                                                    {partDescriptionInput.length !=
                                                        0 &&
                                                        partDescriptionSuggestions.length ===
                                                            0 && (
                                                            <li className="list-group-item">
                                                                No Result found
                                                            </li>
                                                        )}
                                                    {partDescriptionInput.length !=
                                                        0 &&
                                                        partDescriptionSuggestions.length !==
                                                            0 &&
                                                        partDescriptionSuggestions.map(
                                                            (
                                                                currentItem,
                                                                j
                                                            ) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    key={j}
                                                                    onClick={() =>
                                                                        handleSelectDropdownItemForpartDescription(
                                                                            currentItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        currentItem.description
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border">
                        <div className="d-flex align-items-center justify-content-between px-3">
                            <div className="">
                                <div className="d-flex ">
                                    <h5 className=" mb-0">
                                        <span>Shipping Price</span>
                                    </h5>
                                    <p className=" mb-0">
                                        <a href="#" className="ml-3 ">
                                            <img src={editIcon}></img>
                                        </a>
                                        <a href="#" className="ml-3 ">
                                            <img src={shareIcon}></img>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center ">
                                <div className=" text-center border-left py-4 pl-3">
                                    <a
                                        href="#"
                                        className="mr-3 font-size-14 cursor"
                                        title="Upload"
                                        onClick={() => setFileUploadOpen(true)}
                                    >
                                        <img src={uploadIcon}></img>
                                    </a>
                                    <a
                                        onClick={() => handleOpenPartsModal()}
                                        className="cursor"
                                    >
                                        + Add Price
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div
                            className=""
                            style={{
                                height: 400,
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                        >
                            {loadingParts && <LoadingProgress />}
                            {!loadingParts &&
                                (!partSearched ? (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        page={pageNoParts}
                                        rows={dataForSpareParts}
                                        columns={columnsforShippingPrice}
                                        pageSize={pageSizeParts}
                                        checkboxSelection
                                        paginationMode="server"
                                        onPageChange={(newPage) =>
                                            setpageNoParts(newPage)
                                        }
                                        onPageSizeChange={(newPageSize) => {
                                            setpageSizeParts(newPageSize);
                                            setpageNoParts(0);
                                        }}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={100}
                                    />
                                ) : (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        rows={searchedParts}
                                        columns={columnsforShippingPrice}
                                        checkboxSelection
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={searchedParts.length}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const viewTaxTab = () => {
        return (
            <>
                <div className="card p-4 border-blue">
                    <div className=" mt-4 border card p-3">
                        <div className="d-flex justify-content-between mb-2">
                            <h4 className="text-light">
                                Select From Options Below
                            </h4>
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleClearParts}
                                >
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PRICE TYPE
                                    </label>
                                    <Select
                                        defaultValue={sparePartsPriceType}
                                        onChange={handlePriceChangeParts}
                                        options={partspriceoptions}
                                        placeholder="Spare Parts"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group ">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        MODEL
                                    </label>
                                    <Select
                                        value={sparePartsModelType}
                                        onChange={handleModelChangeParts}
                                        options={modeloptions}
                                        placeholder="Select"
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PART NUMBER
                                    </label>
                                    <div
                                        class="input-group icons border-radius-10 "
                                        style={{
                                            overflow: "hidden",
                                            border: "1px solid #872ff9",
                                        }}
                                    >
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text bg-transparent border-0 pr-0 "
                                                id="basic-addon1"
                                            >
                                                <SearchIcon />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            class="form-control search-form-control"
                                            aria-label="Search Dashboard"
                                            value={partIdInput}
                                            onChange={handlePartIdInput}
                                        />
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                                            id="style"
                                            style={{
                                                maxHeight:
                                                    partNumberSuggestions.length >
                                                    5
                                                        ? "200px"
                                                        : "auto",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {loadingPartsId ? (
                                                <InputBoxSearchLoader />
                                            ) : (
                                                <>
                                                    {partIdInput.length != 0 &&
                                                        partNumberSuggestions.length ===
                                                            0 && (
                                                            <li className="list-group-item">
                                                                No Result found
                                                            </li>
                                                        )}
                                                    {partIdInput.length != 0 &&
                                                        partNumberSuggestions.length !==
                                                            0 &&
                                                        partNumberSuggestions.map(
                                                            (
                                                                currentItem,
                                                                j
                                                            ) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    key={j}
                                                                    onClick={() =>
                                                                        handleSelectDropdownItemForpartId(
                                                                            currentItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        currentItem.partId
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="form-group">
                                    <label
                                        className="text-light-dark font-size-12 font-weight-500"
                                        for="exampleInputEmail1"
                                    >
                                        PARTS DESCRIPTION
                                    </label>
                                    <div
                                        class="input-group icons border-radius-10 "
                                        style={{
                                            overflow: "hidden",
                                            border: "1px solid #872ff9",
                                            position: "relative",
                                        }}
                                    >
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text bg-transparent border-0 pr-0 "
                                                id="basic-addon1"
                                            >
                                                <SearchIcon />
                                            </span>
                                        </div>
                                        <input
                                            type="search"
                                            class="form-control search-form-control"
                                            aria-label="Search Dashboard"
                                            value={partDescriptionInput}
                                            onChange={
                                                handlePartDescriptionInput
                                            }
                                        />
                                        <ul
                                            className={`list-group customselectsearch-list scrollbar scrollbar-${1} style`}
                                            id="style"
                                            style={{
                                                maxHeight:
                                                    partDescriptionSuggestions.length >
                                                    5
                                                        ? "200px"
                                                        : "auto",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {loadingPartsDescriptions ? (
                                                <InputBoxSearchLoader />
                                            ) : (
                                                <>
                                                    {partDescriptionInput.length !=
                                                        0 &&
                                                        partDescriptionSuggestions.length ===
                                                            0 && (
                                                            <li className="list-group-item">
                                                                No Result found
                                                            </li>
                                                        )}
                                                    {partDescriptionInput.length !=
                                                        0 &&
                                                        partDescriptionSuggestions.length !==
                                                            0 &&
                                                        partDescriptionSuggestions.map(
                                                            (
                                                                currentItem,
                                                                j
                                                            ) => (
                                                                <li
                                                                    className="list-group-item"
                                                                    key={j}
                                                                    onClick={() =>
                                                                        handleSelectDropdownItemForpartDescription(
                                                                            currentItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        currentItem.description
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border">
                        <div className="d-flex align-items-center justify-content-between px-3">
                            <div className="">
                                <div className="d-flex ">
                                    <h5 className=" mb-0">
                                        <span>TAX</span>
                                    </h5>
                                    <p className=" mb-0">
                                        <a href="#" className="ml-3 ">
                                            <img src={editIcon}></img>
                                        </a>
                                        <a href="#" className="ml-3 ">
                                            <img src={shareIcon}></img>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center ">
                                <div className=" text-center border-left py-4 pl-3">
                                    <a
                                        href="#"
                                        className="mr-3 font-size-14 cursor"
                                        title="Upload"
                                        onClick={() => setFileUploadOpen(true)}
                                    >
                                        <img src={uploadIcon}></img>
                                    </a>
                                    <a
                                        onClick={() => handleOpenPartsModal()}
                                        className="cursor"
                                    >
                                        + Add Price
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div
                            className=""
                            style={{
                                height: 400,
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                        >
                            {loadingParts && <LoadingProgress />}
                            {!loadingParts &&
                                (!partSearched ? (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        page={pageNoParts}
                                        rows={dataForSpareParts}
                                        columns={columnsforTax}
                                        pageSize={pageSizeParts}
                                        checkboxSelection
                                        paginationMode="server"
                                        onPageChange={(newPage) =>
                                            setpageNoParts(newPage)
                                        }
                                        onPageSizeChange={(newPageSize) => {
                                            setpageSizeParts(newPageSize);
                                            setpageNoParts(0);
                                        }}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={100}
                                    />
                                ) : (
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#872ff9",
                                                color: "#fff",
                                            },
                                        }}
                                        rows={searchedParts}
                                        columns={columnsforTax}
                                        checkboxSelection
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowCount={searchedParts.length}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const fileUploadModal = () => {
        return (
            <>
                <Modal
                    show={fileUploadOpen}
                    onHide={() => setFileUploadOpen(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="modal-header-border">
                        <Modal.Title>Import Files</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <div className="p-3">
                            <div className="add-new-recod">
                                <div>
                                    <FontAwesomeIcon
                                        className="cloudupload"
                                        icon={faCloudUploadAlt}
                                    />
                                    <h6 className="font-weight-500 mt-3">
                                        Drag and drop files to upload <br /> or
                                    </h6>
                                    <FileUploader
                                        handleChange={handleReadFile}
                                        name="file"
                                        types={["xls", "xlsx"]}
                                        onClick={(event) => {
                                            event.currentTarget.value = null;
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="mt-3">
                                Single upload file should not be more than 10MB.
                                Only the .xls, .xlsx file types are allowed
                            </p>
                        </div>
                    </Modal.Body>
                    <div className="row m-0 p-3">
                        <div className="col-md-6 col-sm-6">
                            <button
                                className="btn border w-100 bg-white"
                                onClick={() => setFileUploadOpen(false)}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <button
                                className="btn btn-primary w-100"
                                onClick={handleUploadFile}
                                style={{ cursor: "pointer" }}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faCloudUploadAlt}
                                />
                                Upload
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    };
    const EditModalForShipping = () => {
        return (
            <>
                <Modal
                    show={handleOpenEditModalShipping}
                    onHide={() => handleEditModalShipp()}
                    size="small"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Shipping Price</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="partId">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="partId"
                                    value={selectedRowData.partId}
                                    onChange={handleChange}
                                    // readOnly
                                />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Part Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={selectedRowData.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="unit">
                                <Form.Label>Sales Unit</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="unit"
                                    value={selectedRowData.unit}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="price">
                                <Form.Label>Unit Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="price"
                                    value={selectedRowData.price}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="price">
                                <Form.Label> Shipping Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="shippingPrice"
                                    value={selectedRowData.shippingPrice}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => handleEditModalShipp()}
                        >
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
    const EditModalForTax = () => {
        return (
            <>
                <Modal
                    show={handleOpenEditModalTax}
                    onHide={() => handleEditModalTx()}
                    size="small"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Tax</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="partId">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="partId"
                                    value={selectedRowData.partId}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Part Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={selectedRowData.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="unit">
                                <Form.Label>Sales Unit</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="unit"
                                    value={selectedRowData.unit}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="price">
                                <Form.Label>Unit Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="price"
                                    value={selectedRowData.price}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="price">
                                <Form.Label> Tax Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="taxPrice"
                                    value={selectedRowData.taxPrice}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => handleEditModalTx()}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            //  onClick={handleSaveChangesTax}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    const LaborsModal = () => {
        return (
            <>
                <Modal
                    show={showLaborsModal}
                    onHide={() => handleLaborsModal()}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="font-size-14">
                            ADD SERVICE PRICE
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0 bg-white">
                        <div>
                            <div className="p-3">
                                <div className="row input-fields mt-4">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="countryInput"
                                            >
                                                Country
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="countryInput"
                                                placeholder="Country"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="chargeCodeInput"
                                            >
                                                Charge Code
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="chargeCodeInput"
                                                placeholder="Charge Code"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="jobCodeInput"
                                            >
                                                Job Code
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="jobCodeInput"
                                                placeholder="Job Code"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="serviceTypeInput"
                                            >
                                                Service Type
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="serviceTypeInput"
                                                placeholder="Service Type"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="salesUnitInput"
                                            >
                                                Sales Unit
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="salesUnitInput"
                                                placeholder="Sales Unit"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="unitPriceInput"
                                            >
                                                Unit Price
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="unitPriceInput"
                                                placeholder="Unit Price"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="validFromInput"
                                            >
                                                Valid From
                                            </label>
                                            <div className="align-items-center date-box">
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <MobileDatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        className="form-controldate border-radius-10"
                                                        // maxDate={new Date()}
                                                        closeOnSelect
                                                        value={new Date()}
                                                        //onChange={() => { }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: FONT_STYLE,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="validToInput"
                                            >
                                                Valid To
                                            </label>
                                            <div className="align-items-center date-box">
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <MobileDatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        className="form-controldate border-radius-10"
                                                        // maxDate={new Date()}
                                                        closeOnSelect
                                                        value={new Date()}
                                                        //onChange={() => { }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: FONT_STYLE,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="statusInput"
                                            >
                                                Status
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="statusInput"
                                                placeholder="Status"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="currencyInput"
                                            >
                                                Currency
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="currencyInput"
                                                placeholder="Currency"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3 d-flex justify-content-end">
                                <a
                                    href="#"
                                    onClick={() => handleLaborsModal()}
                                    className="btn border btn-primary mr-3"
                                >
                                    Close
                                </a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    };

    const SparePartsModal = () => {
        return (
            <>
                <Modal
                    show={showPartsModal}
                    onHide={() => handlePartsModal()}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="font-size-14">
                            ADD SPARE PARTS PRICE
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0 bg-white">
                        <div>
                            <div className="p-3">
                                <div className="row input-fields mt-4">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="countryInput"
                                            >
                                                Country
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="countryInput"
                                                placeholder="Country"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="partNumberInput"
                                            >
                                                Part Number
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="partNumberInput"
                                                placeholder="Part Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="partDescriptionInput"
                                            >
                                                Part Description
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="partDescriptionInput"
                                                placeholder="Part Description"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="salesUnitInput"
                                            >
                                                Sales Unit
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="salesUnitInput"
                                                placeholder="Sales Unit"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="unitPriceInput"
                                            >
                                                Unit Price
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="unitPriceInput"
                                                placeholder="Unit Price"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="validFromInput"
                                            >
                                                Valid From
                                            </label>
                                            <div className="align-items-center date-box">
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <MobileDatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        className="form-controldate border-radius-10"
                                                        // maxDate={new Date()}
                                                        closeOnSelect
                                                        value={new Date()}
                                                        //onChange={() => { }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: FONT_STYLE,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="validToInput"
                                            >
                                                Valid To
                                            </label>
                                            <div className="align-items-center date-box">
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <MobileDatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        className="form-controldate border-radius-10"
                                                        // maxDate={new Date()}
                                                        closeOnSelect
                                                        value={new Date()}
                                                        //onChange={() => { }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: FONT_STYLE,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="statusInput"
                                            >
                                                Status
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="statusInput"
                                                placeholder="Status"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group w-100">
                                            <label
                                                className="text-light-dark font-size-14 font-weight-500"
                                                htmlFor="currencyInput"
                                            >
                                                Currency
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control border-radius-10"
                                                id="currencyInput"
                                                placeholder="Currency"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3 d-flex justify-content-end">
                                <a
                                    href="#"
                                    onClick={() => handlePartsModal()}
                                    className="btn border btn-primary mr-3"
                                >
                                    Close
                                </a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
    return (
        <>
            {/* <CommanComponents/> */}
            <div className="content-body" style={{ minHeight: "884px" }}>
                <div class="container-fluid mt-3">
                    <div className="d-flex align-items-center justify-content-between mt-2">
                        <h5 className="font-weight-600 mb-0">
                            Price Maintenance
                        </h5>
                        {/* <div className="d-flex justify-content-center align-items-center">
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Share"
                            >
                                <img src={shareIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Item to Review"
                            >
                                <img src={folderaddIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Upload"
                            >
                                <img src={uploadIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Delete"
                            >
                                <img src={deleteIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Copy"
                            >
                                <img src={copyIcon}></img>
                            </a>
                            <a href="#" className="ml-2">
                                <MuiMenuComponent options={activityOptions} />
                            </a>
                        </div> */}
                    </div>
                    <Box
                        className="mt-4"
                        sx={{ width: "100%", typography: "body1" }}
                    >
                        <TabContext value={activeTab}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <TabList
                                    onChange={(e, tabValue) =>
                                        setActiveTab(tabValue)
                                    }
                                >
                                    <Tab label={`SPARE PARTS`} value="parts" />
                                    <Tab label={`LABOUR`} value="labour" />
                                    <Tab label={`DISCOUNT`} value="discount" />
                                    <Tab
                                        label={`SHIPPING PRICE`}
                                        value="shippingPrice"
                                    />
                                    <Tab label={`TAX`} value="tax" />
                                </TabList>
                            </Box>
                            <TabPanel value={activeTab}>
                                {activeTab === "parts" && viewPartsTab()}
                                {activeTab === "labour" && viewLabourTab()}
                                {activeTab === "discount" && viewDiscountTab()}
                                {activeTab === "shippingPrice" &&
                                    viewShippingPriceTab()}
                                {activeTab === "tax" && viewTaxTab()}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
                {showPartsModal && SparePartsModal()}
                {showLaborsModal && LaborsModal()}
                {fileUploadOpen && fileUploadModal()}
                {showEditModalShipping && EditModalForShipping()}
                {showEditModalTax && EditModalForTax()}
            </div>
        </>
    );
}

export default PriceMaintenance;
