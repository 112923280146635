import React, { useEffect, useState } from "react";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Card, Divider, LinearProgress, Tooltip } from "@mui/material";

import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { GRID_STYLE } from "pages/Repair/CONSTANTS";
import { DataGrid } from "@mui/x-data-grid";
import { BUILDING_ESTIMATE_INVOICE } from "navigation/CONSTANTS";
import OrderInformation from "./OrderInformation";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import OrderBillingDetails from "./OrderBillingDetails";
import { billingTypesOptions } from "pages/Common/constants";
import OrderShippingDetails from "./OrderShippingDetails";
import { getQuoteCommonConfig } from "services";

const ProgressBar = ({ progress }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{
        height: 10,
        borderRadius: 5,
        backgroundColor: "#e0e0e0", // Background color
      }}
      sx={{
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          backgroundColor: "#872ff7", // Purple color
        },
      }}
    />
  );
};

const ConstructionEstimateOrder = (props) => {
  const history = useHistory();
  const { state } = props.location;

  const [orderRecord, setOrderRecord] = useState({
    accountName: "",
    dealerStore: "",
    orderType: "",
    orderedBy: "",
    emailAddress: "",
    phoneNumber: "",
    paymentTerms: "",
    currency: "",
    billingType: "",
    billingFrequency: "",
    billingAddress: "",
    deliveryType: "",
    deliveryPriority: "",
    leadTime: "",
    shippingCondition: "",
    shippingPoint: "",
    pickUpInst: "",
    shippingAddress: "",
    partialShipment: false,
    machineBreakdown: false,
    needInstallationHelp: false,
    serialNumber: "",
    model: "",
  });

  const [openShippingDtlsModal, setOpenShippingDtlsModal] = useState(false);
  const [openBillingDtlsModal, setOpenBillingDtlsModal] = useState(false);
  const [openOrderInfoModal, setOpenOrderInfoModal] = useState(false);
  const [selectAddressType, setSelectAddressType] = useState("");
  const [billingFrequencyOptions, setBillingFrequencyOptions] = useState([]);

  const [quoteCurrencyOptions, setQuoteCurrencyOptions] = useState([]);
  const [billingTypeOptions, setBillingTypeOptions] = useState([...billingTypesOptions]);

  const [labourCharge, setLabourCharge] = useState(0);

  const [partsRecords, setPartsRecords] = useState([]);
  const [orderNo, setOrderNo] = useState("");
  const [orderedDate, setOrderedDate] = useState(new Date());

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [recentBuildersLoading, setRecentBuildersLoading] = useState(true);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (state && state.type === "fetch") {
      setOrderRecord({
        ...orderRecord,
        accountName: state?.account || "",
        shippingAddress: state?.address || "",
      });
      setPartsRecords([...state.partsRecord]);
      setOrderNo(state.orderNo || "");
      setOrderedDate(state.orderedOn || new Date());
    }
  }, [state]);

  useEffect(() => {
    // quote-Currency
    getQuoteCommonConfig("currency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d !== "EMPTY") {
              options.push({ value: d, label: d });
            }
          });
        // const options = res.map((d) => ({
        //   value: d,
        //   label: d,
        // }));
        setQuoteCurrencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });

    // Billing-Frequency
    getQuoteCommonConfig("billing-frequency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d.key !== "EMPTY") {
              options.push({ value: d.key, label: d.value });
            }
          });
        setBillingFrequencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  // create invoice
  const createInvoice = () => {
    history.push({ pathname: BUILDING_ESTIMATE_INVOICE, state: state });
  };

  const columns = [
    {
      field: "id",
      //   field: "partsId",
      headerName: "ID",
      flex: 1,
    },
    { field: "partNumber", headerName: "Part Number", flex: 1 },
    { field: "description", headerName: "Part Description", flex: 1 },
    // { field: "partDescription", headerName: "Part Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    {
      field: "unitPrice",
      headerName: "Price",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row.unitPrice),
    },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row.unitPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">{currencyFormatter.format((row.unitPrice + (row.unitPrice * 10) / 100) * row?.quantity)}</div>
      ),
    },
  ];
  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />

      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid ">
          <div className="d-flex justify-content-between my-2 mt-2 ">
            <div>
              <h3 className="text-light">#{orderNo || "NA"}</h3>
              <h5 className="text-muted">
                Order History / Order Details / {""} - <Moment format="DD-MM-YYYY hh:mm A">{orderedDate}</Moment>
              </h5>
            </div>
            <div>
              <button className="btn btn-danger mr-2">
                <DeleteIcon /> Delete Order
              </button>
              <button className="btn btn-info mr-2 ">
                <TrackChangesIcon /> Track Order
              </button>
              <button className="btn bg-primary text-white mr-2 ">
                <DriveFileRenameOutlineIcon /> Edit Order
              </button>
            </div>
          </div>
          <div className="card border px-3 py-2">
            <h4 className="mb-1">Progress</h4>
            <h6 className="text-muted">Current Order Status</h6>
            <div className="d-flex">
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AllInboxIcon />
                </div>
                <h5 className="mt-2 mb-3">Order Confirming</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <PaymentIcon />
                </div>
                <h5 className="mt-2 mb-3">Payment Pending</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AccountTreeIcon />
                </div>
                <h5 className="mt-2 mb-3">Processing</h5>
                <ProgressBar progress={50} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <LocalShippingIcon />
                </div>
                <h5 className="mt-2 mb-3">Shipping</h5>
                <ProgressBar progress={0} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <CheckCircleRoundedIcon />
                </div>
                <h5 className="mt-2 mb-3">Delivered</h5>
                <ProgressBar progress={0} />
              </div>
            </div>
          </div>
          <div className="card border px-3 py-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Customer</h4>
                <h6 className="text-muted">Information Details</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="card border" style={{ overflow: "hidden", height: "17.5rem" }}>
                  <div className={`d-flex justify-content-between align-items-center bg-green px-2 py-2 text-white`}>
                    <h6 className="bg-green text-white mb-0">Order Info </h6>
                    <Tooltip title="Add Order info">
                      <ControlPointIcon className="cursor" onClick={() => setOpenOrderInfoModal(true)} />
                    </Tooltip>
                  </div>
                  <div className={"px-2 py-2"}>
                    <div className="row py-2">
                      <div className="col-md-3 col-sm-3">
                        <p className="mb-0">Account</p>
                      </div>
                      <div className="col-md-9 col-sm-9 text-right px-3">
                        <h6 className="mb-0 ">
                          <b>{orderRecord?.accountName || "-"}</b>
                        </h6>
                      </div>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Store</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.dealerStore?.label || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Order Type</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.orderType?.label || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Order By</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.orderedBy || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Email</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.emailAddress || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Contact</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.phoneNumber || "-"}</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="card border" style={{ overflow: "hidden", height: "17.5rem" }}>
                  <div className={`d-flex justify-content-between align-items-center bg-green px-2 py-2 text-white`}>
                    <h6 className="bg-green text-white mb-0">Shipping Details</h6>
                    <Tooltip title="Add New Address">
                      <AddLocationAltIcon className="cursor" onClick={() => setOpenShippingDtlsModal(true)} />
                    </Tooltip>
                  </div>
                  <div className={"px-2 py-2"}>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Delivery Type</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.deliveryType?.label || "Standard"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Delivery Priority</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.deliveryPriority?.label || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Lead Time</p>
                      <h6 className="mb-0">
                        <b>{"$" + (orderRecord?.leadTime || 0) + " Days" || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-block py-2">
                      <p className="mb-0">Address</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.shippingAddress || orderRecord?.pickUpInst || "-"}</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="card border " style={{ overflow: "hidden", height: "17.5rem" }}>
                  <div className={`d-flex justify-content-between bg-green py-2 px-2 py-2 text-white mb-0`}>
                    <h6 className="bg-green text-white mb-0">Billing Details</h6>
                    <Tooltip title="Add New Payment Method">
                      <ControlPointIcon className="cursor" onClick={() => setOpenBillingDtlsModal(true)} />
                    </Tooltip>
                  </div>
                  <div className={"px-2 py-2"}>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Payment Terms</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.paymentTerms?.label || "Immediate"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Currency</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.currency?.label || "USD"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Billing Type</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.billingType?.label || "Pay Fixed Price"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Billing Frequency</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.billingFrequency?.label || "One Time"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-block py-2">
                      <p className="mb-0">Billing Address</p>
                      <h6 className="mb-0">
                        <b>{orderRecord?.billingAddress || ""}</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-8">
              <div className="card border p-2">
                <div className="d-flex justify-content-between align-items-baseline">
                  <div>
                    <h4 className="mb-1">Product</h4>
                    <h6 className="text-muted">Your Shipment</h6>
                  </div>
                  <div>
                    <DescriptionIcon /> Download CSV
                  </div>
                </div>
                <Divider className="mb-2" />
                <div className="order-summary-items">
                  <DataGrid
                    rows={partsRecords}
                    columns={columns}
                    disableSelectionOnClick
                    // getRowId={(row) => row.partsId}
                    sx={GRID_STYLE}
                    hideFooter={true}
                    autoHeight
                    pagination={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="card border p-2 mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="mb-0">Payment</h4>
                    <h6 className="text-muted">Final Payment Amount</h6>
                  </div>
                  <div>
                    <SimCardDownloadIcon /> Download Invoice
                  </div>
                </div>
                <div className="hr my-0"></div>
                <div className="py-2">
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Sub Total</p>
                    <h6 className="mb-0">
                      <b>
                        {currencyFormatter.format(
                          (partsRecords.length > 0 &&
                            partsRecords.reduce((sum, partItem) => {
                              return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                            }, 0)) ||
                            0
                        )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Tax (10%)</p>
                    <h6 className="mb-0">
                      <b>
                        {currencyFormatter.format(
                          (((partsRecords.length > 0 &&
                            partsRecords.reduce((sum, partItem) => {
                              return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                            }, 0)) ||
                            0) *
                            10) /
                            100
                        )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="d-flex align-items-center mb-0">
                      <p className="mb-0">Discount</p>
                      <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
                    </div>
                    <h6 className="mb-0">
                      <b>
                        -{" "}
                        {currencyFormatter.format(
                          (((partsRecords.length > 0 &&
                            partsRecords.reduce((sum, partItem) => {
                              return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                            }, 0)) ||
                            0) *
                            2) /
                            100
                        )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Shipping Cost</p>
                    <h6 className="mb-0">
                      <b>{currencyFormatter.format(500)}</b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <h6 className="mb-0">
                      <b>Total</b>
                    </h6>
                    <h6 className="mb-0">
                      <b>
                        {currencyFormatter.format(
                          ((partsRecords.length > 0 &&
                            partsRecords.reduce((sum, partItem) => {
                              return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                            }, 0)) ||
                            0) +
                            (((partsRecords.length > 0 &&
                              partsRecords.reduce((sum, partItem) => {
                                return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                              }, 0)) ||
                              0) *
                              10) /
                              100 -
                            (((partsRecords.length > 0 &&
                              partsRecords.reduce((sum, partItem) => {
                                return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                              }, 0)) ||
                              0) *
                              2) /
                              100 +
                            500
                        )}
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <button className="btn bg-primary text-white w-100" onClick={createInvoice}>
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* order shipping details modal */}
      {openShippingDtlsModal && (
        <OrderShippingDetails
          show={openShippingDtlsModal}
          hideModal={() => setOpenShippingDtlsModal(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          setLabourCharge={setLabourCharge}
        />
      )}

      {/* order information details modal */}
      {openOrderInfoModal && (
        <OrderInformation
          show={openOrderInfoModal}
          hideModal={() => setOpenOrderInfoModal(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
        />
      )}

      {/* order billing details modal */}
      {openBillingDtlsModal && (
        <OrderBillingDetails
          show={openBillingDtlsModal}
          hideModal={() => setOpenBillingDtlsModal(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          selectAddressType={selectAddressType}
          setSelectAddressType={setSelectAddressType}
          currencyOptions={quoteCurrencyOptions}
          billingTypeOptions={billingTypeOptions}
          billingFrequencyOptions={billingFrequencyOptions}
        />
      )}
    </>
  );
};

export default ConstructionEstimateOrder;
