import React, { useCallback, useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import $ from "jquery";
import Moment from "react-moment";
import Select from "react-select";
import { TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE, FONT_STYLE_SELECT, GRID_STYLE, SPAREPART_SEARCH_Q_OPTIONS } from "pages/Common/constants";

import { sparePartSearch } from "services/searchServices";
import { CLAIM_VALUE_MASTER_URL, RELATED_HOURS_EXPENSES_MASTER_URL, RELATED_PARTS_MASTER_URL } from "services/CONSTANTS";
import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import SearchPartListModal from "../claimMaster/SearchPartListModal";
import SupplierClaimModal from "../claimMaster/SupplierClaimModal";
import SearchComponent from "pages/components/SearchComponent";
import RelatedPartsSearchComponent from "./RelatedPartsSearchComponent";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import EvaluationPartReport from "../claimMaster/EvaluationPartReport";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HoursOnJobModal from "./HoursOnJobModal";
import {
  createClaimEvaluation,
  createClaimValue,
  createRelatedHRandExpenses,
  createRelatedLabour,
  updateClaimEvaluation,
  updateClaimValue,
  updateRelatedHRandExpenses,
} from "services/warrantyServices";

const coverageTypeOptions = [
  { label: "Parts & Labour", value: "CT_01" },
  { label: "Only Parts", value: "CT_02" },
  { label: "Part & Labour & Misc.", value: "CT_03" },
  { label: "All Covered", value: "CT_04" },
];

const codeOptions = [
  { label: "User-defined", value: "USER_DRIVED" },
  { label: "Auto-derived", value: "AUTO_DERIVED" },
];

const currencyOption = [
  { label: "GBP", value: "GBP" },
  { label: "AUD", value: "AUD" },
  { label: "EUR", value: "EUR" },
  { label: "USD", value: "USD" },
  { label: "INR", value: "INR" },
];

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  marginBottom: "0.6rem",
  "&:(:last-child)": {
    marginBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => {
//   const { expanded } = props; // Get the expanded state
//   return <MuiAccordionSummary expandIcon={expanded ? <RemoveIcon sx={{ fontSize: "3rem" }} /> : <AddIcon sx={{ fontSize: "3rem" }} />} {...props} />;
// })(({ theme }) => ({
//   backgroundColor: "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     // transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
//   ...theme.applyStyles("dark", {
//     backgroundColor: "rgba(255, 255, 255, .05)",
//   }),
// }));

const AccordionSummary = styled((props) => {
  const { expanded } = props; // Get the expanded state
  return <MuiAccordionSummary expandIcon={expanded ? <RemoveIcon sx={{ fontSize: "3rem" }} /> : <AddIcon sx={{ fontSize: "3rem" }} />} {...props} />;
})(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    // transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// get price data in decimal
const getFlotingValue = (value) => {
  if (value) {
    let priceValue = parseFloat(Number(value));
    const priceWitDecimal = priceValue.toFixed(2);
    return priceWitDecimal;
  }
  return "";
};

const RelatedPartsAndExpenses = (props) => {
  const {
    handleBack,
    handleSnack,
    relatedHEId,
    setRelatedHEId,
    claimOrderId,
    claimNumber,
    coverageTypeValue,
    relatedPartsRecords = [],
    setRelatedPartsRecords,
    handleViewClaimValue,
    claimRelateHERecordData,
    setClaimRelateHERecordData,
    jobHoursRecords = [],
    setJobHoursRecords,
    climentOpt = [],
    auth,
    handleUpdateClaimOrder,
    claimStatus,
    machineData,
    handleShowOrderJobHoursModal,
    viewOnlyTab,
    setViewOnlyTab,
    warrantyRequestClaimData,
    partsFailedRecord,
    partsCausalRecord,
    setPartsFailedRecord,
    setPartsCausalRecord,
    evaluationId,
    setEvaluationId,
    handleEvaluationPartsReturn,
    claimValueRecordData,
    setClaimValueRecordData,
    setCoverageTypeValue,
    claimValueId,
    setClaimValueId,
    priceTypeOptions,
    setSettlementCount,
    setClaimValueCount,
    setClaimStatus,
    claimOrderData,
    evaluatedByData,
    claimRecordId,
    hideModal,
    setRefreshSettlemtData,
    claimValueCount,
  } = props;

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      preSelector: { label: "Parts", value: "PARTS" },
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);
  const [masterData, setMasterData] = useState([]);
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openClaimSuplierModal, setOpenClaimSuplierModal] = useState(false);
  const [claimSupplierRecord, setClaimSupplierRecord] = useState("");
  const [viewOnly, setViewOnly] = useState(false);
  const [isNewCreated, setIsNewCreated] = useState(false);

  const [isNewClaimValueCreated, setIsNewClaimValueCreated] = useState(false);

  const [expanded, setExpanded] = useState("claimSummary");

  const [materialMenuAnchorE1, setMaterialMenuAnchorE1] = useState(null);
  const openMaterialMenu = Boolean(materialMenuAnchorE1);

  const [faultyPartMenuAnchorE1, setFaultyPartMenuAnchorE1] = useState(null);
  const openFaultyPartMenu = Boolean(faultyPartMenuAnchorE1);

  const [labourPartMenuAnchorE1, setLabourMenuAnchorE1] = useState(null);
  const openLabourPartMenu = Boolean(labourPartMenuAnchorE1);

  const [currency, setCurrency] = useState(currencyOption[1]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // show the meterial menu
  const handleShowMaterialMenu = (e) => {
    e.stopPropagation();
    setMaterialMenuAnchorE1(e.currentTarget);
  };

  // close the Material Menu
  const handleCloseMaterialMenu = (e) => {
    e.stopPropagation();
    setMaterialMenuAnchorE1(null);
  };

  // show the Faulty & Caucal Part menu
  const handleShowFaultyPartMenu = (e) => {
    e.stopPropagation();
    setFaultyPartMenuAnchorE1(e.currentTarget);
  };

  // close the Faulty Part Menu
  const handleCloseFaultyPartMenu = (e) => {
    e.stopPropagation();
    setFaultyPartMenuAnchorE1(null);
  };

  // show the Labour menu
  const handleShowLabourPartMenu = (e) => {
    e.stopPropagation();
    setLabourMenuAnchorE1(e.currentTarget);
  };

  // close the Faulty Part Menu
  const handleCloseLabourPartMenu = (e) => {
    e.stopPropagation();
    setLabourMenuAnchorE1(null);
  };

  // change value of input fields
  const handleInputFiledChange = (e) => {
    const { name, value } = e.target;
    if (name === "vehicleKMClaimed") {
      setClaimRelateHERecordData({ ...claimRelateHERecordData, [name]: value });
      setClaimValueRecordData({ ...claimValueRecordData, [name]: value, vehicleKMClaimed: Number(value || 0), travelClaimed: Number(value) * 50 });
    } else {
      setClaimValueRecordData({ ...claimValueRecordData, [name]: Number(value) });
    }
    setSettlementCount((pre) => pre + 1);
    setClaimValueCount((pre) => pre + 1);
  };

  useEffect(() => {
    if (relatedHEId && !isNewCreated) {
      setViewOnly(true);
      setViewOnlyTab({ ...viewOnlyTab, relatedPartsExpensesViewOnly: true });
      callGetApi(`${RELATED_HOURS_EXPENSES_MASTER_URL}/${relatedHEId}`, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // set code value
          const _code = codeOptions.find((obj) => obj.value === responseData.code);

          // set alternate code value
          const _coverageType = coverageTypeOptions.find((obj) => obj.value === responseData.coverageType);

          // const _jobHours = jobHoursRecords.reduce((total, item) => total + item.totalPrice, 0);
          const _jobHours = jobHoursRecords.reduceRight((total, item) => {
            const timeInHours = item.unitOfMeasurement === "MINUTES" ? Number(item.totalHours || 0) / 60 : Number(item.totalHours || 0);
            return total + timeInHours;
          }, 0);
          console.log("--------------- jobHoursRecords related part :: ", _jobHours, jobHoursRecords);
          setClaimRelateHERecordData({
            ...responseData,
            type: climentOpt[0],
            code: _code || "",
            coverageType: _coverageType || "",
            // jobHours: Number(_jobHours).toFixed(2) || responseData.jobHours || 0,
            jobHours: jobHoursRecords.length !== 0 ? Number(_jobHours).toFixed(2) : responseData.jobHours || 0,
          });
        }
      });
    }
  }, [relatedHEId, isNewCreated]);

  // useEffect(() => {
  //   if (claimValueId && !isNewClaimValueCreated) {
  //     setViewOnly(true);
  //     callGetApi(`${CLAIM_VALUE_MASTER_URL}/${claimValueId}`, (response) => {
  //       if (response.status === API_SUCCESS) {
  //         const responseData = response.data;

  //         // type
  //         const _type = priceTypeOptions.find((obj) => obj.value === responseData.type);

  //         // coverage type
  //         const _coverageType = coverageTypeOptions.find((obj) => obj.value === responseData.coverageType);

  //         setClaimValueRecordData({
  //           ...responseData,
  //           type: _type || priceTypeOptions[0],
  //           coverageType: _coverageType || coverageTypeOptions[0],
  //           costPerHours: 70,
  //           totalLaborAmountClaimed: responseData.totalHoursClaimed * 70,
  //         });

  //         setTimeout(() => {
  //           setSettlementCount((pre) => pre + 1);
  //           setClaimValueCount((pre) => pre + 1);
  //         }, 2000);
  //       }
  //     });
  //   }
  // }, [claimValueId, isNewClaimValueCreated]);

  // get related parts table data
  const getRelatedPartsTableData = (relatedPartsRecords) => {
    if (querySearchSelector[0].preSelector?.value === "PRODUCT") {
      const filteredParts = relatedPartsRecords.filter((obj) => obj.taskType === "PRODUCT");
      return filteredParts;
    } else {
      const filteredParts = relatedPartsRecords.filter((obj) => obj.taskType !== "PRODUCT");
      return filteredParts;
    }
  };

  // input fields value change
  const handleInputFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "vehicleKM") {
      setClaimRelateHERecordData({ ...claimRelateHERecordData, [name]: value });
      setClaimValueRecordData({ ...claimValueRecordData, [name]: value, vehicleKMClaimed: Number(value || 0), travelClaimed: Number(value) * 50 });
    } else if (name === "vehicleKMClaimed") {
      // setClaimValueRecordData({ ...claimValueRecordData, [name]: value });
      setClaimRelateHERecordData({ ...claimRelateHERecordData, [name]: value });
    } else {
      setClaimRelateHERecordData({ ...claimRelateHERecordData, [name]: value });
    }
  };

  // select fields value change
  const handleSelectFiledChange = (e, keyName) => {
    setClaimRelateHERecordData({
      ...claimRelateHERecordData,
      [keyName]: e,
    });
  };

  // clear filter
  const clearFilteredData = () => {
    setMasterData([]);
  };

  // Consumable Search
  const handleQuerySearchClick = async (type) => {
    // if (relatedPartsRecords.length !== 0 && !relatedPartsRecords[relatedPartsRecords.length - 1]?.relatedPartsId) {
    //     handleSnack("info", "Please Update Last Row Record then you and add more data.");
    //     return;
    // }
    if (querySearchSelector[0].preSelector?.value === "PRODUCT") {
      const isUserIdPresent = relatedPartsRecords.some((parts) => parts.relatedPartsId === 0);
      if (isUserIdPresent) {
        handleSnack("info", "Please Update Last Row Record then you and add more data.");
        return;
      }
      setRelatedPartsRecords([
        ...relatedPartsRecords,
        {
          relatedPartsId: 0,
          segment: "",
          jobCode: "",
          title: "",
          compCode: "",
          description: "",
          portfolioId: "",
          subDescription: "",
          version: "",
          taskType: "PRODUCT",
          suppliedBy: "YES",
          quantity: 1,
          model: "",
          serialNo: "",
          validFrom: new Date(),
          validTo: new Date(),
          unitPrice: 0,
          extendedPrice: 0,
          discount: 0,
          totalPrice: 0,
          claimOrderId: claimOrderId,
          isNew: true,
        },
      ]);
    } else {
      $(".scrollbar").css("display", "none");
      var searchStr = "";
      querySearchSelector.map(function (item, i) {
        if (i === 0 && item.selectCategory.value && item.inputSearch) {
          searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
        } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
          searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
        }
        return searchStr;
      });

      try {
        if (searchStr) {
          let exits = false;

          if (getRelatedPartsTableData(relatedPartsRecords).length > 0) {
            exits = getRelatedPartsTableData(relatedPartsRecords).some((row) => row["serialNo"] === querySearchSelector[0].inputSearch);
          }

          if (!exits) {
            const res = await sparePartSearch(searchStr);
            setMasterData(res);
            setSearchResultOpen(true);
          } else {
            handleSnack("info", "Already exits, change the input search!");
          }
        } else {
          handleSnack("info", "Please fill the search criteria!");
        }
      } catch (err) {
        handleSnack("error", "Error occurred while fetching spare parts!");
      }
    }
  };

  // Close SparePart search modal
  const handleSearchResClose = () => {
    setSearchResultOpen(false);
  };

  // suplier claim model open
  const handleClaimSupplierOpen = (row) => {
    setClaimSupplierRecord(row);
    setOpenClaimSuplierModal(true);
  };

  // supplier claim model close
  const handleClaimSupplierClose = (row) => {
    setClaimSupplierRecord("");
    setOpenClaimSuplierModal(false);
  };

  const partsColumns = [
    {
      field: "serialNo",
      headerName: querySearchSelector[0].preSelector?.value === "PRODUCT" ? "Product Code" : "Part Number",
      flex: 1,
      editable: querySearchSelector[0].preSelector?.value === "PRODUCT" ? true : false,
    },
    {
      field: "description",
      headerName: querySearchSelector[0].preSelector?.value === "PRODUCT" ? "Product Description" : "Part Description",
      flex: 1,
      editable: querySearchSelector[0].preSelector?.value === "PRODUCT" ? true : false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
    },
    {
      field: "analysis",
      headerName: "Analysis",
      flex: 1,
      editable: true,
    },
    {
      field: "supplier",
      headerName: querySearchSelector[0].preSelector?.value === "PRODUCT" ? "Merchant" : "Supplier",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "SP0023", value: "SP0023" },
        { label: "SP1987", value: "SP1987" },
        { label: "SP4987", value: "SP4987" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      flex: 1,
      editable: querySearchSelector[0].preSelector?.value === "PRODUCT" ? true : false,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => <>{row?.suppliedBy === "YES" ? 0 : row.unitPrice * row.quantity}</>,
      // renderCell: ({ row }) => <>{row.unitPrice * row.quantity}</>,
    },
    {
      field: "suppliedBy",
      headerName: "Supplied by Rheem",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "No" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
      // renderCell: ({ row }) => <>{row.unitPrice * row.quantity}</>,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.relatedPartsId]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.relatedPartsId)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.relatedPartsId)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.relatedPartsId)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleDeleteClick(row.relatedPartsId)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Supplier Claim">
                <img
                  className="m-1"
                  src={"../../assets/images/supplierClaim.png"}
                  alt="Supplier Claim"
                  style={{
                    width: "20px",
                    height: "20px",
                    fill: "#872ff7",
                  }}
                />
              </Tooltip>
            }
            label="Return"
            className="textPrimary"
            onClick={() => handleClaimSupplierOpen(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // get part columns
  const getColumns = useCallback(() => {
    if (querySearchSelector[0].preSelector?.value !== "PRODUCT") {
      const columns = partsColumns.filter((column) => column.field !== "suppliedBy");
      return columns;
    }
    return partsColumns;
  }, [querySearchSelector[0].preSelector?.value, rowModesModel]);

  // row edit start
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // row edit stop
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // click on row edit button
  const handleEditClick = (relatedPartsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [relatedPartsId]: { mode: GridRowModes.Edit },
    });
  };

  // click on row save button
  const handleSaveClick = (relatedPartsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [relatedPartsId]: { mode: GridRowModes.View },
    });
  };

  // click on row delete button
  const handleDeleteClick = (relatedPartsId) => () => {
    if (relatedPartsId) {
      callDeleteApi(null, `${RELATED_PARTS_MASTER_URL}/${relatedPartsId}`, (response) => {
        if (response.status === API_SUCCESS) {
          let _relatedPartsIds = claimOrderData["relatedPartsIds"].filter((item) => item?.relatedPartsId !== relatedPartsId);
          const uniqueRelatedPartsIds = [...new Set(_relatedPartsIds)];
          handleUpdateClaimOrder({ relatedPartsIds: uniqueRelatedPartsIds });
          setRelatedPartsRecords(relatedPartsRecords.filter((row) => row.relatedPartsId !== relatedPartsId));
        }
      });
    } else {
      setRelatedPartsRecords(relatedPartsRecords.filter((row) => row.relatedPartsId !== relatedPartsId));
    }
  };

  // click on row cancel button
  const handleCancelClick = (relatedPartsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [relatedPartsId]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    });

    const editedRow = relatedPartsRecords.find((row) => row.relatedPartsId === relatedPartsId);
    if (editedRow.isNew) {
      setRelatedPartsRecords(relatedPartsRecords.filter((row) => row.relatedPartsId !== relatedPartsId));
    }
  };

  // cange table row edit|delete mode change
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // row update process >> put api call
  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const updatedRow = { ...newRow, totalPrice: newRow.quantity * newRow.unitPrice, isNew: true };

        if (newRow.relatedPartsId) {
          callPutApi(null, `${RELATED_PARTS_MASTER_URL}/${newRow.relatedPartsId}`, updatedRow, (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("success", "Parts updated successfully");
              setRelatedPartsRecords(
                relatedPartsRecords.map((row) => (row.relatedPartsId === updatedRow.relatedPartsId ? { ...updatedRow, isNew: undefined } : row))
              );

              const _relatedPartsRecords = relatedPartsRecords.map((row) =>
                row.relatedPartsId === updatedRow.relatedPartsId ? { ...updatedRow, isNew: undefined } : row
              );

              const _totalPartsClaimed = _relatedPartsRecords.reduceRight((total, item) => {
                if (item.taskType !== "PRODUCT") {
                  return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * Number(item.quantity));
                  // return total + item.unitPrice * item.quantity;
                } else {
                  return total;
                }
              }, 0);
              const _totalProductClaimed = _relatedPartsRecords.reduceRight((total, item) => {
                if (item.taskType === "PRODUCT") {
                  return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity);
                  // return total + item.unitPrice * item.quantity;
                } else {
                  return total;
                }
              }, 0);
              setClaimValueRecordData((prev) => ({ ...prev, totalPartsClaimed: _totalPartsClaimed, totalProductClaimed: _totalProductClaimed }));
              setClaimValueCount((prev) => prev + 1);
              resolve(response.data);
            } else {
              handleSnack("error", "Parts details could not be updated");
              resolve(oldRow);
            }
          });
        } else {
          callPostApi(null, `${RELATED_PARTS_MASTER_URL}`, updatedRow, (response) => {
            if (response.status === API_SUCCESS) {
              const responseData = response.data;
              handleSnack("success", "Parts Created successfully");
              setRelatedPartsRecords(
                relatedPartsRecords.map((row) =>
                  row.relatedPartsId === updatedRow.relatedPartsId
                    ? { ...updatedRow, relatedPartsId: responseData.relatedPartsId, isNew: undefined }
                    : row
                )
              );
              const _relatedPartsRecords = relatedPartsRecords.map((row) =>
                row.relatedPartsId === updatedRow.relatedPartsId ? { ...updatedRow, isNew: undefined } : row
              );

              const _totalPartsClaimed = _relatedPartsRecords.reduceRight((total, item) => {
                if (item.taskType !== "PRODUCT") {
                  return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity);
                  // return total + item.unitPrice * item.quantity;
                } else {
                  return total;
                }
              }, 0);
              const _totalProductClaimed = _relatedPartsRecords.reduceRight((total, item) => {
                if (item.taskType === "PRODUCT") {
                  return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity);
                  // return total + item.unitPrice * item.quantity;
                } else {
                  return total;
                }
              }, 0);
              setClaimValueRecordData((prev) => ({ ...prev, totalPartsClaimed: _totalPartsClaimed, totalProductClaimed: _totalProductClaimed }));
              setClaimValueCount((prev) => prev + 1);
              resolve(response.data);
            } else {
              handleSnack("error", "Parts details could not be updated");
              resolve(oldRow);
            }
          });
        }

        resolve(updatedRow);
      }),
    [relatedPartsRecords]
  );

  // save the parts hours data
  const handleSavePartHoursData = () => {
    const rUrl = RELATED_HOURS_EXPENSES_MASTER_URL;
    const rObj = {
      ...claimRelateHERecordData,
      type: claimRelateHERecordData.type?.value || "CHANNEL_PARTNER",
      code: claimRelateHERecordData.code?.value || "USER_DRIVED",
      alternateCode: claimRelateHERecordData.alternateCode || "NA",
      // alternateCode:
      //     claimRelateHERecordData.alternateCode || "USER_DRIVED",
      coverageType: coverageTypeValue?.value || "CT_04",
      // coverageType:
      //     claimRelateHERecordData.coverageType?.value || "CT_04",
      name: auth?.customerName ? auth?.customerName : "",
      jobHours: parseFloat(Number(claimRelateHERecordData?.jobHours || 0)),
    };
    if (relatedHEId) {
      callPutApi(null, `${rUrl}/${relatedHEId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", "Related Parts & Expenses updated successfully");
          setViewOnly(true);
          setViewOnlyTab({ ...viewOnlyTab, relatedPartsExpensesViewOnly: true });
          handleUpdateClaimOrder({ relatedHEId: responseData.relatedHEId });
        } else {
          handleSnack("info", "Related Parts & Expenses updation failed.");
        }
      });
    } else {
      callPostApi(null, `${rUrl}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setIsNewCreated(true);
          handleSnack("success", "Related Parts & Expenses Created successfully");
          setRelatedHEId(responseData.relatedHEId);
          setViewOnly(true);
          setViewOnlyTab({ ...viewOnlyTab, relatedPartsExpensesViewOnly: true });
          handleUpdateClaimOrder();
        } else {
          handleSnack("info", "Related Parts & Expenses creation failed.");
        }
      });
    }
  };

  // show complaint, cause and Correction Menu Open
  const viewMaterilMenu = () => {
    return (
      <>
        <Menu
          anchorEl={materialMenuAnchorE1}
          id="account-menu"
          open={openMaterialMenu}
          onClose={handleCloseMaterialMenu}
          onClick={handleCloseMaterialMenu}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: 300,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.4,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          width={10}
        >
          <MenuItem onClick={handleCloseMaterialMenu}>
            <span className="font-size-13 font-weight-500 text-gray">COMPLAINT</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
          <MenuItem onClick={handleCloseMaterialMenu}>
            <span className="font-size-13 font-weight-500 text-gray">CAUSE</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
          <MenuItem onClick={handleCloseMaterialMenu}>
            <span className="font-size-13 font-weight-500 text-gray">CORRECTION</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
        </Menu>
      </>
    );
  };

  // show complaint, cause and Correction Menu Open
  const viewFaultyPartMenu = () => {
    return (
      <>
        <Menu
          anchorEl={faultyPartMenuAnchorE1}
          id="account-menu"
          open={openFaultyPartMenu}
          onClose={handleCloseFaultyPartMenu}
          onClick={handleCloseFaultyPartMenu}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: 300,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.4,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          width={10}
        >
          <MenuItem onClick={handleCloseFaultyPartMenu}>
            <span className="font-size-13 font-weight-500 text-gray">COMPLAINT</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
          <MenuItem onClick={handleCloseFaultyPartMenu}>
            <span className="font-size-13 font-weight-500 text-gray">CAUSE</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
          <MenuItem onClick={handleCloseFaultyPartMenu}>
            <span className="font-size-13 font-weight-500 text-gray">CORRECTION</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
        </Menu>
      </>
    );
  };

  // show complaint, cause and Correction Menu Open
  const viewLabourPartMenu = () => {
    return (
      <>
        <Menu
          anchorEl={labourPartMenuAnchorE1}
          id="account-menu"
          open={openLabourPartMenu}
          onClose={handleCloseLabourPartMenu}
          onClick={handleCloseLabourPartMenu}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: 300,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.4,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          width={10}
        >
          <MenuItem onClick={handleCloseLabourPartMenu}>
            <span className="font-size-13 font-weight-500 text-gray">COMPLAINT</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
          <MenuItem onClick={handleCloseLabourPartMenu}>
            <span className="font-size-13 font-weight-500 text-gray">CAUSE</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
          <MenuItem onClick={handleCloseLabourPartMenu}>
            <span className="font-size-13 font-weight-500 text-gray">CORRECTION</span>
            <p
              className="font-size-13 font-weight-500 mb-0"
              style={{
                // wordWrap: "break-word", // Ensure long text breaks and wraps properly
                whiteSpace: "normal", // Allow text to wrap to the next line
                overflowWrap: "anywhere", // Force break long words if necessary
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium!
            </p>
          </MenuItem>
        </Menu>
      </>
    );
  };

  // add Update Claim value Details
  // save claim values
  const handleSave = () => {
    const rUrl = CLAIM_VALUE_MASTER_URL;
    const rObj = {
      ...claimValueRecordData,
      coverageType: claimValueRecordData.coverageType?.value || coverageTypeOptions[0]?.value || "",

      totalPartsClaimed: parseFloat(claimValueRecordData.totalPartsClaimed || 0),
      type: claimValueRecordData.type?.value || priceTypeOptions[0]?.value || "",
    };

    if (claimValueId) {
      callPutApi(null, `${rUrl}/${claimValueId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setClaimStatus({
            label: "Submitted",
            value: "CLAIM_SUBMITTED",
          });
          setViewOnly(true);
          handleSnack("success", "Claim Value Updated Successfully.");
        } else {
        }
      });
    } else {
      callPostApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          setIsNewCreated(true);
          const responseData = response.data;
          setClaimValueId(responseData.claimValueId);
          setViewOnly(true);
          setClaimStatus({
            label: "Submitted",
            value: "CLAIM_SUBMITTED",
          });
          handleUpdateClaimOrder({
            claimOrderStatus: "CLAIM_SUBMITTED",
            claimValueId: responseData.claimValueId,
          });
          handleSnack("success", "Claim Value Created Successfully.");
        } else {
        }
      });
    }
  };

  // claim value add update
  const addUpdateClaimValue = async () => {
    const _jobHours = jobHoursRecords.reduceRight((total, item) => {
      const timeInHours = item.unitOfMeasurement === "MINUTES" ? Number(item.totalHours) / 60 : Number(item.totalHours);
      return Number(total + timeInHours);
    }, 0);

    const rObj = {
      ...claimValueRecordData,
      coverageType: claimValueRecordData.coverageType?.value || coverageTypeOptions[0]?.value || "",

      totalPartsClaimed: parseFloat(Number(claimValueRecordData.totalPartsClaimed || 0)),
      type: claimValueRecordData.type?.value || priceTypeOptions[0]?.value || "",
      totalLaborAmountClaimed: Number(_jobHours).toFixed(2) || 0,
    };

    if (claimValueId) {
      return await updateClaimValue(claimValueId, rObj);
    } else {
      return await createClaimValue(rObj);
    }
  };

  // add update evaluation
  const addUpdateEvaluation = async () => {
    const evalatuinonRObj = {
      ...evaluatedByData,
      claimId: claimRecordId,
      evaluated: true,
      // evaluated: evaluatedStatus,
    };
    if (evaluationId) {
      return await updateClaimEvaluation(evaluationId, evalatuinonRObj);
    } else {
      return await createClaimEvaluation(evalatuinonRObj);
    }
  };

  // add update related hours and expenses
  const addUpdateRelatedHours = async () => {
    const rObj = {
      ...claimRelateHERecordData,
      type: claimRelateHERecordData.type?.value || "CHANNEL_PARTNER",
      code: claimRelateHERecordData.code?.value || "USER_DRIVED",
      alternateCode: claimRelateHERecordData.alternateCode || "NA",
      // alternateCode:
      //     claimRelateHERecordData.alternateCode || "USER_DRIVED",
      coverageType: coverageTypeValue?.value || "CT_04",
      // coverageType:
      //     claimRelateHERecordData.coverageType?.value || "CT_04",
      name: auth?.customerName ? auth?.customerName : "",
      jobHours: parseFloat(Number(claimRelateHERecordData?.jobHours || 0)),
    };
    if (relatedHEId) {
      return await updateRelatedHRandExpenses(relatedHEId, rObj);
    } else {
      return await createRelatedHRandExpenses(rObj);
    }
  };

  // add update related labour
  const addUpdateRelatedLabour = async (relatedHEId) => {
    if (jobHoursRecords.length > 0) {
      const rowsData = [];
      for (const row of jobHoursRecords) {
        try {
          const rObj = {
            chargeCode: row?.chargeCode,
            jobCode: row?.jobCode,
            unitOfMeasurement: row?.unitOfMeasurement,
            totalHours: row?.totalHours,
            unitPrice: row?.unitPrice,
            costPerHour: 70,
            totalPrice: row?.totalPrice,
            laborType: row?.laborType || "SHOP",
            serviceType: row?.serviceType || "GROUND_CLEAN",
            priceType: "FIXED",
            comment: row?.comment,
            relatedHEId: relatedHEId,
          };
          await createRelatedLabour(rObj).then((result) => {
            if (result) {
              rowsData.push(result);
            }
          });
          return rowsData;
        } catch (error) {
          return [];
        }
      }
    }
  };

  // save changes
  const handleSaveChanges = async () => {
    try {
      await addUpdateClaimValue().then(async (claimvalueRes) => {
        if (claimvalueRes) {
          setClaimValueId(claimvalueRes?.claimValueId);
          await addUpdateEvaluation().then(async (claimEvaluationRes) => {
            if (claimEvaluationRes) {
              setEvaluationId(claimEvaluationRes?.evaluationId);
              await addUpdateRelatedHours().then(async (relatedHERes) => {
                if (relatedHERes) {
                  setRelatedHEId(relatedHERes?.relatedHEId);
                  await addUpdateRelatedLabour(relatedHERes?.relatedHEId).then(async (relatedLabours) => {
                    handleUpdateClaimOrder({
                      claimOrderStatus: "CLAIM_SUBMITTED",
                      claimValueId: claimvalueRes?.claimValueId,
                      relatedHEId: relatedHERes?.relatedHEId,
                    });
                    setViewOnlyTab({ ...viewOnlyTab, relatedPartsExpensesViewOnly: true });
                  });
                }
              });
            }
          });
        }
      });
    } catch (error) {
      console.log("error: " + error);
      return;
    }
  };

  return (
    <>
      <div
        className={`card border px-3 py-3 mb-2 ${claimStatus?.value === "ARCHIVED" ? "mx-3" : ""}`}
        style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
      >
        <Accordion expanded={expanded === "claimSummary"} onChange={handleChange("claimSummary")} key={"claimSummary"}>
          <AccordionSummary expanded={expanded === "faultyCausalPart" ? true : false}>
            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <Typography>{"Claim Summary"}</Typography>
              <div>
                <Typography onClick={handleShowFaultyPartMenu}>Complaint, Cause, Correction</Typography>
                {viewFaultyPartMenu()}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {viewOnlyTab?.relatedPartsExpensesViewOnly || claimStatus?.value === "ARCHIVED" ? (
              <>
                <div className="row mt-3">
                  <ReadOnlyField label="COVERAGE TYPE" value={claimValueRecordData.coverageType?.label} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="CURRENCY" value={currency?.label} className="col-md-3 col-sm-3" />
                  <ReadOnlyField
                    label="TOTAL AMOUNT CLAIMED"
                    value={getFlotingValue(claimValueRecordData.totalAmountClaimed)}
                    className="col-md-3 col-sm-3"
                  />
                  <ReadOnlyField
                    label="TOTAL PARTS CLAIMED"
                    value={getFlotingValue(claimValueRecordData.totalPartsClaimed)}
                    className="col-md-3 col-sm-3"
                  />
                  <ReadOnlyField
                    label="TOTAL HOURS CLAIMED"
                    value={getFlotingValue(claimValueRecordData.totalHoursClaimed)}
                    className="col-md-3 col-sm-3"
                  />
                  {/* <ReadOnlyField label="COST PER HOUR" value={getFlotingValue(claimValueRecordData.costPerHours)} className="col-md-3 col-sm-3" /> */}
                  <ReadOnlyField
                    label="TOTAL LABOUR AMOUNT CLAIMED"
                    // value={
                    //   claimValueRecordData.totalLaborAmountClaimed
                    //     ? getFlotingValue(claimValueRecordData.totalLaborAmountClaimed)
                    //     : getFlotingValue(claimValueRecordData.totalHoursClaimed * 70)
                    // }
                    value={getFlotingValue(
                      jobHoursRecords.reduce((sum, item) => {
                        return sum + item.totalPrice;
                      }, 0)
                    )}
                    className="col-md-3 col-sm-3"
                  />
                  <ReadOnlyField label="TRAVEL CLAIMED" value={getFlotingValue(claimValueRecordData.travelClaimed)} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="MISC. CLAIMED" value={getFlotingValue(claimValueRecordData.miscClaimed)} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="TRAVEL TIME" value={claimRelateHERecordData.travelHours} className="col-md-3 col-sm-3" />
                  <ReadOnlyField
                    label="PRODUCT PRICE"
                    value={getFlotingValue(claimValueRecordData?.productPrice) || "0.00"}
                    className="col-md-3 col-sm-3"
                  />
                  <ReadOnlyField label="TAX (GST 10%)" value={getFlotingValue(claimValueRecordData?.tax || 0)} className="col-md-3 col-sm-3" />
                  <ReadOnlyField label="WORK ORDER NUMBER" value={claimRelateHERecordData.workOrder} className="col-md-3 col-sm-3" />
                  <ReadOnlyField
                    label="VEHICLE KM CLAIMED"
                    value={getFlotingValue(claimValueRecordData.vehicleKMClaimed)}
                    className="col-md-3 col-sm-3"
                    showText={true}
                    textValue={
                      <p className="m-0" style={{ fontSize: "11px" }}>
                        Kms minus (-) 25Km Metro Radius
                      </p>
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="row input-fields mt-2 ">
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">COVERAGE TYPE</label>
                      <Select
                        onChange={(e) => {
                          setClaimValueRecordData({ ...claimValueRecordData, coverageType: e });
                          setCoverageTypeValue(e);
                        }}
                        options={coverageTypeOptions}
                        value={claimValueRecordData.coverageType}
                        styles={FONT_STYLE_SELECT}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">CURRENCY</label>
                      <Select onChange={(e) => setCurrency(e)} options={currencyOption} value={currency} styles={FONT_STYLE_SELECT} />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TOTAL AMOUNT CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={getFlotingValue(claimValueRecordData.totalAmountClaimed)}
                          name={"totalAmountClaimed"}
                          // onChange={handleInputFiledChange}
                          disabled={true}
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TOTAL PARTS CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={getFlotingValue(claimValueRecordData.totalPartsClaimed)}
                          name={"totalPartsClaimed"}
                          // onChange={handleInputFiledChange}
                          disabled={true}
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TOTAL HOURS CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={claimValueRecordData.totalHoursClaimed}
                          name={"totalHoursClaimed"}
                          // onChange={handleInputFiledChange}
                          disabled
                        />
                        <span className="hours-div text-primary">Hr</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">COST PER HOUR</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={claimValueRecordData.costPerHours}
                          name={"costPerHours"}
                          onChange={handleInputFiledChange}
                          disabled
                        />
                        <span className="hours-div text-primary">Hr</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TOTAL LABOUR AMOUNT CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          // value={claimValueRecordData.totalLaborAmountClaimed}
                          // value={
                          //   claimValueRecordData.totalLaborAmountClaimed
                          //     ? getFlotingValue(claimValueRecordData.totalLaborAmountClaimed)
                          //     : getFlotingValue(claimValueRecordData.totalHoursClaimed * 70)
                          // }
                          value={getFlotingValue(
                            jobHoursRecords.reduce((sum, item) => {
                              return sum + item.totalPrice;
                            }, 0)
                          )}
                          name={"totalLaborAmountClaimed"}
                          onChange={handleInputFiledChange}
                          disabled
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TRAVEL CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={claimValueRecordData.travelClaimed}
                          name={"travelClaimed"}
                          onChange={handleInputFiledChange}
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">MISC. CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={claimValueRecordData.miscClaimed}
                          name={"miscClaimed"}
                          onChange={handleInputFiledChange}
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">TRAVEL TIME</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="text"
                          class="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          name="travelHours"
                          value={claimRelateHERecordData.travelHours}
                          onChange={handleInputFieldChange}
                        />
                        <span className="hours-div text-primary">Hr</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">PRODUCT PRICE</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={claimValueRecordData?.productPrice}
                          name={"productPrice"}
                          onChange={handleInputFiledChange}
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TAX (GST 10%)</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={getFlotingValue(claimValueRecordData?.tax) || 0}
                          name={"tax"}
                          // onChange={handleInputFiledChange}
                          disabled
                        />
                        <span className="hours-div text-primary">{currency?.label || "Select Currency"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">WORK ORDER NUMBER</label>
                      <input
                        type="text"
                        class="form-control border-radius-10 text-primary"
                        name="workOrder"
                        value={claimRelateHERecordData.workOrder}
                        onChange={handleInputFieldChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">VEHICLE KM CLAIMED</label>
                      <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          value={claimValueRecordData.vehicleKMClaimed}
                          name={"vehicleKMClaimed"}
                          onChange={handleInputFiledChange}
                        />
                        <span className="hours-div text-primary">Km</span>
                      </div>
                      <div className="css-w8dmq8">Kms minus (-) 25Km Metro Radius</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === "material"} onChange={handleChange("material")} key={"material"}>
          <AccordionSummary expanded={expanded === "material" ? true : false}>
            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <Typography>
                {"Material"}
                <span className="ml-2">
                  {currencyFormatter.format(
                    getRelatedPartsTableData(relatedPartsRecords).reduce((sum, item) => {
                      if (item?.taskType !== "PRODUCT") {
                        return sum + (item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity);
                        // return sum + item.unitPrice * item.quantity;
                      }
                      return sum;
                      // return sum + item?.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity;
                    }, 0)
                  )}
                </span>
              </Typography>
              {/* <div>
                <Typography onClick={handleShowMaterialMenu}>Complaint, Cause, Correction</Typography>
                {viewMaterilMenu()}
              </div> */}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row align-items-center">
              <div className="col-12">
                <div className="d-flex align-items-center w-100">
                  <RelatedPartsSearchComponent
                    querySearchSelector={querySearchSelector}
                    setQuerySearchSelector={setQuerySearchSelector}
                    clearFilteredData={clearFilteredData}
                    handleSnack={handleSnack}
                    searchAPI={sparePartSearch}
                    searchClick={handleQuerySearchClick}
                    options={SPAREPART_SEARCH_Q_OPTIONS}
                    background={"white"}
                    type=""
                    buttonText="ADD"
                    tableRecords={getRelatedPartsTableData(relatedPartsRecords)}
                    // buttonText="ADD PART"
                  />
                </div>
              </div>
            </div>
            <DataGrid
              sx={GRID_STYLE}
              getRowId={(row) => row.relatedPartsId}
              //   rows={getRelatedPartsTableData([data])}
              rows={getRelatedPartsTableData(relatedPartsRecords)}
              autoHeight
              columns={getColumns()}
              // columns={partsColumns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              experimentalFeatures={{ newEditingApi: true }}
              onProcessRowUpdateError={(error) => console.log(error)}
              processRowUpdate={processRowUpdate}
              // paginationMode="server"
              // rowsPerPageOptions={[5, 10, 20]}
              // pagination
            />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === "labourHours"} onChange={handleChange("labourHours")} key={"labourHours"}>
          <AccordionSummary expanded={expanded === "labourHours" ? true : false}>
            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <Typography>
                {"Labour Hours"}
                <span className="ml-2">
                  {currencyFormatter.format(
                    jobHoursRecords.reduce((sum, item) => {
                      return sum + item.totalPrice;
                    }, 0)
                  )}
                </span>
              </Typography>
              <div>
                <span className="font-size-12">
                  HOURS ON THE JOB - <b>{claimRelateHERecordData.jobHours}</b>
                </span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <HoursOnJobModal
              //   show={showJobHoursModal}
              //   hideModal={handleShowOrderJobHoursModal}
              jobHoursRecords={jobHoursRecords}
              setJobHoursRecords={setJobHoursRecords}
              claimRelateHERecordData={claimRelateHERecordData}
              setClaimRelateHERecordData={setClaimRelateHERecordData}
              setClaimValueRecordData={setClaimValueRecordData}
              handleInputFieldChange={handleInputFieldChange}
              viewOnlyTab={viewOnlyTab}
              claimStatus={claimStatus}
              setSettlementCount={setSettlementCount}
              setClaimValueCount={setClaimValueCount}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === "faultyCausalPart"} onChange={handleChange("faultyCausalPart")} key={"faultyCausalPart"}>
          <AccordionSummary expanded={expanded === "faultyCausalPart" ? true : false}>
            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <Typography>{"Faulty & Causal Parts"}</Typography>
              {/* <div>
                <Typography onClick={handleShowFaultyPartMenu}>Complaint, Cause, Correction</Typography>
                {viewFaultyPartMenu()}
              </div> */}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <EvaluationPartReport
              handleSnack={handleSnack}
              title="Failed Parts"
              partsRecord={[...partsFailedRecord, ...partsCausalRecord]}
              setPartsRecord={setPartsFailedRecord}
              claimOrderId={claimOrderId}
              evaluationId={evaluationId}
              isFailedPart={true}
              handleEvaluationPartsReturn={handleEvaluationPartsReturn}
              keyName={"failed-parts"}
              handleUpdateClaimOrder={handleUpdateClaimOrder}
              claimOrderData={claimOrderData}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={`row mb-2 ${claimStatus?.value === "ARCHIVED" ? "mx-3" : "mx-0"}`} style={{ justifyContent: "right" }}>
        {viewOnlyTab?.relatedPartsExpensesViewOnly || claimStatus?.value === "ARCHIVED" ? (
          <button
            type="button"
            className="btn btn-ligh save the parts hours datat bg-primary text-white mx-0"
            // onClick={() => handleViewClaimValue("adjustClaimValue")}
            // onClick={handleSaveChanges}
            onClick={hideModal}
          >
            {/* Go to Claim Value */}
            Close
            {/* Save & Close */}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-ligh save the parts hours datat bg-primary text-white mx-0"
            // onClick={handleSavePartHoursData}
            onClick={handleSaveChanges}
          >
            Save
          </button>
        )}
      </div>

      {searchResultOpen && (
        <SearchPartListModal
          show={searchResultOpen}
          hideModal={handleSearchResClose}
          masterData={masterData}
          claimOrderId={claimOrderId}
          // relatedPartsRecords
          setRelatedPartsRecords={setRelatedPartsRecords}
          handleSnack={handleSnack}
          handleUpdateClaimOrder={handleUpdateClaimOrder}
          claimOrderData={claimOrderData}
        />
      )}
      {openClaimSuplierModal && (
        <SupplierClaimModal
          claimSupplierShow={openClaimSuplierModal}
          handleClaimSupplierClose={handleClaimSupplierClose}
          claimSupplierRecord={claimSupplierRecord}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default RelatedPartsAndExpenses;
